export const LOAD_CUSTOMER_LTV = "APP/CUSTOMERS/LTV/LOAD_LTV";
export const LOAD_CUSTOMER_LTV_SUCCESS = "APP/CUSTOMERS/LTV/LOAD_LTV_SUCCESS";
export const LOAD_CUSTOMER_LTV_ERROR = "APP/CUSTOMERS/LTV/LOAD_LTV_ERROR";

export const LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN =
  "APP/CUSTOMERS/LTV/LOAD_SEGMENTS_DROPDOWN";
export const LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN_SUCCESS =
  "APP/CUSTOMERS/LTV/LOAD_SEGMENTS_DROPDOWN_SUCCESS";
export const LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN_ERROR =
  "APP/CUSTOMERS/LTV/LOAD_SEGMENTS_DROPDOWN_ERROR";

export const LOAD_NEW_CUSTOMER_CLTV_DATA =
  "APP/CUSTOMERS/CLTV/LOAD_NEW_CUSTOMER";
export const LOAD_NEW_CUSTOMER_CLTV_DATA_SUCCESS =
  "APP/CUSTOMERS/CLTV/LOAD_NEW_CUSTOMER_SUCCESS";
export const LOAD_NEW_CUSTOMER_CLTV_DATA_ERROR =
  "APP/CUSTOMERS/CLTV/LOAD_NEW_CUSTOMER_ERROR";

export const CUSTOMER_LTV_CLEAN_UP = "APP/CUSTOMERS/LTV/CLEAN_UP";
