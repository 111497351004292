import React, { FC, memo } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import Logo from "assets/img/logo.svg";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { RootStore } from "store";

interface Props {
  isLoggedIn: boolean;
  handleLogout: () => void;
}

const Header: FC<Props> = ({ isLoggedIn, handleLogout }: Props) => {
  const history = useHistory();
  const classes = useStyles();

  const { companyName, storeLogo } = useSelector(
    (state: RootStore) => state.userAuth.loginData
  );

  const onSignIn = () => {
    history.push("/signin");
  };

  const onSignUp = () => {
    history.push("/signup");
  };

  const onSignOut = () => {
    handleLogout();
  };

  return (
    <header className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.logoContainer}>
          <Link id="homepageLink" to="/">
            <img
              src={storeLogo || Logo}
              alt="logo"
              className={classes.logo}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = Logo;
              }}
            />
            <p>{isLoggedIn ? companyName : "Brand Name"}</p>
          </Link>
        </div>
        <div className={classes.buttonContainer}>
          {!isLoggedIn ? (
            <Button
              id="signinButton"
              name="signinButton"
              className={classes.button}
              variant="outlined"
              onClick={onSignIn}
              color="primary"
            >
              SIGN IN
            </Button>
          ) : (
            <Button
              id="signOutButton"
              name="signOutButton"
              className={classes.button}
              variant="outlined"
              onClick={onSignOut}
              color="primary"
            >
              Sign Out
            </Button>
          )}
          {!isLoggedIn && (
            <Button
              id="registerButton"
              name="registerButton"
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={onSignUp}
            >
              REGISTER
            </Button>
          )}
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
