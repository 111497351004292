import { DiscountsListActionTypes } from './types/actions'
import { DiscountsListState } from './types/reducer'
import {
  LOAD_DISCOUNTS_LIST,
  LOAD_DISCOUNTS_LIST_SUCCESS,
  LOAD_DISCOUNTS_LIST_ERROR,
  LOAD_DISCOUNTS_KPI,
  LOAD_DISCOUNTS_KPI_SUCCESS,
  LOAD_DISCOUNTS_KPI_ERROR,
  LOAD_DISCOUNTS_OPTIONS,
  LOAD_DISCOUNTS_OPTIONS_ERROR,
  LOAD_DISCOUNTS_OPTIONS_SUCCESS,
  LOAD_DISCOUNTS_GRAPH,
  LOAD_DISCOUNTS_GRAPH_ERROR,
  LOAD_DISCOUNTS_GRAPH_SUCCESS,
  DISCOUNTS_LIST_CLEAN_UP,
} from './constants'

export const initialState: DiscountsListState = {
  isLoading: false,
  discountsListSuccess: false,
  discountsList: [],
  listLoading: false,
  kpiSuccess: false,
  kpiData: {
    totalDiscount: '0',
    attachmentRate: '0',
    revenue: 0,
    amountDiscounted: 0,
    aov: '0',
    avgDiscountValue: '0',
  },
  totalElements: 0,
  discountsOptionsSuccess: false,
  discountsCodeOptions: [],
  discountsChartOptions: [],
  discountsTypeOptions: [],
  discountsStatusOptions: [],
  graphSuccess: false,
  graphLoading: false,
  graphData: {
    currentPeriod: { totalCount: 0, data: [], percentage: 0 },
    previousPeriod: { totalCount: 0, data: [], percentage: 0 },
    dateLabels: [],
    discount: {
      title: '',
      totalCount: 0,
      description: '',
      previousPeriod: {
        period: '',
        count: 0,
        percentage: '',
      },
      previousYear: {
        period: '',
        count: 0,
        percentage: '',
      },
    },
  },
}

const reducer = (
  state: DiscountsListState = initialState,
  action: DiscountsListActionTypes,
): DiscountsListState => {
  switch (action.type) {
    case LOAD_DISCOUNTS_KPI:
      return {
        ...state,
        isLoading: true,
        kpiData: initialState.kpiData,
      }
    case LOAD_DISCOUNTS_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: true,
        kpiData: action.payload,
      }
    case LOAD_DISCOUNTS_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: false,
      }

    // Discounts Options
    case LOAD_DISCOUNTS_OPTIONS:
      return {
        ...state,
        isLoading: true,
        discountsCodeOptions: initialState.discountsCodeOptions,
        discountsChartOptions: initialState.discountsChartOptions,
        discountsStatusOptions: initialState.discountsStatusOptions,
        discountsTypeOptions: initialState.discountsTypeOptions,
      }
    case LOAD_DISCOUNTS_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        discountsOptionsSuccess: true,
        discountsCodeOptions: action.payload.discountsCodeOptions,
        discountsChartOptions: action.payload.discountsChartOptions,
        discountsStatusOptions: action.payload.discountsStatusOptions,
        discountsTypeOptions: action.payload.discountsTypeOptions,
      }
    case LOAD_DISCOUNTS_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        discountsOptionsSuccess: false,
      }

    // Discounts List
    case LOAD_DISCOUNTS_LIST:
      return {
        ...state,
        listLoading: true,
        discountsList: initialState.discountsList,
        totalElements: initialState.totalElements,
      }
    case LOAD_DISCOUNTS_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        discountsListSuccess: true,
        discountsList: action.payload.discountsList,
        totalElements: action.payload.totalElements || 0,
      }
    case LOAD_DISCOUNTS_LIST_ERROR:
      return {
        ...state,
        listLoading: false,
        discountsListSuccess: false,
      }

    // Discounts Graph
    case LOAD_DISCOUNTS_GRAPH:
      return {
        ...state,
        graphLoading: true,
        graphData: initialState.graphData,
      }
    case LOAD_DISCOUNTS_GRAPH_SUCCESS:
      return {
        ...state,
        graphLoading: false,
        graphSuccess: true,
        graphData: action.payload,
      }
    case LOAD_DISCOUNTS_GRAPH_ERROR:
      return {
        ...state,
        graphLoading: false,
        graphSuccess: false,
      }

    case DISCOUNTS_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
