import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    height: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  wrapper: {
    margin: "0 auto",
    width: "75%",
    height: "7.5rem",
    border: `0.1rem solid ${theme.app.colors.headerBorderColor}`,
    padding: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  logoContainer: {
    display: "flex",
    "& > img": {
      marginRight: "1rem",
      height: "2rem",
      width: "2.4rem",
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    "& > p": {
      margin: 0,
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: theme.app.fonts.textLarge,
      lineHeight: "1.7rem",
      textAlign: "center",
      letterSpacing: "0.05rem",
      color: `${theme.palette.primary.main}`,
    },
    "& > a": {
      textDecoration: "none",
      color: `${theme.palette.primary.main}`,
      display: "flex",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
      "& > p": {
        margin: 0,
        fontStyle: "normal",
        // fontWeight: 800,
        // fontSize: theme.app.fonts.textLarge,
        lineHeight: "1.7rem",
        textAlign: "center",
        letterSpacing: "0.05rem",
        color: `${theme.palette.primary.main}`,
        [theme.breakpoints.down("xs")]: {
          fontSize: theme.app.fonts.textSmall,
          fontWeight: 600,
        },
      },
      "& > img": {
        marginRight: "1rem",
        height: "2rem",
        width: "2.4rem",
        [theme.breakpoints.down("md")]: {
          marginRight: "0.5rem",
          height: "2.5rem",
          width: "2rem",
        },
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "15rem",
    },
  },
  logo: {
    width: "3rem",
    marginRight: "1rem",
  },
  buttonContainer: {
    display: "flex",
    width: "25rem",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
      width: "18rem",
    },
  },
  button: {
    marginLeft: "2rem",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: theme.app.fonts.textSmall,
      width: "7rem",
      marginLeft: 0,
      padding: "0.5rem",
    },
  },
}));

export default useStyles;
