export const LOAD_PRODUCT_CATEGORY_KPI =
  'APP/PRODUCT/CATEGORY/LOAD_KPI'
export const LOAD_PRODUCT_CATEGORY_KPI_SUCCESS =
  'APP/PRODUCT/CATEGORY/LOAD_KPI_SUCCESS'
export const LOAD_PRODUCT_CATEGORY_KPI_ERROR =
  'APP/PRODUCT/CATEGORY/LOAD_KPI_ERROR'

export const LOAD_PRODUCT_IN_CATEGORY =
  'APP/PRODUCT/CATEGORY/LOAD_IN_CATEGORY'
export const LOAD_PRODUCT_IN_CATEGORY_SUCCESS =
  'APP/PRODUCT/CATEGORY/LOAD_IN_CATEGORY_SUCCESS'
export const LOAD_PRODUCT_IN_CATEGORY_ERROR =
  'APP/PRODUCT/CATEGORY/LOAD_IN_CATEGORY_ERROR'

export const LOAD_CUSTOMER_IN_CATEGORY =
  'APP/PRODUCT/CATEGORY/LOAD_CUSTOMER'
export const LOAD_CUSTOMER_IN_CATEGORY_SUCCESS =
  'APP/PRODUCT/CATEGORY/LOAD_CUSTOMER_SUCCESS'
export const LOAD_CUSTOMER_IN_CATEGORY_ERROR =
  'APP/PRODUCT/CATEGORY/LOAD_CUSTOMER_ERROR'

export const LOAD_CATEGORY_SEARCH_OPTIONS =
  'APP/PRODUCT/CATEGORY/LOAD_SEARCH_OPTIONS'
export const LOAD_CATEGORY_SEARCH_OPTIONS_SUCCESS =
  'APP/PRODUCT/CATEGORY/LOAD_SEARCH_OPTIONS_SUCCESS'
export const LOAD_CATEGORY_SEARCH_OPTIONS_ERROR =
  'APP/PRODUCT/CATEGORY/LOAD_SEARCH_OPTIONS_ERROR'

export const CATEGORY_LIST_CLEAN_UP =
  'APP/PRODUCT/CATEGORY/LIST/CLEAN_UP'
