import React, { FC, memo } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import SearchImg from 'assets/img/header-search-icon.svg'
import useStyles from './styles'

interface Props {
  searchWidth: string
  placeholder: string
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  id: string
  type?: string
  onKeyPress?: (e: React.KeyboardEvent<HTMLDivElement>) => void
}

const SearchBox: FC<Props> = ({
  searchWidth,
  placeholder,
  onClick,
  id,
  type,
  onKeyPress,
}: Props): JSX.Element => {
  const classes = useStyles({ searchWidth })

  return (
    <div className={classes.searchContainer}>
      <TextField
        id={id}
        name="searchText"
        InputProps={{
          inputProps: { style: { borderRadius: 0 } },
          startAdornment: (
            <InputAdornment position="start">
              <img
                className={classes.searchIcon}
                src={SearchImg}
                alt="search icon"
              />
            </InputAdornment>
          ),
          style: { borderRadius: 0 },
        }}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        fullWidth
        onChange={onClick}
        type={type}
        className={classes.input}
        onKeyPress={onKeyPress}
      />
    </div>
  )
}
SearchBox.defaultProps = {
  type: '',
  onKeyPress: undefined,
}
export default memo(SearchBox)
