/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from 'axios'
import mantixApiInstance from '../interceptors/interceptor'

const PAGE = 'dashboard/'
const LOAD_DASHBOARD_SUMMARY_URL =
  process.env.REACT_APP_LOAD_DASHBOARD_SUMMARY

const CUSTOMER_PURCHASING_DETAILS_URL =
  process.env.REACT_APP_CUSTOMER_PURCHASING_DETAILS

const LOAD_DASHBOARD_KPI_URL =
  process.env.REACT_APP_LOAD_DASHBOARD_KPI

const TOP_PERFORMANCE_PRODUCTS_URL =
  process.env.REACT_APP_TOP_PERFORMANCE_PRODUCTS

const LOAD_WEB_TRAFFIC_URL =
  process.env.REACT_APP_LOAD_WEB_TRAFFIC_DATA

const LOAD_CONVERSION_RATE_URL =
  process.env.REACT_APP_LOAD_CONVERSION_RATE

const LOAD_PRODUCTS_HIERARCHY_URL =
  process.env.REACT_APP_LOAD_PRODUCTS_HIERARCHY

const LOAD_DASH_GRAPH_URL = process.env.REACT_APP_LOAD_DASHBOARD_GRAPH

export const loadDashboardSummary = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_DASHBOARD_SUMMARY_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadCustPurchaseDetails = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  page: number
  pageSize: number
  type: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${CUSTOMER_PURCHASING_DETAILS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadDashboardKPI = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_DASHBOARD_KPI_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadTopPerfProducts = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  page: number
  pageSize: number
  type: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${TOP_PERFORMANCE_PRODUCTS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadWebTrafficData = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_WEB_TRAFFIC_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadConversionRate = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CONVERSION_RATE_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadProductsHierarchy = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  productName: string
  percentage: string | number
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCTS_HIERARCHY_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadDashboardGraph = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  type: string
  period: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_DASH_GRAPH_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}
