import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { AppActionTypes } from "./types/actions";
import {
  GET_USER_AUTH,
  GET_USER_AUTH_SUCCESS,
  GET_USER_AUTH_ERROR,
  SET_STORE_LOGO,
} from "./appConstants";

const userAuth: AppActionTypes = {
  type: GET_USER_AUTH,
};

const userAuthSuccess = (userId: string): AppActionTypes => ({
  type: GET_USER_AUTH_SUCCESS,
  payload: userId,
});

const userAuthError = (error: string): AppActionTypes => ({
  type: GET_USER_AUTH_ERROR,
  payload: error,
});

const setStoreLogo = (logo: string): AppActionTypes => ({
  type: SET_STORE_LOGO,
  payload: logo,
});

export const getUserAuth = (): ThunkAction<
  Promise<void>,
  null,
  null,
  AppActionTypes
> => async (dispatch: Dispatch<AppActionTypes>): Promise<void> => {
  try {
    dispatch(userAuth);
    // const result = await Auth.currentSession()
    // const user = await Auth.currentAuthenticatedUser()
    // if (result) {
    //   dispatch(userAuthSuccess(user))
    // }

    const rememberMe = localStorage.getItem("rememberMe");
    const userId =
      rememberMe === "true"
        ? localStorage.getItem("userId")
        : sessionStorage.getItem("userId");

    if (userId) {
      dispatch(userAuthSuccess(userId));
    } else dispatch(userAuthError("No current User"));
  } catch (error) {
    const err = error && error.message ? error.message : error || "";
    dispatch(userAuthError(err));
  }
};
