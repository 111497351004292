export const LOAD_SHIPPING_LIST = 'APP/ORDERS/SHIPPING/LOAD_LIST'
export const LOAD_SHIPPING_LIST_SUCCESS =
  'APP/ORDERS/SHIPPING/LOAD_LIST_SUCCESS'
export const LOAD_SHIPPING_LIST_ERROR =
  'APP/ORDERS/SHIPPING/LOAD_LIST_ERROR'

export const LOAD_SHIPPING_KPI = 'APP/ORDERS/SHIPPING/LOAD_KPI'
export const LOAD_SHIPPING_KPI_SUCCESS =
  'APP/ORDERS/SHIPPING/LOAD_KPI_SUCCESS'
export const LOAD_SHIPPING_KPI_ERROR =
  'APP/ORDERS/SHIPPING/LOAD_KPI_ERROR'

export const LOAD_SHIPPING_OPTIONS =
  'APP/ORDERS/SHIPPING/LOAD_OPTIONS'
export const LOAD_SHIPPING_OPTIONS_SUCCESS =
  'APP/ORDERS/SHIPPING/LOAD_OPTIONS_SUCCESS'
export const LOAD_SHIPPING_OPTIONS_ERROR =
  'APP/ORDERS/SHIPPING/LOAD_OPTIONS_ERROR'

export const SHIPPING_LIST_CLEAN_UP =
  'APP/ORDERS/SHIPPING/LIST/CLEAN_UP'
