export const LOAD_INVENTORY_LIST = "APP/PRODUCT/INVENTORY/LOAD_LIST";
export const LOAD_INVENTORY_LIST_SUCCESS =
  "APP/PRODUCT/INVENTORY/LOAD_LIST_SUCCESS";
export const LOAD_INVENTORY_LIST_ERROR =
  "APP/PRODUCT/INVENTORY/LOAD_LIST_ERROR";

export const LOAD_INVENTORY_SEARCH_OPTIONS =
  "APP/PRODUCT/INVENTORY/LOAD_SEARCH_OPTIONS";
export const LOAD_INVENTORY_SEARCH_OPTIONS_SUCCESS =
  "APP/PRODUCT/INVENTORY/LOAD_SEARCH_OPTIONS_SUCCESS";
export const LOAD_INVENTORY_SEARCH_OPTIONS_ERROR =
  "APP/PRODUCT/INVENTORY/LOAD_SEARCH_OPTIONS_ERROR";
export const LOAD_PRODUCT_STATUS_OPTIONS =
  "APP/PRODUCT/INVENTORY/LOAD_PRODUCT_STATUS_OPTIONS";
export const LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS =
  "APP/PRODUCT/INVENTORY/LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS";
export const LOAD_PRODUCT_STATUS_OPTIONS_ERROR =
  'APP/PRODUCT/INVENTORY/LOAD_PRODUCT_STATUS_OPTIONS_ERROR'
export const INVENTORY_LIST_CLEAN_UP =
  'APP/PRODUCT/INVENTORY/LIST/CLEAN_UP'
export const LOAD_INVENTORY_PRODUCTS_FOR_EXPORT =
  'APP/INVENTORY/PRODUCT/EXPORT'
export const LOAD_INVENTORY_PRODUCTS_FOR_EXPORT_SUCCESS =
  'APP/INVENTORY/PRODUCT/EXPORT_SUCCESS'
export const LOAD_INVENTORY_PRODUCTS_FOR_EXPORT_ERROR =
  'APP/INVENTORY/PRODUCT/EXPORT_ERROR'
