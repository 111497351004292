import { SelectStoresActionTypes } from './types/actions'
import { SelectStoresState } from './types/reducer'
import {
  LOAD_STORES,
  LOAD_STORES_SUCCESS,
  LOAD_STORES_ERROR,
  LOAD_STORES_CLEAN_UP,
} from './constants'

export const initialState: SelectStoresState = {
  isLoading: false,
  success: false,
  stores: [],
  userId: '',
  logo: '',
}

const reducer = (
  state: SelectStoresState = initialState,
  action: SelectStoresActionTypes,
): SelectStoresState => {
  switch (action.type) {
    case LOAD_STORES:
      return { ...state, isLoading: true }
    case LOAD_STORES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        stores: action.payload.stores,
        userId: action.payload.userId,
        logo: action.payload.logo,
      }
    case LOAD_STORES_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case LOAD_STORES_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
