import { SegmentActionTypes } from './types/actions'
import { SegmentState } from './types/reducer'
import {
  LOAD_SEGMENT,
  LOAD_SEGMENT_SUCCESS,
  LOAD_SEGMENT_ERROR,
  LOAD_PRODUCT_STATUS_OPTIONS,
  LOAD_PRODUCT_STATUS_OPTIONS_ERROR,
  LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS,
  SEGMENT_CLEAN_UP,
} from './constants'

export const initialState: SegmentState = {
  isLoading: false,
  success: false,
  isOptionsLoading: false,
  productSegmentOptions: [],
  searchByOptions: [],
  segmentOptionsSuccess: false,
  data: {
    title: '',
    content: [],
  },
}

const reducer = (
  state: SegmentState = initialState,
  action: SegmentActionTypes,
): SegmentState => {
  switch (action.type) {
    case LOAD_SEGMENT:
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
      }
    case LOAD_SEGMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        data: action.payload,
      }
    case LOAD_SEGMENT_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case LOAD_PRODUCT_STATUS_OPTIONS:
      return {
        ...state,
        isOptionsLoading: true,
        searchByOptions: initialState.searchByOptions,
        productSegmentOptions: initialState.productSegmentOptions,
      }
    case LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        isOptionsLoading: false,
        segmentOptionsSuccess: true,
        searchByOptions: action.payload.searchByOptions,
        productSegmentOptions: action.payload.productSegmentOptions,
      }
    case LOAD_PRODUCT_STATUS_OPTIONS_ERROR:
      return {
        ...state,
        isOptionsLoading: false,
        segmentOptionsSuccess: false,
      }
    case SEGMENT_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
