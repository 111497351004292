/* eslint-disable no-param-reassign */

import React, { FC, useEffect, useState } from "react";
import { RootStore } from "store";
import { useSelector, useDispatch } from "react-redux";
import SideBarComponent from "components/SideBar";
import DashboardIconBlack from "assets/img/DashboardIconBlack.svg";
import CustomersIconBlack from "assets/img/CustomersIconBlack.svg";
import ProductIconBlack from "assets/img/ProductIconBlack.svg";
import ChannelsIconBlack from "assets/img/ChannelsIconBlack.svg";
import SettingsIconBlack from "assets/img/SettingsIconBlack.svg";
import OrdersIconBlack from "assets/img/OrdersIconBlack.svg";
import MarketingIconBlack from "assets/img/MarketingIconBlack.svg";
import Loading from "components/common/Loading";
import ErrorBoundary from "containers/ErrorBoundary";
import { RouteList, Menu } from "./types/reducer";
import { loadSidebarList } from "./actions";

type List = {
  name: string;
  route?: string;
  isDropdown: boolean;
  icon?: string;
};

const routeList: List[] = [
  {
    name: "Dashboard",
    route: "/dashboard",
    isDropdown: false,
    icon: DashboardIconBlack,
  },
  {
    name: "Customers",
    isDropdown: true,
    icon: CustomersIconBlack,
  },
  {
    name: "Customer Overview",
    route: "/customers-overview",
    isDropdown: false,
  },
  {
    name: "Customer DB",
    route: "/customers-db",
    isDropdown: false,
  },
  {
    name: "Customers Segments",
    route: "/customers-segments",
    isDropdown: false,
  },
  {
    name: "Purchase Behavior",
    route: "/customers-purchase-behavior",
    isDropdown: false,
  },
  {
    name: "Marketing",
    isDropdown: true,
    icon: MarketingIconBlack,
  },
  {
    name: "Sources",
    route: "/marketing-sources",
    isDropdown: false,
  },
  {
    name: "Ad Campaigns",
    route: "/marketing-advertising",
    isDropdown: false,
  },
  {
    name: "E-mails",
    route: "/marketing-emails",
    isDropdown: false,
  },
  {
    name: "Abandoned Carts",
    route: "/marketing-abandoned-carts",
    isDropdown: false,
  },
  {
    name: "Products",
    isDropdown: true,
    icon: ProductIconBlack,
  },
  {
    name: "Products DB",
    route: "/products-db",
    isDropdown: false,
  },
  {
    name: "Products Segments",
    route: "/products-segments",
    isDropdown: false,
  },
  {
    name: "Inventory",
    route: "/products-inventory",
    isDropdown: false,
  },
  {
    name: "Categories",
    route: "/products-categories",
    isDropdown: false,
  },
  {
    name: "Product Overview",
    route: "/products-overview",
    isDropdown: false,
  },
  {
    name: "Orders",
    isDropdown: true,
    icon: OrdersIconBlack,
  },
  {
    name: "Orders DB",
    route: "/orders-db",
    isDropdown: false,
  },
  {
    name: "Shipping",
    route: "/orders-shipping",
    isDropdown: false,
  },
  {
    name: "Discounts",
    route: "/orders-discounts",
    isDropdown: false,
  },
  {
    name: "Channels",
    route: "/channels",
    isDropdown: false,
    icon: ChannelsIconBlack,
  },
  {
    name: "Settings",
    isDropdown: true,
    icon: SettingsIconBlack,
  },
  {
    name: "Stores",
    route: "/settings-store",
    isDropdown: false,
  },
  {
    name: "Team Members",
    route: "/settings-team-members",
    isDropdown: false,
  },
];

const SideBarContainer: FC<Props> = ({ children }: Props) => {
  const sidebarListData = useSelector((state: RootStore) => state.sidebarList);
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState<RouteList>([]);
  const { role } = useSelector((state: RootStore) => state.userAuth.loginData);

  useEffect(() => {
    if (sidebarListData.success) {
      const menuList = sidebarListData.data.filter(
        (list) => list.name !== "Channels"
        // && list.name !== "Products" // for bimart only
      );
      // ------------------to hide orders -> shipping -----------
      const filterList = menuList.map((element) => ({
        ...element,
        subList: element.subList?.filter(
          (subElement) =>
            subElement.name !== "Shipping" && subElement.name !== "Stores"
          // subElement.name !== "Abandoned Carts" // bimart branch only
        ),
      }));
      //----------------------------------------
      const sortedMenu = filterList.sort(
        (a: Menu, b: Menu) => a.precedence - b.precedence
      );

      sortedMenu.forEach((menu: Menu) => {
        if (menu.subList && menu.subList.length > 0) {
          menu.subList.forEach((submenu) => {
            routeList.forEach((item: List) => {
              if (item.name === submenu.name) {
                if (item.name === "Products Segments") {
                  submenu.name = "Segments";
                }
                if (item.name === "Customers Segments") {
                  submenu.name = "Segments";
                }
                if (item.name === "Product Overview") {
                  submenu.name = "Overview";
                }
                if (item.name === "Customer Overview") {
                  submenu.name = "Overview";
                }
                submenu.route = item.route || "";
              }
            });
          });
        }
        routeList.forEach((item: List) => {
          if (item.name === menu.name) {
            menu.route = item.route || "";
            menu.isDropdown = item.isDropdown;
            menu.icon = item.icon || "";
          }
        });
      });
      setRoutes(sortedMenu);
    }
  }, [sidebarListData.success, sidebarListData.data]);

  useEffect(() => {
    dispatch(loadSidebarList(role));
  }, [dispatch, role]);

  return (
    <>
      <ErrorBoundary>
        {sidebarListData.isLoading && <Loading id="sideBar" />}
        {!sidebarListData.isLoading && routes.length > 0 && (
          <SideBarComponent routes={routes}>{children}</SideBarComponent>
        )}
      </ErrorBoundary>
    </>
  );
};

export interface Props {
  children: React.ReactNode;
}

export default SideBarContainer;
