import { SignUpActionTypes } from './types/actions'
import { SignUpState } from './types/reducer'
import {
  USER_SIGN_UP,
  USER_SIGN_UP_SUCCESS,
  USER_SIGN_UP_ERROR,
  SIGN_UP_CLEAN_UP,
} from './constants'

export const initialState: SignUpState = {
  isLoading: false,
  success: false,
  userId: '',
}

const reducer = (
  state: SignUpState = initialState,
  action: SignUpActionTypes,
): SignUpState => {
  switch (action.type) {
    case USER_SIGN_UP:
      return {
        ...state,
        isLoading: true,
        userId: '',
      }
    case USER_SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        userId: action.payload,
      }
    case USER_SIGN_UP_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }

    case SIGN_UP_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
