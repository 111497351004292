import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: 'rgb(255 255 255 / 59%)',
  },
  wrapper: {
    margin: '0 auto',
    height: '7.5rem',
    width: '90%',
    border: `0.1rem solid ${theme.app.colors.headerBorderColor}`,
    padding: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menu: {
    flex: '1 0 2rem',
    // margin: '0 1rem',
    marginRight: '-1.5rem',
  },
  menu2: {
    marginRight: '-2rem',
  },
  arrowIcon: {
    margin: '0 0.5rem 0 1rem',
  },
  userMenuPopper: {
    width: '15rem',
  },
  popper: {
    width: '45rem',
    right: 0,
  },
}))

export default useStyles
