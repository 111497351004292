export const LOAD_CUSTOMER_HIGHLIGHTS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_HIGHLIGHTS'
export const LOAD_CUSTOMER_HIGHLIGHTS_SUCCESS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_HIGHLIGHTS_SUCCESS'
export const LOAD_CUSTOMER_HIGHLIGHTS_ERROR =
  'APP/CUSTOMERS/OVERVIEW/LOAD_HIGHLIGHTS_ERROR'

export const LOAD_CUSTOMER_OVERVIEW_GRAPH =
  'APP/CUSTOMERS/OVERVIEW/LOAD_GRAPH'
export const LOAD_CUSTOMER_OVERVIEW_GRAPH_SUCCESS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_GRAPH_SUCCESS'
export const LOAD_CUSTOMER_OVERVIEW_GRAPH_ERROR =
  'APP/CUSTOMERS/OVERVIEW/LOAD_GRAPH_ERROR'

export const LOAD_CUSTOMER_RFM_ANALYSIS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS'
export const LOAD_CUSTOMER_RFM_ANALYSIS_SUCCESS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_SUCCESS'
export const LOAD_CUSTOMER_RFM_ANALYSIS_ERROR =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_ERROR'

export const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_FILTERS'
export const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_SUCCESS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_FILTERS_SUCCESS'
export const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_ERROR =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_FILTERS_ERROR'

export const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_FILTERS_BUTTON'
export const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_SUCCESS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_FILTERS_BUTTON_SUCCESS'
export const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_ERROR =
  'APP/CUSTOMERS/OVERVIEW/LOAD_RFM_ANALYSIS_FILTERS_BUTTON_ERROR'

export const LOAD_CATEGORY_LIST =
  'APP/CUSTOMERS/OVERVIEW/LOAD_CATEGORY_LIST'
export const LOAD_CATEGORY_LIST_SUCCESS =
  'APP/CUSTOMERS/OVERVIEW/LOAD_CATEGORY_LIST_SUCCESS'
export const LOAD_CATEGORY_LIST_ERROR =
  'APP/CUSTOMERS/OVERVIEW/LOAD_CATEGORY_LIST_ERROR'

export const CUSTOMER_HIGHLIGHTS_CLEAN_UP =
  'APP/CUSTOMERS/OVERVIEW/HIGHLIGHTS/CLEAN_UP'
