import { CategoriesListActionTypes } from "./types/actions";
import { CategoriesListState } from "./types/reducer";
import {
  LOAD_CATEGORIES_LIST,
  LOAD_CATEGORIES_LIST_SUCCESS,
  LOAD_CATEGORIES_LIST_ERROR,
  LOAD_CATEGORIES_SEARCH_OPTIONS,
  LOAD_CATEGORIES_SEARCH_OPTIONS_ERROR,
  LOAD_CATEGORIES_SEARCH_OPTIONS_SUCCESS,
  CATEGORIES_LIST_CLEAN_UP,
  LOAD_CATEGORIES_GRAPH,
  LOAD_CATEGORIES_GRAPH_ERROR,
  LOAD_CATEGORIES_GRAPH_SUCCESS,
  LOAD_CATEGORIES_PIE_GRAPH,
  LOAD_CATEGORIES_PIE_GRAPH_ERROR,
  LOAD_CATEGORIES_PIE_GRAPH_SUCCESS,
  LOAD_PRODUCT_STATUS_OPTIONS,
  LOAD_PRODUCT_STATUS_OPTIONS_ERROR,
  LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS,
  LOAD_CATEGORIES_FOR_EXPORT_SUCCESS,
  LOAD_CATEGORIES_FOR_EXPORT,
  LOAD_CATEGORIES_FOR_EXPORT_ERROR,
} from './constants'

export const initialState: CategoriesListState = {
  isLoading: false,
  searchOptions: [],
  productSegmentOptions: [],
  searchBySegmentOptions: [],
  prodSegmentOptionsSuccess: false,
  success: false,
  listLoading: false,
  categoriesListSuccess: false,
  categoriesList: [],
  totalElements: 0,
  graphData: {
    current: [],
    previous: [],
  },
  graphLoading: false,
  graphSuccess: false,
  donutData: [],
  donutLoading: false,
  donutSuccess: false,
  categoryTotalRow: {
    orders: 0,
    qtySold: 0,
    revenue: '',
    margin: 0,
    grossProfit: '',
    cogs: '',
    qtyAvailable: '',
    qtyRefunded: 0,
    amtRefunded: '',
  },
  exportCategories: [],
  exportListLoading: false,
  exportTotalRows: undefined
}

const reducer = (
  state: CategoriesListState = initialState,
  action: CategoriesListActionTypes
): CategoriesListState => {
  switch (action.type) {
    case LOAD_CATEGORIES_SEARCH_OPTIONS:
      return {
        ...state,
        isLoading: true,
        searchOptions: initialState.searchOptions,
      };
    case LOAD_CATEGORIES_SEARCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        searchOptions: action.payload,
      };
    case LOAD_CATEGORIES_SEARCH_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS:
      return {
        ...state,
        isLoading: true,
        searchBySegmentOptions: initialState.searchBySegmentOptions,
        productSegmentOptions: initialState.productSegmentOptions,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prodSegmentOptionsSuccess: true,
        searchBySegmentOptions: action.payload.searchByOptions,
        productSegmentOptions: action.payload.productSegmentOptions,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        prodSegmentOptionsSuccess: false,
      };

    case LOAD_CATEGORIES_GRAPH:
      return {
        ...state,
        graphLoading: true,
        graphData: initialState.graphData,
      };
    case LOAD_CATEGORIES_GRAPH_SUCCESS:
      return {
        ...state,
        graphLoading: false,
        graphSuccess: true,
        graphData: action.payload,
      };
    case LOAD_CATEGORIES_GRAPH_ERROR:
      return {
        ...state,
        graphLoading: false,
        graphSuccess: false,
      };
    case LOAD_CATEGORIES_PIE_GRAPH:
      return {
        ...state,
        donutLoading: true,
        donutData: initialState.donutData,
      };
    case LOAD_CATEGORIES_PIE_GRAPH_SUCCESS:
      return {
        ...state,
        donutLoading: false,
        donutSuccess: true,
        donutData: action.payload,
      };
    case LOAD_CATEGORIES_PIE_GRAPH_ERROR:
      return {
        ...state,
        donutLoading: false,
        donutSuccess: false,
      };
    case LOAD_CATEGORIES_LIST:
      return {
        ...state,
        listLoading: true,
        categoriesList: initialState.categoriesList,
        totalElements: initialState.totalElements,
        categoryTotalRow: initialState.categoryTotalRow,
      }
    case LOAD_CATEGORIES_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        categoriesListSuccess: true,
        exportListLoading: false,
        categoriesList: action.payload.categoriesList,
        totalElements: action.payload.totalElements || 0,
        categoryTotalRow: action.payload.categoryTotalRow,
      }
    case LOAD_CATEGORIES_LIST_ERROR:
      return {
        ...state,
        listLoading: false,
        categoriesListSuccess: false,
      };

    case CATEGORIES_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    case LOAD_CATEGORIES_FOR_EXPORT:
    return {
      ...state,
      exportListLoading: true,
    }
    case LOAD_CATEGORIES_FOR_EXPORT_SUCCESS:
      return {
        ...state,
        exportCategories: action.payload.list,
        exportTotalRows: action.payload.totalRowValues,
        exportListLoading: false,
        isLoading: false,
      }
    case LOAD_CATEGORIES_FOR_EXPORT_ERROR:
      return {
        ...state,
        exportListLoading: false
      }
    default:
      return state;
  }
};

export default reducer;
