import React, { memo } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import style from './styles'

interface Props {
  id: string
}

const Loading = ({ id }: Props): JSX.Element => {
  const classes = style()
  return (
    <div className={classes.root}>
      <CircularProgress id={`${id}Loading`} color="primary" />
    </div>
  )
}

export default memo(Loading)
