export const LOAD_PRODUCT_DETAILS =
  'APP/PRODUCT/DETAILS/LOAD_PRODUCT_DETAILS'
export const LOAD_PRODUCT_DETAILS_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_PRODUCT_DETAILS_SUCCESS'
export const LOAD_PRODUCT_DETAILS_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_PRODUCT_DETAILS_ERROR'

export const LOAD_PRODUCT_KPI = 'APP/PRODUCT/DETAILS/LOAD_KPI'
export const LOAD_PRODUCT_KPI_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_KPI_SUCCESS'
export const LOAD_PRODUCT_KPI_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_KPI_ERROR'

export const LOAD_SALES_CHART_DATA =
  'APP/PRODUCT/DETAILS/LOAD_SALES_CHART_DATA'
export const LOAD_SALES_CHART_DATA_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_SALES_CHART_DATA_SUCCESS'
export const LOAD_SALES_CHART_DATA_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_SALES_CHART_DATA_ERROR'

export const LOAD_INVENTORY_CHART_DATA =
  'APP/PRODUCT/DETAILS/LOAD_INVENTORY_CHART_DATA'
export const LOAD_INVENTORY_CHART_DATA_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_INVENTORY_CHART_DATA_SUCCESS'
export const LOAD_INVENTORY_CHART_DATA_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_INVENTORY_CHART_DATA_ERROR'

export const LOAD_REFUNDS_CHART_DATA =
  'APP/PRODUCT/DETAILS/LOAD_REFUNDS_CHART_DATA'
export const LOAD_REFUNDS_CHART_DATA_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_REFUNDS_CHART_DATA_SUCCESS'
export const LOAD_REFUNDS_CHART_DATA_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_REFUNDS_CHART_DATA_ERROR'

export const LOAD_PERFORMANCE_LIST =
  'APP/PRODUCT/DETAILS/LOAD_PERFORMANCE_LIST'
export const LOAD_PERFORMANCE_LIST_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_PERFORMANCE_LIST_SUCCESS'
export const LOAD_PERFORMANCE_LIST_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_PERFORMANCE_LIST_ERROR'

export const LOAD_CUSTOMERS_LIST =
  'APP/PRODUCT/DETAILS/LOAD_CUSTOMERS_LIST'
export const LOAD_CUSTOMERS_LIST_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_CUSTOMERS_LIST_SUCCESS'
export const LOAD_CUSTOMERS_LIST_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_CUSTOMERS_LIST_ERROR'

export const LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS =
  'APP/PRODUCT/DETAILS/LOAD_SEARCH_OPTIONS'
export const LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_SEARCH_OPTIONS_SUCCESS'
export const LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_SEARCH_OPTIONS_ERROR'

export const LOAD_BUNDLED_PRODUCTS =
  'APP/PRODUCT/DETAILS/LOAD_BUNDLED_PRODUCTS'
export const LOAD_BUNDLED_PRODUCTS_SUCCESS =
  'APP/PRODUCT/DETAILS/LOAD_BUNDLED_PRODUCTS_SUCCESS'
export const LOAD_BUNDLED_PRODUCTS_ERROR =
  'APP/PRODUCT/DETAILS/LOAD_BUNDLED_PRODUCTS_ERROR'

export const PRODUCT_DETAILS_CLEAN_UP = 'APP/PRODUCT/DETAILS/CLEAN_UP'
