import { Dispatch } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { SET_ERROR } from 'containers/Errors/constants'
import { ErrorActionTypes } from 'containers/Errors/types/actions'
import { loadSidebarList as loadSidebarListApi } from 'api'
import { SidebarListActionTypes } from './types/actions'
import { Menu } from './types/reducer'
import {
  LOAD_SIDEBAR_LIST,
  LOAD_SIDEBAR_LIST_SUCCESS,
  LOAD_SIDEBAR_LIST_ERROR,
  CLEAN_UP,
} from './constants'

const loadSidebarlist: SidebarListActionTypes = {
  type: LOAD_SIDEBAR_LIST,
}

const loadSidebarListSuccess = (
  data: Menu[],
): SidebarListActionTypes => ({
  type: LOAD_SIDEBAR_LIST_SUCCESS,
  payload: data,
})

const loadSidebarListError = (): SidebarListActionTypes => ({
  type: LOAD_SIDEBAR_LIST_ERROR,
})

export const loadSidebarList = (
  role: string,
): ThunkAction<
  Promise<void>,
  null,
  null,
  SidebarListActionTypes
> => async (
  dispatch: Dispatch<SidebarListActionTypes | ErrorActionTypes>,
): Promise<void> => {
  try {
    dispatch(loadSidebarlist)
    const response = await loadSidebarListApi(role)
    dispatch(loadSidebarListSuccess(response.data))
  } catch (error) {
    const err = error && error.message ? error.message : (error || '')
    dispatch(loadSidebarListError())
    dispatch({ type: SET_ERROR, payload: err })
  }
}

export const cleanUp = (): SidebarListActionTypes => ({
  type: CLEAN_UP,
})
