import { SignInActionTypes } from './types/actions'
import { SignInState } from './types/reducer'
import {
  USER_SIGN_IN,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_IN_ERROR,
  SIGN_IN_CLEAN_UP,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  VERIFY_DETAILS,
  VERIFY_DETAILS_SUCCESS,
  VERIFY_DETAILS_ERROR,
} from './constants'

export const initialState: SignInState = {
  isLoading: false,
  success: false,
  data: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    username: '',
    session: '',
    userId: '',
    tenantId: '',
    role: '',
    companyName: '',
    firstLogin: false,
  },
  isUpdateProfile: false,
  changePasswordSuccess: false,
  isVerifyDetails: false,
  isVerifyDetailsSuccess: false,
}

const reducer = (
  state: SignInState = initialState,
  action: SignInActionTypes,
): SignInState => {
  switch (action.type) {
    case USER_SIGN_IN:
      return { ...state, isLoading: true, data: initialState.data }
    case USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      }
    case USER_SIGN_IN_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case SIGN_IN_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    case UPDATE_PROFILE:
      return {
        ...state,
        isLoading: false,
        isUpdateProfile: true,
        success: true,
      }
    case VERIFY_DETAILS:
      return {
        ...state,
        isLoading: false,
        isVerifyDetails: true,
        isVerifyDetailsSuccess: false,
        success: true,
      }
    case VERIFY_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isVerifyDetailsSuccess: true,
        success: true,
      }
    case VERIFY_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        isVerifyDetailsSuccess: false,
        isVerifyDetails: true,
        success: true,
      }
    case CHANGE_PASSWORD:
      return {
        ...state,
        isLoading: true,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        changePasswordSuccess: true,
      }
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        changePasswordSuccess: false,
      }
    default:
      return state
  }
}

export default reducer
