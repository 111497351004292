import { CustomerProfileActionTypes } from "./types/actions";
import { CustomerProfileState } from "./types/reducer";
import {
  LOAD_CUSTOMER_PROFILE,
  LOAD_CUSTOMER_PROFILE_SUCCESS,
  LOAD_CUSTOMER_PROFILE_ERROR,
  LOAD_PROFILE_SUMMARY,
  LOAD_PROFILE_SUMMARY_SUCCESS,
  LOAD_PROFILE_SUMMARY_ERROR,
  LOAD_MATCHING_SEGMENTS,
  LOAD_MATCHING_SEGMENTS_SUCCESS,
  LOAD_MATCHING_SEGMENTS_ERROR,
  CUSTOMER_PROFILE_CLEAN_UP,
  LOAD_CATEGORY_PURCHASES,
  LOAD_CATEGORY_PURCHASES_SUCCESS,
  LOAD_CATEGORY_PURCHASES_ERROR,
  LOAD_TIMELINE_DATA,
  LOAD_TIMELINE_DATA_SUCCESS,
  LOAD_TIMELINE_DATA_ERROR,
  LOAD_TIMELINE_KPI_DATA,
  LOAD_TIMELINE_KPI_DATA_SUCCESS,
  LOAD_TIMELINE_KPI_DATA_ERROR,
  LOAD_INTEREST_DATA,
  LOAD_INTEREST_DATA_ERROR,
  LOAD_INTEREST_DATA_SUCCESS,
  LOAD_BRAND_AFFINITIES_DATA,
  LOAD_BRAND_AFFINITIES_DATA_ERROR,
  LOAD_BRAND_AFFINITIES_DATA_SUCCESS,
  LOAD_CUSTOMER_SUGGESTED_PRODUCTS,
  LOAD_CUSTOMER_SUGGESTED_PRODUCTS_ERROR,
  LOAD_CUSTOMER_SUGGESTED_PRODUCTS_SUCCESS,
} from "./constants";

export const initialState: CustomerProfileState = {
  isLoading: false,
  userProfileSuccess: false,
  userProfileData: {
    customerId: "",
    tenantId: "",
    storeId: "",
    firstName: "",
    lastName: "",
    email: "",
    designation: "",
    phone: "",
    age: 0,
    maritalStatus: "",
    industry: "",
    custAddress: "",
    lastActivity: "",
    lastPurchase: "",
  },
  summaryDataSuccess: false,
  summaryDataLoading: false,
  summaryData: {
    label: "",
    orders: 0,
    totalSpent: "",
    frequency: "",
    churnPercent: "",
    aov: "",
    description: "",
    lapsePoint: "",
  },
  catPurchasesLoading: false,
  catPurchaseSuccess: false,
  catPurchasesData: [],
  timelineData: {
    totalActivities: 0,
    kpi: [],
    today: [],
    yesterday: [],
    lastWeek: [],
    thisMonth: [],
    lastMonth: [],
    Older: [],
  },
  timelineDataSuccess: false,
  timelineDataLoading: false,
  brandAffinitiesInterestsLoading: false,
  interestsSuccess: false,
  interestData: [],
  brandAffinitiesSuccess: false,
  brandAffinitiesData: [],
  matchingSegmentsSuccess: false,
  matchingSegmentsLoading: false,
  matchingSegmentsData: [],
  customerSuggestedProductsSuccess: false,
  customerSuggestedProductsLoading: false,
  customerSuggestedProducts: [],
  timelineKpi: [],
  kpiLoading: false,
  kpiSuccess: false,
}

const reducer = (
  state: CustomerProfileState = initialState,
  action: CustomerProfileActionTypes
): CustomerProfileState => {
  switch (action.type) {
    case LOAD_CUSTOMER_PROFILE:
      return { ...state, isLoading: true };
    case LOAD_CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userProfileSuccess: true,
        userProfileData: action.payload,
      };
    case LOAD_CUSTOMER_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
        userProfileSuccess: false,
      };

    case LOAD_PROFILE_SUMMARY:
      return {
        ...state,
        isLoading: true,
        summaryDataLoading: true,
        summaryData: initialState.summaryData,
      };
    case LOAD_PROFILE_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        summaryDataSuccess: true,
        summaryDataLoading: false,
        summaryData: action.payload,
      };
    case LOAD_PROFILE_SUMMARY_ERROR:
      return {
        ...state,
        isLoading: false,
        summaryDataLoading: false,
        summaryDataSuccess: false,
      };

    case LOAD_MATCHING_SEGMENTS:
      return {
        ...state,
        matchingSegmentsLoading: true,
        matchingSegmentsData: initialState.matchingSegmentsData,
      };
    case LOAD_MATCHING_SEGMENTS_SUCCESS:
      return {
        ...state,
        matchingSegmentsLoading: false,
        matchingSegmentsSuccess: true,
        matchingSegmentsData: action.payload,
      };
    case LOAD_MATCHING_SEGMENTS_ERROR:
      return {
        ...state,
        matchingSegmentsLoading: false,
        matchingSegmentsSuccess: false,
      };

    case LOAD_CATEGORY_PURCHASES:
      return {
        ...state,
        catPurchasesLoading: true,
        catPurchasesData: initialState.catPurchasesData,
      };
    case LOAD_CATEGORY_PURCHASES_SUCCESS:
      return {
        ...state,
        catPurchasesLoading: false,
        catPurchaseSuccess: true,
        catPurchasesData: action.payload,
      };
    case LOAD_CATEGORY_PURCHASES_ERROR:
      return {
        ...state,
        catPurchasesLoading: false,
        catPurchaseSuccess: false,
      }
    case LOAD_TIMELINE_KPI_DATA:
      return {
        ...state,
        kpiLoading: true,
        timelineKpi: initialState.timelineKpi,
      }
    case LOAD_TIMELINE_KPI_DATA_SUCCESS:
      return {
        ...state,
        kpiLoading: false,
        kpiSuccess: true,
        timelineKpi: action.payload,
      }
    case LOAD_TIMELINE_KPI_DATA_ERROR:
      return {
        ...state,
        kpiLoading: false,
        kpiSuccess: false,
      }
    case LOAD_TIMELINE_DATA:
      return {
        ...state,
        timelineDataLoading: true,
        timelineData: initialState.timelineData,
      };
    case LOAD_TIMELINE_DATA_SUCCESS:
      return {
        ...state,
        timelineDataLoading: false,
        timelineDataSuccess: true,
        timelineData: action.payload,
      };
    case LOAD_TIMELINE_DATA_ERROR:
      return {
        ...state,
        timelineDataLoading: false,
        timelineDataSuccess: false,
      };
    case LOAD_INTEREST_DATA:
      return {
        ...state,
        brandAffinitiesInterestsLoading: true,
        interestData: initialState.interestData,
      };
    case LOAD_INTEREST_DATA_SUCCESS:
      return {
        ...state,
        brandAffinitiesInterestsLoading: false,
        interestsSuccess: true,
        interestData: action.payload,
      };
    case LOAD_INTEREST_DATA_ERROR:
      return {
        ...state,
        brandAffinitiesInterestsLoading: false,
        interestsSuccess: false,
      };
    case LOAD_BRAND_AFFINITIES_DATA:
      return {
        ...state,
        brandAffinitiesInterestsLoading: true,
        brandAffinitiesData: initialState.brandAffinitiesData,
      };
    case LOAD_BRAND_AFFINITIES_DATA_SUCCESS:
      return {
        ...state,
        brandAffinitiesInterestsLoading: false,
        brandAffinitiesSuccess: true,
        brandAffinitiesData: action.payload,
      };
    case LOAD_BRAND_AFFINITIES_DATA_ERROR:
      return {
        ...state,
        brandAffinitiesInterestsLoading: false,
        brandAffinitiesSuccess: false,
      };

    case LOAD_CUSTOMER_SUGGESTED_PRODUCTS:
      return {
        ...state,
        customerSuggestedProductsLoading: true,
        customerSuggestedProducts: initialState.customerSuggestedProducts,
      };
    case LOAD_CUSTOMER_SUGGESTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        customerSuggestedProductsLoading: false,
        customerSuggestedProductsSuccess: true,
        customerSuggestedProducts: action.payload,
      };
    case LOAD_CUSTOMER_SUGGESTED_PRODUCTS_ERROR:
      return {
        ...state,
        customerSuggestedProductsLoading: false,
        customerSuggestedProductsSuccess: false,
      };

    case CUSTOMER_PROFILE_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
