import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import './App.css'

const app =
  process.env.NODE_ENV === 'development' ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )

ReactDOM.render(app, document.getElementById('root'))
