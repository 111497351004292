import { MarketingEmailActionTypes } from './types/actions'
import { MarketingEmailState } from './types/reducer'
import {
  LOAD_MARK_EMAILS_FILTERS,
  LOAD_MARK_EMAILS_FILTERS_ERROR,
  LOAD_MARK_EMAILS_FILTERS_SUCCESS,
  LOAD_MARK_COUNTRY_FILTERS,
  LOAD_MARK_COUNTRY_FILTERS_ERROR,
  LOAD_MARK_COUNTRY_FILTERS_SUCCESS,
  LOAD_MARKETING_EMAILS_LIST,
  LOAD_MARKETING_EMAILS_LIST_SUCCESS,
  LOAD_MARKETING_EMAILS_LIST_ERROR,
  LOAD_MARKETING_EMAIL_KPI,
  LOAD_MARKETING_EMAIL_KPI_ERROR,
  LOAD_MARKETING_EMAIL_KPI_SUCCESS,
  MARKETING_EMAIL_CLEAN_UP,
} from './constants'

export const initialState: MarketingEmailState = {
  isLoading: false,
  filters: [],
  filtersLoading: false,
  filtersSuccess: false,
  countryFilters: [],
  countryFiltersLoading: false,
  countryFiltersSuccess: false,
  listLoading: false,
  marketingEmailListSuccess: false,
  marketingEmailList: [],
  totalElements: 0,
  kpiSuccess: false,
  kpiData: {
    emailsSent: '',
    clickRate: '',
    conversionRate: '',
    openRate: '',
    visits: '',
    orderValue: '',
  },
}

const reducer = (
  state: MarketingEmailState = initialState,
  action: MarketingEmailActionTypes,
): MarketingEmailState => {
  switch (action.type) {
    case LOAD_MARK_EMAILS_FILTERS:
      return {
        ...state,
        filtersLoading: true,
        filters: initialState.filters,
      }
    case LOAD_MARK_EMAILS_FILTERS_SUCCESS:
      return {
        ...state,
        filtersLoading: false,
        filtersSuccess: true,
        filters: action.payload,
      }
    case LOAD_MARK_EMAILS_FILTERS_ERROR:
      return {
        ...state,
        filtersLoading: false,
        filtersSuccess: false,
      }
    case LOAD_MARK_COUNTRY_FILTERS:
      return {
        ...state,
        countryFiltersLoading: true,
        countryFilters: initialState.countryFilters,
      }
    case LOAD_MARK_COUNTRY_FILTERS_SUCCESS:
      return {
        ...state,
        countryFiltersLoading: false,
        countryFiltersSuccess: true,
        countryFilters: action.payload,
      }
    case LOAD_MARK_COUNTRY_FILTERS_ERROR:
      return {
        ...state,
        countryFiltersLoading: false,
        countryFiltersSuccess: false,
      }
    case LOAD_MARKETING_EMAILS_LIST:
      return {
        ...state,
        listLoading: true,
        marketingEmailList: initialState.marketingEmailList,
        totalElements: initialState.totalElements,
      }
    case LOAD_MARKETING_EMAILS_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        marketingEmailListSuccess: true,
        marketingEmailList: action.payload.marketingEmailList,
        totalElements: action.payload.totalElements || 0,
      }
    case LOAD_MARKETING_EMAILS_LIST_ERROR:
      return {
        ...state,
        listLoading: false,
        marketingEmailListSuccess: false,
      }
    case LOAD_MARKETING_EMAIL_KPI:
      return {
        ...state,
        isLoading: true,
        kpiData: initialState.kpiData,
      }
    case LOAD_MARKETING_EMAIL_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: true,
        kpiData: action.payload,
      }
    case LOAD_MARKETING_EMAIL_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: false,
      }

    case MARKETING_EMAIL_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
