import { SET_ERROR, REMOVE_ERROR, SET_MESSAGE } from './constants'
import { ErrorActionTypes } from './types/actions'
import { ErrorState } from './types/reducer'

const initState: ErrorState = {
  error: '',
  isOpen: false,
  message: '',
}

const reducer = (
  state: ErrorState = initState,
  action: ErrorActionTypes,
): ErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: action.payload,
        isOpen: true,
        message: '',
      }
    case REMOVE_ERROR:
      return {
        error: '',
        isOpen: false,
        message: '',
      }
    case SET_MESSAGE:
      return {
        message: action.payload,
        isOpen: true,
        error: '',
      }
    default:
      return state
  }
}

export default reducer
