export const LOAD_FIRST_OPTIONS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_FIRST_OPTIONS'
export const LOAD_FIRST_OPTIONS_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_FIRST_OPTIONS_SUCCESS'
export const LOAD_FIRST_OPTIONS_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_FIRST_OPTIONS_ERROR'

export const LOAD_ATTRIBUTE_VALUE =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ATTRIBUTE_VALUE'
export const LOAD_ATTRIBUTE_VALUE_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ATTRIBUTE_VALUE_SUCCESS'
export const LOAD_ATTRIBUTE_VALUE_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ATTRIBUTE_VALUE_ERROR'

export const LOAD_ATTRIBUTE_OPTIONS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ATTRIBUTE_OPTIONS'
export const LOAD_ATTRIBUTE_OPTIONS_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ATTRIBUTE_OPTIONS_SUCCESS'
export const LOAD_ATTRIBUTE_OPTIONS_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ATTRIBUTE_OPTIONS_ERROR'

export const LOAD_OPERATOR_OPTIONS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_OPERATOR_OPTIONS'
export const LOAD_OPERATOR_OPTIONS_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_OPERATOR_OPTIONS_SUCCESS'
export const LOAD_OPERATOR_OPTIONS_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_OPERATOR_OPTIONS_ERROR'

export const NEW_SEGMENTS_CLEAN_UP =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENTS/CLEAN_UP'

export const ADD_NEW_SIMPLE_BLOCK =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/ADD_NEW_SIMPLE_BLOCK'
export const ADD_NEW_SIMPLE_BLOCK_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/ADD_NEW_SIMPLE_BLOCK_SUCCESS'
export const ADD_NEW_SIMPLE_BLOCK_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/ADD_NEW_SIMPLE_BLOCK_ERROR'

export const REMOVE_SIMPLE_BLOCK =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/REMOVE_SIMPLE_BLOCK'
export const REMOVE_SIMPLE_BLOCK_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/REMOVE_SIMPLE_BLOCK_SUCCESS'
export const REMOVE_SIMPLE_BLOCK_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/REMOVE_SIMPLE_BLOCK_ERROR'

export const SAVE_NEW_SEGMENT =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/SAVE'
export const SAVE_NEW_SEGMENT_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/SAVE_SUCCESS'
export const SAVE_NEW_SEGMENT_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/SAVE_ERROR'

export const VALIDATE_SEGMENT =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/VALIDATE_SEGMENT'
export const VALIDATE_SEGMENT_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/VALIDATE_SEGMENT_SUCCESS'
export const VALIDATE_SEGMENT_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/VALIDATE_SEGMENT_ERROR'

export const PREVIEW_SEGMENT =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/PREVIEW_SEGMENT'
export const PREVIEW_SEGMENT_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/PREVIEW_SEGMENT_SUCCESS'
export const PREVIEW_SEGMENT_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/PREVIEW_SEGMENT_ERROR'

export const LOAD_SEGMENT_KPI_OPTIONS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_SEGMENT_KPI_OPTIONS'
export const LOAD_SEGMENT_KPI_OPTIONS_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_SEGMENT_KPI_OPTIONS_SUCCESS'
export const LOAD_SEGMENT_KPI_OPTIONS_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_SEGMENT_KPI_OPTIONS_ERROR'

export const LOAD_ALL_SEGMENTS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ALL_SEGMENTS'
export const LOAD_ALL_SEGMENTS_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ALL_SEGMENTS_SUCCESS'
export const LOAD_ALL_SEGMENTS_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/LOAD_ALL_SEGMENTS_ERROR'

export const DELETE_SEGMENT =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/DELETE_SEGMENT'
export const DELETE_SEGMENT_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/DELETE_SEGMENT_SUCCESS'
export const DELETE_SEGMENT_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/DELETE_SEGMENT_ERROR'

export const EDIT_SEGMENT =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/EDIT_SEGMENT'
export const EDIT_SEGMENT_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/EDIT_SEGMENT_SUCCESS'
export const EDIT_SEGMENT_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/EDIT_SEGMENT_ERROR'

export const CLEAR_BLOCKS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/CLEAR_BLOCKS'
export const CLEAR_BLOCKS_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/CLEAR_BLOCKS_SUCCESS'
export const CLEAR_BLOCKS_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/CLEAR_BLOCKS_ERROR'

export const VALIDATE_EDIT_COPY =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/VALIDATE_EDIT_COPY'
export const VALIDATE_EDIT_COPY_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/VALIDATE_EDIT_COPY_SUCCESS'
export const VALIDATE_EDIT_COPY_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/VALIDATE_EDIT_COPY_ERROR'

export const SCORE_PREDICTION =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/SCORE_PREDICTION'
export const SCORE_PREDICTION_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/SCORE_PREDICTION_SUCCESS'
export const SCORE_PREDICTION_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/NEW_SEGMENT/SCORE_PREDICTION_ERROR'
