import React, { memo } from 'react'
import Button from '@material-ui/core/Button'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMoreRounded'
import { Divider, Paper } from '@material-ui/core'
import useStyles from './styles'

interface Props {
  clearAll: () => void
}

const Notification = ({ clearAll }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <Paper id="notification-item" className={classes.root}>
      <p className={classes.timelineHeading}>This Week</p>
      <Divider className={classes.divider} />
      <div className={classes.headingSection}>
        <div className={classes.pointAlignment}>
          <div className={classes.icon} />
          <div className={classes.txt}>New User Onboarded</div>
        </div>
        <div className={classes.list}>
          <p>
            <strong>Kinanthi Ayani</strong> has joined today
          </p>
          <p>
            <strong>Adam Williams</strong> has joined 1 day ago
          </p>
          <p>
            <strong>Sam Smith</strong> has joined 2 days ago
          </p>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.pointAlignment}>
        <div className={classes.icon} />
        <div className={classes.txt}>
          50 users of Abandoned carts has made successfully purchased.
          2 days ago
        </div>
      </div>
      <div className={classes.txt2}>
        <UnfoldMoreIcon
          className={classes.moreItemIcon}
          color="primary"
        />
        <p className={classes.txt}>
          View 10 more emails from this week
        </p>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.pointAlignment}>
        <div className={classes.icon} />
        <div className={classes.txt}>
          Campaign name 1 Syndication updated with new customers
        </div>
      </div>
      <Divider className={classes.divider} />
      <Button
        color="primary"
        className={classes.btn}
        onClick={clearAll}
      >
        Clear All
      </Button>
    </Paper>
  )
}

export default memo(Notification)
