import { makeStyles, Theme } from '@material-ui/core/styles'

type Props = {
  searchWidth: string
}

const useStyles = makeStyles<Theme, Props>(() => ({
  searchContainer: {
    width: ({ searchWidth }) => searchWidth,
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginLeft: '1rem',
  },
  input: {
    borderRadius: 0,
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}))

export default useStyles
