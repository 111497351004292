import { CustomerDBActionTypes } from "./types/actions";
import { CustomerDBState } from "./types/reducer";
import {
  LOAD_CUSTOMER_OPTIONS,
  LOAD_CUSTOMER_OPTIONS_SUCCESS,
  LOAD_CUSTOMER_OPTIONS_ERROR,
  CUSTOMER_DB_CLEAN_UP,
  SEARCH_DB,
  SEARCH_DB_SUCCESS,
  SEARCH_DB_ERROR,
  LOAD_SEARCH_DB_FOR_EXPORT_SUCCESS,
  LOAD_SEARCH_DB_FOR_EXPORT,
  LOAD_SEARCH_DB_FOR_EXPORT_ERROR,
} from './constants'

export const initialState: CustomerDBState = {
  isLoading: false,
  customerOptionsSuccess: false,
  customerDBSuccess: false,
  customerOptions: [],
  customerList: [],
  totalElements: 0,
  totalRow: {
    totalAOV: "",
    totalDiscount: "",
    totalOrder: "",
    totalRefund: "",
    totalRevenue: "",
  },
  fromDate: '',
  toDate: '',
  exportCustomersList: [],
  exportListLoading: false,
  exportTotalRows: undefined,
}

const reducer = (
  state: CustomerDBState = initialState,
  action: CustomerDBActionTypes
): CustomerDBState => {
  switch (action.type) {
    case LOAD_CUSTOMER_OPTIONS:
      return {
        ...state,
        isLoading: true,
        customerOptions: initialState.customerOptions,
      };
    case LOAD_CUSTOMER_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerOptionsSuccess: true,
        customerOptions: action.payload,
      };
    case LOAD_CUSTOMER_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        customerOptionsSuccess: false,
      };
    case SEARCH_DB:
      return {
        ...state,
        isLoading: true,
        customerList: initialState.customerList,
        totalElements: initialState.totalElements,
        totalRow: initialState.totalRow,
      };
    case SEARCH_DB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        customerDBSuccess: true,
        customerList: action.payload.customerList,
        totalElements: action.payload.totalElements,
        totalRow: action.payload.totalRow,
      };
    case SEARCH_DB_ERROR:
      return {
        ...state,
        isLoading: false,
        customerDBSuccess: false,
      }
    case LOAD_SEARCH_DB_FOR_EXPORT:
      return {
        ...state,
        exportListLoading: true,
      }
    case LOAD_SEARCH_DB_FOR_EXPORT_SUCCESS:
      return {
        ...state,
        exportListLoading: false,
        exportTotalRows: action.payload.totalRowValues,
        exportCustomersList: action.payload.list,
      }
    case LOAD_SEARCH_DB_FOR_EXPORT_ERROR:
      return {
        ...state,
        exportListLoading: false,
      }
    case CUSTOMER_DB_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
