/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useEffect, useCallback } from "react";
import Header from "containers/Header/Dashboard";
import SideBar from "containers/SideBar";
import ErrorBoundary from "containers/ErrorBoundary";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { userSignOut } from "containers/Header/actions";
import styles from "./styles";
import { RootStore } from "store";

interface Props {
  children: React.ReactNode;
}

const Main: React.FC<Props> = ({ children, ...props }: Props) => {
  const { userId, authToken } = useSelector(
    (store: RootStore) => store.userAuth.loginData
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = styles();

  const timeout = 30 * 60 * 1000;

  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [lastActive, setLastActive] = useState<any>(new Date());
  const [isIdle, setIsIdle] = useState(false);

  const handleOnActive = () => setIsIdle(false);

  const handleLogout = useCallback(() => {
    dispatch(
      userSignOut(
        userId,
        true,
        authToken,
        moment().utc().format("yyyy-MM-DD hh:mm:ss")
      )
    );
    history.push({
      pathname: "/signin",
    });
  }, [dispatch]);

  const handleOnIdle = () => {
    setIsIdle(true);
    handleLogout();
  };
  const { getRemainingTime, getLastActiveTime, getElapsedTime } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    setRemaining(getRemainingTime());
    setLastActive(getLastActiveTime());
    setElapsed(getElapsedTime());

    setInterval(() => {
      setLastActive(getLastActiveTime());
    }, 5000);
  }, []);

  return (
    <SideBar {...props}>
      <ErrorBoundary>
        <Header {...props} />
      </ErrorBoundary>
      <ErrorBoundary>
        <div className={classes.mainContainer}>{children}</div>
      </ErrorBoundary>
    </SideBar>
  );
};

export default memo(Main);
