import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: '90%',
    margin: '0 auto',
    minHeight: '10rem',
    position: 'relative',
  },
}))

export default useStyles
