/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo } from "react";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import BellImg from "assets/img/header-notification.svg";
import arrowIcon from "assets/img/down-arrow-icon.svg";
import SearchBox from "components/common/SearchBox";
import Notification from "components/Notification";
import { userSignOut } from "containers/Header/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { RootStore } from "store";

interface Props {
  anchorEl: HTMLElement | null;
  notificationAnchorEl: HTMLElement | null;
  handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleClickAway: (event: React.MouseEvent<Document, MouseEvent>) => void;
  handleNotificationClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleLogout: () => void;
  handleChangePassword: () => void;
  clearAll: () => void;
  handleProfile: () => void;
}

const Header: FC<Props> = ({
  anchorEl,
  notificationAnchorEl,
  handleClick,
  handleClickAway,
  handleNotificationClick,
  handleLogout,
  handleChangePassword,
  clearAll,
  handleProfile,
}: Props) => {
  const open = Boolean(anchorEl);
  const notiOpen = Boolean(notificationAnchorEl);
  const { username, userId, authToken } = useSelector(
    (state: RootStore) => state.userAuth.loginData
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const onSearch = () => {};

  function closingCode() {
    dispatch(userSignOut(userId, false, authToken));
    history.push({
      pathname: "/signin",
    });
    return null;
  }
  // window.onbeforeunload = closingCode
  return (
    <>
      <header className={classes.container}>
        <div className={classes.wrapper}>
          <SearchBox
            id="dashboardHeaderSearch"
            searchWidth="45%"
            placeholder="Search..."
            onClick={onSearch}
          />
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.buttonContainer}>
              <Button
                id="userMenuLink"
                aria-controls="user-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.menu}
              >
                <span>{username}</span>
                <img
                  src={arrowIcon}
                  alt="select arrow icon"
                  className={classes.arrowIcon}
                />
              </Button>
              <Button
                id="notificationLink"
                aria-controls="notification"
                aria-haspopup="true"
                // onClick={handleNotificationClick}
                className={classes.menu2}
              >
                <img src={BellImg} alt="notification" />
              </Button>
              <Popper
                id="user-menu"
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                className={classes.userMenuPopper}
              >
                <Paper>
                  <MenuItem id="profileLink" onClick={handleProfile}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    id="changePasswordLink"
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </MenuItem>
                  <MenuItem id="logoutLink" onClick={handleLogout}>
                    Logout
                  </MenuItem>
                </Paper>
              </Popper>
              <Popper
                id="notification"
                open={notiOpen}
                anchorEl={notificationAnchorEl}
                placement="bottom-end"
                className={classes.popper}
              >
                <Notification clearAll={clearAll} />
              </Popper>
            </div>
          </ClickAwayListener>
        </div>
      </header>
    </>
  );
};

export default memo(Header);
