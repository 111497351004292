import { CategoryListActionTypes } from "./types/actions";
import { CategoryListState } from "./types/reducer";
import {
  LOAD_PRODUCT_CATEGORY_KPI,
  LOAD_PRODUCT_CATEGORY_KPI_ERROR,
  LOAD_PRODUCT_CATEGORY_KPI_SUCCESS,
  LOAD_PRODUCT_IN_CATEGORY,
  LOAD_PRODUCT_IN_CATEGORY_SUCCESS,
  LOAD_PRODUCT_IN_CATEGORY_ERROR,
  LOAD_CATEGORY_SEARCH_OPTIONS,
  LOAD_CATEGORY_SEARCH_OPTIONS_ERROR,
  LOAD_CATEGORY_SEARCH_OPTIONS_SUCCESS,
  LOAD_CUSTOMER_IN_CATEGORY_ERROR,
  LOAD_CUSTOMER_IN_CATEGORY,
  LOAD_CUSTOMER_IN_CATEGORY_SUCCESS,
  CATEGORY_LIST_CLEAN_UP,
} from "./constants";

export const initialState: CategoryListState = {
  isLoading: false,
  success: false,
  productListLoading: false,
  productListSuccess: false,
  productList: [],
  categoryProductTotalRow: {
    orders: 0,
    qtySold: 0,
    revenue: "",
    grossProfit: "",
    totalMargin: "",
    qtyAvailable: 0,
    cogs: '',
    qtyRefunded: '',
    amtRefunded: '',
  },
  categoryCustomerTotalRow: {
    orders: 0,
    ordersTotal: 0,
    qtySold: 0,
    revenue: "",
    amtRefunded: "",
    lifeTimeRevenue: "",
    qtyRefunded: "",
  },
  productTotalElements: 0,
  kpi: {
    revenue: "",
    qtySold: 0,
    sku: "",
    grossProfit: "",
    orders: 0,
    amtRefunded: '',
  },
  searchSuccess: false,
  productSearchOptions: [],
  custSearchOptions: [],
  custListLoading: false,
  custList: [],
  custListSuccess: false,
  custTotalElements: 0,
};

const reducer = (
  state: CategoryListState = initialState,
  action: CategoryListActionTypes
): CategoryListState => {
  switch (action.type) {
    case LOAD_PRODUCT_CATEGORY_KPI:
      return {
        ...state,
        isLoading: true,
        kpi: initialState.kpi,
      };
    case LOAD_PRODUCT_CATEGORY_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        kpi: action.payload,
      };
    case LOAD_PRODUCT_CATEGORY_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };

    case LOAD_CATEGORY_SEARCH_OPTIONS:
      return {
        ...state,
        isLoading: true,
        productSearchOptions: initialState.productSearchOptions,
        custSearchOptions: initialState.custSearchOptions,
      };
    case LOAD_CATEGORY_SEARCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchSuccess: true,
        productSearchOptions: action.payload.productSearchOptions,
        custSearchOptions: action.payload.customerSearchOptions,
      };
    case LOAD_CATEGORY_SEARCH_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        searchSuccess: false,
      };

    case LOAD_PRODUCT_IN_CATEGORY:
      return {
        ...state,
        productListLoading: true,
        productList: initialState.productList,
        productTotalElements: initialState.productTotalElements,
        categoryProductTotalRow: initialState.categoryProductTotalRow,
      };
    case LOAD_PRODUCT_IN_CATEGORY_SUCCESS:
      return {
        ...state,
        productListLoading: false,
        productListSuccess: true,
        productList: action.payload.list,
        productTotalElements: action.payload.totalElements || 0,
        categoryProductTotalRow: action.payload.totalRow,
      };
    case LOAD_PRODUCT_IN_CATEGORY_ERROR:
      return {
        ...state,
        productListLoading: false,
        productListSuccess: false,
      };

    case LOAD_CUSTOMER_IN_CATEGORY:
      return {
        ...state,
        custListLoading: true,
        custList: initialState.custList,
        custTotalElements: initialState.custTotalElements,
        categoryCustomerTotalRow: initialState.categoryCustomerTotalRow,
      };
    case LOAD_CUSTOMER_IN_CATEGORY_SUCCESS:
      return {
        ...state,
        custListLoading: false,
        custListSuccess: true,
        custList: action.payload.list,
        custTotalElements: action.payload.totalElements || 0,
        categoryCustomerTotalRow: action.payload.totalRow,
      };
    case LOAD_CUSTOMER_IN_CATEGORY_ERROR:
      return {
        ...state,
        custListLoading: false,
        custListSuccess: false,
      };

    case CATEGORY_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
