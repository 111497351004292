/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  CustomFilter,
  ObjToSend,
} from "containers/Product/Toolbar/types/reducer";
import mantixApiInstance from "../interceptors/interceptor";

const PAGE = "product/";
const PRODUCTS_SEGMENTS = "customer/";
const LOAD_PRODUCT_LIST_URL = process.env.REACT_APP_LOAD_PRODUCT_LIST;
const LOAD_OPTIONS_URL = process.env.REACT_APP_LOAD_SEGMENT_OPTIONS;
const LOAD_PRODUCT_COST_LIST_URL = process.env.REACT_APP_LOAD_PRODUCT_COST_LIST;
const LOAD_CATEGORIES_LIST_URL = process.env.REACT_APP_LOAD_CATEGORIES_LIST;
const LOAD_INVENTORY_LIST_URL = process.env.REACT_APP_LOAD_INVENTORY_LIST;
const LOAD_PRODUCT_KPI_URL = process.env.REACT_APP_LOAD_PRODUCT_KPI;
const LOAD_PRODUCT_DETAIL_URL = process.env.REACT_APP_LOAD_PRODUCT_DETAIL;
const LOAD_PRODUCT_GRAPH_URL = process.env.REACT_APP_LOAD_PRODUCT_GRAPH;
const LOAD_PERFORMANCE_LIST_URL =
  process.env.REACT_APP_LOAD_VARIANT_PERFORMANCE_LIST;
const LOAD_CUSTOMERS_LIST_URL =
  process.env.REACT_APP_LOAD_PURCHASING_CUSTOMERS_LIST;
const LOAD_PRODUCT_CATEGORY_LIST_URL =
  process.env.REACT_APP_LOAD_PRODUCT_CATEGORY_LIST;
const LOAD_PRODUCT_CATEGORY_KPI_URL =
  process.env.REACT_APP_LOAD_PRODUCT_CATEGORY_KPI;
const LOAD_CATEGORY_PRODUCT_LIST_URL =
  process.env.REACT_APP_LOAD_CATEGORY_PRODUCT_LIST;
const LOAD_CATEGORY_CUSTOMER_LIST_URL =
  process.env.REACT_APP_LOAD_CATEGORY_CUSTOMER_LIST;
const LOAD_PRODUCT_SEGMENT_URL = process.env.REACT_APP_LOAD_PRODUCT_SEGMENT;
const LOAD_FILTER_OPERATORS_OPTIONS_URL =
  process.env.REACT_APP_LOAD_FILTER_OPERATORS_OPTIONS;
const SAVE_PRODUCT_FILTER_URL = process.env.REACT_APP_SAVE_FILTER;
const LOAD_BUNDLED_PRODUCTS_URL = process.env.REACT_APP_LOAD_BUNDLED_PRODUCTS;
const LOAD_FILTER_CATEGORIES_URL = process.env.REACT_APP_LOAD_SEGMENT_OPERATORS;
const LOAD_CATEGORIES_GRAPH_URL =
  process.env.REACT_APP_LOAD_PRODUCT_CATEGORIES_GRAPH;
const LOAD_CATEGORIES_PIE_GRAPH_URL =
  process.env.REACT_APP_LOAD_PRODUCT_CATEGORIES_PIE_GRAPH;
const LOAD_OVERVIEW_CATEGORIES_URL =
  process.env.REACT_APP_LOAD_OVERVIEW_CATEGORIES;
const LOAD_OVERVIEW_SUB_CATEGORIES_URL =
  process.env.REACT_APP_LOAD_OVERVIEW_SUB_CATEGORIES;
const LOAD_OVERVIEW_TOP_PRODUCTS_URL =
  process.env.REACT_APP_LOAD_OVERVIEW_TOP_PRODUCTS;
const REACT_APP_LOAD_OVERVIEW_TOP_BRAND_PERFORMANCE_URL =
  process.env.REACT_APP_LOAD_OVERVIEW_TOP_BRAND_PERFORMANCE;
const REACT_APP_LOAD_CATEGORY_HEIRCHY_URL =
  process.env.REACT_APP_LOAD_CATEGORY_HEIRCHY;
const REACT_APP_LOAD_LOCATION_HEIRCHY_URL =
  process.env.REACT_APP_LOAD_LOCATION_HEIRCHY;
const SAVE_PRODUCT_INVENTORY_FILTER_URL =
  process.env.REACT_APP_SAVE_INVENTORY_FILTER;

export const loadProductList = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    segmentName: string
    searchKey: string
    searchValue: string
    sortOrder: string
    sortBy: string
    categoryId: number | null
    exportFlag: boolean
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  let param: any = {
    ...params,
    export: params.exportFlag,
  }
  delete param.exportFlag
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_LIST_URL}`,
    method: 'post',
    params: param,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadOptionsByDescription = (params: {
  tenantId: string;
  description: string;
  userId?: string;
  includeCustom?: boolean;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PRODUCTS_SEGMENTS}${LOAD_OPTIONS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCategoriesList = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    searchKey: string
    searchValue: string
    categoryId: number | null
    segmentName: string
    sortOrder: string
    sortBy: string
    exportFlag: boolean
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  let param: any = {
    ...params,
    export: params.exportFlag,
  }
  delete param.exportFlag
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CATEGORIES_LIST_URL}`,
    method: 'post',
    params: param,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadOptionsByCategory = (params: {
  tenantId: string;
  category: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PRODUCTS_SEGMENTS}${LOAD_OPTIONS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadProductCostList = (
  params: {
    tenantId: string;
    storeId: string;
    from: string;
    to: string;
    page: number;
    pageSize: number;
    searchKey: string;
    searchValue: string;
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_COST_LIST_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadInventoryList = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    searchKey: string
    searchValue: string
    segmentName: string
    segmentType: string
    sortOrder: string
    sortBy: string
    categoryId: number | null
    exportFlag: boolean
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  let param: any = {
    ...params,
    export: params.exportFlag,
  }
  delete param.exportFlag
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_INVENTORY_LIST_URL}`,
    method: 'post',
    params: param,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadProductKPI = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  productCode: string;
  locationCode: number;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_KPI_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadProductDetail = (params: {
  tenantId: string;
  storeId: string;
  productCode: string;
  locationCode: number;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_DETAIL_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadProductChartData = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  productCode: string;
  type: string;
  period: string;
  locationCode: number;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadPerformanceList = (
  params: {
    tenantId: string;
    storeId: string;
    from: string;
    to: string;
    page: number;
    pageSize: number;
    productCode: string;
    searchKey: string;
    searchValue: string;
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PERFORMANCE_LIST_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomersList = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    productCode: string
    searchKey: string
    searchValue: string
    sortOrder: string
    sortBy: string
    locationCode: number
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMERS_LIST_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadProductCategoryList = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  page: number;
  pageSize: number;
  categoryId: string;
  searchKey: string;
  searchValue: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_CATEGORY_LIST_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadProductCategoryKPI = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  categoryId: string;
  locationCode: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_CATEGORY_KPI_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadProductsInCategory = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    categoryId: string
    searchKey: string
    searchValue: string
    sortOrder: string
    sortBy: string
    locationCode: string
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CATEGORY_PRODUCT_LIST_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomersInCategory = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    categoryId: string
    searchKey: string
    searchValue: string
    sortOrder: string
    sortBy: string
    locationCode: string
  },
  filterObject: CustomFilter
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CATEGORY_CUSTOMER_LIST_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadSegment = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  label: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_SEGMENT_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadFilterOperatorOptions = (params: {
  tenantId: string;
  segmentName: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_FILTER_OPERATORS_OPTIONS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

const getSaveFilterUrl = (isInventoryPage: boolean) => {
  if (isInventoryPage) return SAVE_PRODUCT_INVENTORY_FILTER_URL;
  return SAVE_PRODUCT_FILTER_URL;
};

export const saveNewFilter = (
  params: {
    tenantId: string;
    userId: string;
    storeId: string;
    segmentName: string;
    saveAsSegment: boolean;
    logicalOperator: string;
  },
  objToSend: ObjToSend[],
  isInventoryPage: boolean
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${getSaveFilterUrl(isInventoryPage)}`,
    method: "post",
    data: objToSend,
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadBundledProduct = (params: {
  tenantId: string;
  storeId: string;
  productCode: string;
  from: string;
  to: string;
  locationCode: number;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_BUNDLED_PRODUCTS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadFilterCategories = (params: {
  tenantId: string;
  storeId: string;
  segmentName: string;
  categories: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_FILTER_CATEGORIES_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCategoriesGraph = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CATEGORIES_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadCategoriesPieGraph = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CATEGORIES_PIE_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
// product overview apis
export const loadOverviewCategoryOptions = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_OVERVIEW_CATEGORIES_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadOverviewSubCategoryOptions = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_OVERVIEW_SUB_CATEGORIES_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadOverviewTopProducts = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_OVERVIEW_TOP_PRODUCTS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadOverviewTopBrandPerformance = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${REACT_APP_LOAD_OVERVIEW_TOP_BRAND_PERFORMANCE_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCategoryHeirchy = (params: {
  tenantId: string;
  storeId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${REACT_APP_LOAD_CATEGORY_HEIRCHY_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadLocationHeirchy = (params: {
  tenantId: string;
  storeId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${REACT_APP_LOAD_LOCATION_HEIRCHY_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
