import { ZendeskActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_ZENDESK,
  INTEGRATE_ZENDESK_SUCCESS,
  INTEGRATE_ZENDESK_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    zendesk: {
      subDomain: '',
      emailAddress: '',
      password: '',
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: ZendeskActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_ZENDESK:
      return { ...state, isLoading: true }
    case INTEGRATE_ZENDESK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_ZENDESK_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
