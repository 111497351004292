export interface UserAttributes {
  firstName: "";
  email: "";
  lastName: "";
  phoneNumber: "";
}

export interface Data {
  user: {
    attributes: UserAttributes;
    username: "";
    session: "";
    signInUserSession: {
      jwtToken: string;
    };
    groups: string[];
  };
  userId: string;
}

export interface ILocationInfo {
  asn?: string;
  city?: string;
  continent_code?: string;
  country?: string;
  country_area?: string;
  country_calling_code?: string;
  country_capital?: string;
  country_code?: string;
  country_code_iso3?: string;
  country_name?: string;
  country_population?: number;
  country_tld?: string;
  currency?: string;
  currency_name?: string;
  in_eu?: boolean;
  ip?: string;
  languages?: string;
  latitude?: number;
  longitude?: number;
  network?: string;
  org?: string;
  postal?: string;
  region?: string;
  region_code?: string;
  timezone?: string;
  utc_offset?: string;
  version?: string;
}

export interface AppState {
  data: Data;
  storeLogo: string;
  isLoggedIn: boolean;
  isAuthenticating: boolean;
  locationInfo: ILocationInfo;
  loginData: ILoginData;
  error: string;
}

export interface ILoginData {
  role?: any;
  storeLogo?: any;
  storeId?: any;
  tenantId?: any;
  companyName?: any;
  userId?: any;
  username?: any;
  authToken?: any;
  disableMFA?: any;
  email?: any;
  updateProfile?: boolean;
}

export const appData: Data = {
  user: {
    attributes: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    },
    username: "",
    session: "",
    signInUserSession: {
      jwtToken: "",
    },
    groups: [],
  },
  userId: "",
};

export const appLocation: ILocationInfo = {
  asn: "",
  city: "",
  continent_code: "",
  country: "",
  country_area: undefined,
  country_calling_code: "",
  country_capital: "",
  country_code: "",
  country_code_iso3: "",
  country_name: "",
  country_population: undefined,
  country_tld: "",
  currency: "",
  currency_name: "",
  in_eu: false,
  ip: "",
  languages: "",
  latitude: undefined,
  longitude: undefined,
  network: "",
  org: "",
  postal: "",
  region: "",
  region_code: "",
  timezone: "",
  utc_offset: "",
  version: "",
};
