import { OrdersDBActionTypes } from "./types/actions";
import { OrdersDBState } from "./types/reducer";
import {
  LOAD_ORDERS_DB,
  LOAD_ORDERS_DB_SUCCESS,
  LOAD_ORDERS_DB_ERROR,
  LOAD_ORDERS_OPTIONS,
  LOAD_ORDERS_OPTIONS_SUCCESS,
  LOAD_ORDERS_OPTIONS_ERROR,
  LOAD_ORDER_FILTER_OPTIONS,
  LOAD_ORDER_FILTER_OPTIONS_SUCCESS,
  LOAD_ORDER_FILTER_OPTIONS_ERROR,
  LOAD_ORDER_FILTER_BY_BRANDS_INITIALS,
  LOAD_ORDER_FILTER_BY_BRAND_INITIALS_SUCCESS,
  LOAD_ORDER_FILTER_BY_BRAND_INITIALS_ERROR,
  LOAD_ORDER_FILTER_BY_BRANDS,
  LOAD_ORDER_FILTER_BY_BRAND_SUCCESS,
  LOAD_ORDER_FILTER_BY_BRAND_ERROR,
  ORDERS_DB_CLEAN_UP,
  LOAD_ORDER_FILTER_BY_STORES_SUCCESS,
  LOAD_ORDER_FILTER_BY_STORES,
  LOAD_ORDER_FILTER_BY_STORES_ERROR,
  LOAD_ORDERS_DB_FOR_EXPORT,
  LOAD_ORDERS_DB_FOR_EXPORT_SUCCESS,
  LOAD_ORDERS_DB_FOR_EXPORT_ERROR,
} from './constants'

export const initialState: OrdersDBState = {
  isLoading: false,
  ordersOptionsSuccess: false,
  ordersDBSuccess: false,
  ordersOptions: [],
  filterOptions: [],
  filterOptionsSuccess: false,
  ordersList: [],
  totalElements: 0,
  totalRow: {
    totalDiscount: "",
    totalGrossRevenue: "",
    totalRefunded: "",
    totalShipping: "",
    totalTax: "",
  },
  brands: [],
  initialsIsLoading: false,
  initialsSuccess: false,
  initials: [],
  initialsTotalElements: 0,
  brandDataIsLoading: false,
  brandDataSuccess: false,
  storesDataIsLoading: false,
  storesDataSuccess: false,
  storesDatas: [],
  exportOrderList: [],
  exportListLoading: false,
  exportTotalRows: undefined,
}

const reducer = (
  state: OrdersDBState = initialState,
  action: OrdersDBActionTypes
): OrdersDBState => {
  switch (action.type) {
    case LOAD_ORDERS_DB:
      return {
        ...state,
        isLoading: true,
        ordersList: initialState.ordersList,
        totalElements: initialState.totalElements,
        totalRow: initialState.totalRow,
      };
    case LOAD_ORDERS_DB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ordersDBSuccess: true,
        ordersList: action.payload.ordersList,
        totalElements: action.payload.totalElements,
        totalRow: action.payload.totalRow,
      };
    case LOAD_ORDERS_DB_ERROR:
      return {
        ...state,
        isLoading: false,
        ordersDBSuccess: false,
      };

    case LOAD_ORDERS_OPTIONS:
      return {
        ...state,
        isLoading: true,
        ordersOptions: initialState.ordersOptions,
      };
    case LOAD_ORDERS_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ordersOptionsSuccess: true,
        ordersOptions: action.payload,
      };
    case LOAD_ORDERS_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        ordersOptionsSuccess: false,
      };

    case LOAD_ORDER_FILTER_OPTIONS:
      return {
        ...state,
        isLoading: true,
        filterOptions: initialState.filterOptions,
      };
    case LOAD_ORDER_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filterOptionsSuccess: true,
        filterOptions: action.payload,
      };
    case LOAD_ORDER_FILTER_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        filterOptionsSuccess: false,
      };
    case LOAD_ORDER_FILTER_BY_BRANDS:
      return {
        ...state,
        brandDataIsLoading: true,
        brands: initialState.brands,
        initialsTotalElements: initialState.initialsTotalElements,
      };
    case LOAD_ORDER_FILTER_BY_BRAND_SUCCESS:
      return {
        ...state,
        brandDataIsLoading: false,
        brandDataSuccess: true,
        brands: action.payload.brands,
        initialsTotalElements: action.payload.totalElements,
      };
    case LOAD_ORDER_FILTER_BY_BRAND_ERROR:
      return {
        ...state,
        brandDataIsLoading: false,
        brandDataSuccess: false,
      };
    case LOAD_ORDER_FILTER_BY_BRANDS_INITIALS:
      return {
        ...state,
        initialsIsLoading: true,
        initials: initialState.initials,
      };
    case LOAD_ORDER_FILTER_BY_BRAND_INITIALS_SUCCESS:
      return {
        ...state,
        initialsIsLoading: false,
        initialsSuccess: true,
        initials: action.payload,
      };
    case LOAD_ORDER_FILTER_BY_BRAND_INITIALS_ERROR:
      return {
        ...state,
        initialsIsLoading: false,
        initialsSuccess: false,
      };
    case LOAD_ORDER_FILTER_BY_STORES:
      return {
        ...state,
        storesDataIsLoading: true,
        storesDatas: initialState.storesDatas,
      };
    case LOAD_ORDER_FILTER_BY_STORES_SUCCESS:
      return {
        ...state,
        storesDataIsLoading: false,
        storesDataSuccess: true,
        storesDatas: action.payload,
      };
    case LOAD_ORDER_FILTER_BY_STORES_ERROR:
      return {
        ...state,
        storesDataIsLoading: false,
        storesDataSuccess: false,
      }
    case LOAD_ORDERS_DB_FOR_EXPORT:
      return {
        ...state,
        exportListLoading: true
      }
    case LOAD_ORDERS_DB_FOR_EXPORT_SUCCESS:
      return {
        ...state,
        exportListLoading: false,
        exportOrderList: action.payload.list,
        exportTotalRows: action.payload.totalRowValues,
      }
    case LOAD_ORDERS_DB_FOR_EXPORT_ERROR:
      return {
        ...state,
        exportListLoading: false,
      }
    case ORDERS_DB_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
