import { CustomerHighlightsActionTypes } from './types/actions'
import { CustomerHighlightsState } from './types/reducer'
import {
  LOAD_CUSTOMER_HIGHLIGHTS,
  LOAD_CUSTOMER_HIGHLIGHTS_SUCCESS,
  LOAD_CUSTOMER_HIGHLIGHTS_ERROR,
  LOAD_CUSTOMER_OVERVIEW_GRAPH,
  LOAD_CUSTOMER_OVERVIEW_GRAPH_SUCCESS,
  LOAD_CUSTOMER_OVERVIEW_GRAPH_ERROR,
  LOAD_CUSTOMER_RFM_ANALYSIS,
  LOAD_CUSTOMER_RFM_ANALYSIS_SUCCESS,
  LOAD_CUSTOMER_RFM_ANALYSIS_ERROR,
  LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS,
  LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_SUCCESS,
  LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_ERROR,
  LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON,
  LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_SUCCESS,
  LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_ERROR,
  LOAD_CATEGORY_LIST,
  LOAD_CATEGORY_LIST_SUCCESS,
  LOAD_CATEGORY_LIST_ERROR,
  CUSTOMER_HIGHLIGHTS_CLEAN_UP,
} from './constants'

export const initialState: CustomerHighlightsState = {
  isLoading: false,
  highlightSuccess: false,
  highlightsData: [],
  overviewGraphSuccess: false,
  isChartLoading: false,
  overviewGraphData: {
    new: {
      totalCount: 0,
      percentage: '',
      data: [],
    },
    refunded: {
      totalCount: 0,
      percentage: '',
      data: [],
    },
    returning: {
      totalCount: 0,
      percentage: '',
      data: [],
    },
    dateLabels: [],
  },
  rfmAnalysisSuccess: false,
  rfmAnalysisData: [],
  isRfmLoading: false,
  rfmAnalysisFiltersSuccess: false,
  rfmAnalysisFiltersLoading: false,
  rfmAnalysisFiltersData: {
    recencyOptions: [],
    monetizationOptions: [],
    frequencyOptions: [],
  },
  rfmAnalysisFiltersButtonSuccess: false,
  rfmAnalysisFiltersButtonLoading: false,
  isCategoryListLoading: false,
  categoryListSuccess: false,
  categoryListData: [],
  totalElements: 0,
}

const reducer = (
  state: CustomerHighlightsState = initialState,
  action: CustomerHighlightsActionTypes,
): CustomerHighlightsState => {
  switch (action.type) {
    case LOAD_CUSTOMER_HIGHLIGHTS:
      return { ...state, isLoading: true, highlightsData: [] }
    case LOAD_CUSTOMER_HIGHLIGHTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        highlightSuccess: true,
        highlightsData: action.payload,
      }
    case LOAD_CUSTOMER_HIGHLIGHTS_ERROR:
      return {
        ...state,
        isLoading: false,
        highlightSuccess: false,
      }

    case LOAD_CUSTOMER_OVERVIEW_GRAPH:
      return { ...state, isChartLoading: true }
    case LOAD_CUSTOMER_OVERVIEW_GRAPH_SUCCESS:
      return {
        ...state,
        isChartLoading: false,
        overviewGraphSuccess: true,
        overviewGraphData: action.payload,
      }
    case LOAD_CUSTOMER_OVERVIEW_GRAPH_ERROR:
      return {
        ...state,
        isChartLoading: false,
        overviewGraphSuccess: false,
      }

    case LOAD_CUSTOMER_RFM_ANALYSIS:
      return { ...state, isRfmLoading: true, rfmAnalysisData: [] }
    case LOAD_CUSTOMER_RFM_ANALYSIS_SUCCESS:
      return {
        ...state,
        isRfmLoading: false,
        rfmAnalysisSuccess: true,
        rfmAnalysisData: action.payload,
      }
    case LOAD_CUSTOMER_RFM_ANALYSIS_ERROR:
      return {
        ...state,
        isRfmLoading: false,
        rfmAnalysisSuccess: false,
      }

    case LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS:
      return {
        ...state,
        rfmAnalysisFiltersLoading: true,
        rfmAnalysisFiltersData: initialState.rfmAnalysisFiltersData,
      }
    case LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_SUCCESS:
      return {
        ...state,
        rfmAnalysisFiltersLoading: false,
        rfmAnalysisFiltersSuccess: true,
        rfmAnalysisFiltersData: action.payload,
      }
    case LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_ERROR:
      return {
        ...state,
        rfmAnalysisFiltersLoading: false,
        rfmAnalysisFiltersSuccess: false,
      }

    case LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON:
      return {
        ...state,
        rfmAnalysisFiltersButtonLoading: true,
        rfmAnalysisData: initialState.rfmAnalysisData,
      }
    case LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_SUCCESS:
      return {
        ...state,
        rfmAnalysisFiltersButtonLoading: false,
        rfmAnalysisFiltersButtonSuccess: true,
        rfmAnalysisData: action.payload,
      }
    case LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_ERROR:
      return {
        ...state,
        rfmAnalysisFiltersButtonLoading: false,
        rfmAnalysisFiltersButtonSuccess: false,
      }

    case LOAD_CATEGORY_LIST:
      return {
        ...state,
        isCategoryListLoading: true,
        categoryListSuccess: false,
        categoryListData: [],
        totalElements: 0,
      }
    case LOAD_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isCategoryListLoading: false,
        categoryListSuccess: true,
        categoryListData: action.payload.categoryList,
        totalElements: action.payload.totalElements,
      }
    case LOAD_CATEGORY_LIST_ERROR:
      return {
        ...state,
        isCategoryListLoading: false,
        categoryListSuccess: false,
      }

    case CUSTOMER_HIGHLIGHTS_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
