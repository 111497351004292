import React, { FC } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Loadable from "utils/Loadable";
import NotFound from "components/NotFound";
import PrivateRoute from "./PrivateRoute";
import IndexRoute from "./IndexRoute";

const SignUpPage = Loadable(() => import("containers/SignUp"));
const SignUpVerifyPage = Loadable(() => import("containers/SignUpVerify"));
const SignInPage = Loadable(() => import("containers/SignIn"));
const SignInVerifyPage = Loadable(() => import("containers/SignInVerify"));
const ProfilePage = Loadable(() => import("containers/Profile"));
const DashboardPage = Loadable(() => import("containers/Dashboard"));
const MarketingSourcesPage = Loadable(
  () => import("containers/Marketing/Sources")
);
const MarketingAdvertisingPage = Loadable(
  () => import("containers/Marketing/Advertising")
);
const EmailMarketingPage = Loadable(
  () => import("containers/Marketing/Emails")
);
const AbandonedCartsMarketingPage = Loadable(
  () => import("containers/Marketing/AbandonedCarts")
);
const StorePage = Loadable(() => import("containers/Stores"));
const forgotPasswordPage = Loadable(() => import("containers/ForgotPassword"));
const ForgotPasswordConfirmPage = Loadable(
  () => import("containers/ForgotPasswordConfirm")
);
const changePasswordPage = Loadable(() => import("containers/ChangePassword"));
const CustomerOverviewPage = Loadable(
  () => import("containers/Customers/Overview")
);
const CustomerDBPage = Loadable(
  () => import("containers/Customers/CustomersDB")
);
const CustomerSegmentsPage = Loadable(
  () => import("containers/Customers/CustomerSegments")
);
const CustomerLTVPage = Loadable(() => import("containers/Customers/LTV"));
const ordersDBPage = Loadable(() => import("containers/Orders/DB"));
const ordersShippingPage = Loadable(() => import("containers/Orders/Shipping"));
const ordersDiscountPage = Loadable(
  () => import("containers/Orders/Discounts")
);
const productDBPage = Loadable(() => import("containers/Product/ProductList"));
const productSegmentsPage = Loadable(
  () => import("containers/Product/Segments")
);
const productInventoryPage = Loadable(
  () => import("containers/Product/Inventory")
);
const productCategoriesPage = Loadable(
  () => import("containers/Product/ProductCategories")
);
const productOverviewPage = Loadable(
  () => import("containers/Product/Overview")
);
const TeamMembersPage = Loadable(() => import("containers/TeamMembers/index"));

const ChannelPage = Loadable(() => import("containers/Channel"));

const SelectStore = Loadable(() => import("containers/SelectStore"));
const AddStore = Loadable(() => import("containers/AddStore"));

const Routes: FC = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/select-store" />} />
        <IndexRoute path="/signup" component={SignUpPage} />
        <IndexRoute path="/signup-verify" component={SignUpVerifyPage} />
        <IndexRoute path="/signin" component={SignInPage} />
        <IndexRoute path="/signin-verify" component={SignInVerifyPage} />
        <IndexRoute path="/forgot-password" component={forgotPasswordPage} />
        <IndexRoute
          path="/forgot-password-confirm"
          component={ForgotPasswordConfirmPage}
        />
        <PrivateRoute path="/change-password" component={changePasswordPage} />
        <PrivateRoute path="/profile" component={ProfilePage} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />

        <PrivateRoute path="/channels" component={ChannelPage} />

        <PrivateRoute
          path="/marketing-sources"
          component={MarketingSourcesPage}
        />
        <PrivateRoute
          path="/marketing-advertising"
          component={MarketingAdvertisingPage}
        />
        <PrivateRoute path="/marketing-emails" component={EmailMarketingPage} />
        <PrivateRoute
          path="/marketing-abandoned-carts"
          component={AbandonedCartsMarketingPage}
        />
        <PrivateRoute
          path="/customers-overview"
          component={CustomerOverviewPage}
        />
        <PrivateRoute path="/customers-db" component={CustomerDBPage} />
        <PrivateRoute
          path="/customers-overview"
          component={CustomerOverviewPage}
        />
        <PrivateRoute
          path="/customers-segments"
          component={CustomerSegmentsPage}
        />
        <PrivateRoute
          path="/customers-purchase-behavior"
          component={CustomerLTVPage}
        />
        <PrivateRoute path="/orders-db" component={ordersDBPage} />
        <PrivateRoute path="/orders-shipping" component={ordersShippingPage} />
        <PrivateRoute path="/orders-discounts" component={ordersDiscountPage} />
        <PrivateRoute path="/products-db" component={productDBPage} />
        <PrivateRoute
          path="/products-segments"
          component={productSegmentsPage}
        />
        <PrivateRoute
          path="/products-inventory"
          component={productInventoryPage}
        />
        <PrivateRoute
          path="/products-categories"
          component={productCategoriesPage}
        />
        <PrivateRoute
          path="/products-overview"
          component={productOverviewPage}
        />
        <PrivateRoute path="/settings-store" component={StorePage} />
        <PrivateRoute path="/add-store" component={AddStore} />
        <PrivateRoute path="/select-store" component={SelectStore} />
        <PrivateRoute
          path="/settings-team-members"
          component={TeamMembersPage}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
