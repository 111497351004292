import { InventoryListActionTypes } from "./types/actions";
import { InventoryListState } from "./types/reducer";
import {
  LOAD_INVENTORY_LIST,
  LOAD_INVENTORY_LIST_SUCCESS,
  LOAD_INVENTORY_LIST_ERROR,
  LOAD_INVENTORY_SEARCH_OPTIONS,
  LOAD_INVENTORY_SEARCH_OPTIONS_ERROR,
  LOAD_INVENTORY_SEARCH_OPTIONS_SUCCESS,
  LOAD_PRODUCT_STATUS_OPTIONS,
  LOAD_PRODUCT_STATUS_OPTIONS_ERROR,
  LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS,
  INVENTORY_LIST_CLEAN_UP,
  LOAD_INVENTORY_PRODUCTS_FOR_EXPORT_SUCCESS,
  LOAD_INVENTORY_PRODUCTS_FOR_EXPORT,
  LOAD_INVENTORY_PRODUCTS_FOR_EXPORT_ERROR,
} from './constants'

export const initialState: InventoryListState = {
  isLoading: false,
  inventoryOptions: [],
  inventories: [],
  success: false,
  listLoading: false,
  inventoryListSuccess: false,
  inventoryList: [],
  totalElements: 0,
  totalRow: {
    totalQtyAvailable: "",
    totalDepletion: "",
    totalSellRate: "",
    totalQtySold: "",
    totalRevenue: "",
    totalAveragePrice: "",
  },
  productSegmentOptions: [],
  searchBySegmentOptions: [],
  prodSegmentOptionsSuccess: false,
  exportInventoryProduct: [],
  exportListLoading: false,
  exportTotalRows: undefined,
}

const reducer = (
  state: InventoryListState = initialState,
  action: InventoryListActionTypes
): InventoryListState => {
  switch (action.type) {
    case LOAD_INVENTORY_SEARCH_OPTIONS:
      return {
        ...state,
        isLoading: true,
        inventoryOptions: initialState.inventoryOptions,
        inventories: initialState.inventories,
        success: false,
      };
    case LOAD_INVENTORY_SEARCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        inventoryOptions: action.payload.inventoryOptions,
        inventories: action.payload.inventories,
      };
    case LOAD_INVENTORY_SEARCH_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS:
      return {
        ...state,
        isLoading: true,
        searchBySegmentOptions: initialState.searchBySegmentOptions,
        productSegmentOptions: initialState.productSegmentOptions,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        prodSegmentOptionsSuccess: true,
        searchBySegmentOptions: action.payload.searchByOptions,
        productSegmentOptions: action.payload.productSegmentOptions,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        prodSegmentOptionsSuccess: false,
      };
    case LOAD_INVENTORY_LIST:
      return {
        ...state,
        listLoading: true,
        inventoryList: initialState.inventoryList,
        totalElements: initialState.totalElements,
        totalRow: initialState.totalRow,
      };
    case LOAD_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        inventoryListSuccess: true,
        inventoryList: action.payload.inventoryList,
        totalElements: action.payload.totalElements || 0,
        totalRow: action.payload.totalRow,
      };
    case LOAD_INVENTORY_LIST_ERROR:
      return {
        ...state,
        listLoading: false,
        inventoryListSuccess: false,
      };

    case INVENTORY_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    case LOAD_INVENTORY_PRODUCTS_FOR_EXPORT:
      return {
        ...state,
        exportListLoading: true,
      }
    case LOAD_INVENTORY_PRODUCTS_FOR_EXPORT_SUCCESS:
      return {
        ...state,
        exportInventoryProduct: action.payload.list,
        exportTotalRows: action.payload.totalRowValues,
        exportListLoading: false,
        isLoading: false,
        listLoading: false,
      }
    case LOAD_INVENTORY_PRODUCTS_FOR_EXPORT_ERROR:
      return {
        ...state,
        exportListLoading: false,
        isLoading: false,
      }

    default:
      return state;
  }
};

export default reducer;
