export const LOAD_ADD_FILTER_OPTIONS =
  'APP/PRODUCT/TOOLBAR/LOAD_ADD_FILTER_OPTIONS'
export const LOAD_ADD_FILTER_OPTIONS_SUCCESS =
  'APP/PRODUCT/TOOLBAR/LOAD_ADD_FILTER_OPTIONS_SUCCESS'
export const LOAD_ADD_FILTER_OPTIONS_ERROR =
  'APP/PRODUCT/TOOLBAR/LOAD_ADD_FILTER_OPTIONS_ERROR'

export const LOAD_FILTER_OPERATOR_OPTIONS =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_OPERATOR_OPTIONS'
export const LOAD_FILTER_OPERATOR_OPTIONS_SUCCESS =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_OPERATOR_OPTIONS_SUCCESS'
export const LOAD_FILTER_OPERATOR_OPTIONS_ERROR =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_OPERATOR_OPTIONS_ERROR'

export const ADD_FILTER_SEGMENT_CLEAN_UP =
  'APP/PRODUCT/TOOLBAR/ADD_FILTER_SEGMENT/CLEAN_UP'

export const UPDATE_FILTER_VALUES =
  'APP/PRODUCT/TOOLBAR/UPDATE_FILTER_VALUES'
export const UPDATE_FILTER_VALUES_SUCCESS =
  'APP/PRODUCT/TOOLBAR/UPDATE_FILTER_VALUES_SUCCESS'
export const UPDATE_FILTER_VALUES_ERROR =
  'APP/PRODUCT/TOOLBAR/UPDATE_FILTER_VALUES_ERROR'

export const REMOVE_FILTER = 'APP/PRODUCT/TOOLBAR/REMOVE_FILTER'
export const REMOVE_FILTER_SUCCESS =
  'APP/PRODUCT/TOOLBAR/REMOVE_FILTER_SUCCESS'
export const REMOVE_FILTER_ERROR =
  'APP/PRODUCT/TOOLBAR/REMOVE_FILTER_ERROR'

export const SAVE_FILTER = 'APP/PRODUCT/TOOLBAR/SAVE_FILTER'
export const SAVE_FILTER_SUCCESS =
  'APP/PRODUCT/TOOLBAR/SAVE_FILTER_SUCCESS'
export const SAVE_FILTER_ERROR =
  'APP/PRODUCT/TOOLBAR/SAVE_FILTER_ERROR'

export const LOAD_FILTER_CATEGORIES =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_CATEGORIES'
export const LOAD_FILTER_CATEGORIES_SUCCESS =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_CATEGORIES_SUCCESS'
export const LOAD_FILTER_CATEGORIES_ERROR =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_CATEGORIES_ERROR'

export const LOAD_FILTER_PRODUCTS =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_PRODUCTS'
export const LOAD_FILTER_PRODUCTS_SUCCESS =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_PRODUCTS_SUCCESS'
export const LOAD_FILTER_PRODUCTS_ERROR =
  'APP/PRODUCT/TOOLBAR/LOAD_FILTER_PRODUCTS_ERROR'
export const LOAD_CAT_HEIRCHY = 'APP/PRODUCT/TOOLBAR/LOAD_CAT_HEIRCHY'
export const LOAD_CAT_HEIRCHY_SUCCESS =
  'APP/PRODUCT/TOOLBAR/LOAD_CAT_HEIRCHY_SUCCESS'
export const LOAD_CAT_HEIRCHY_ERROR =
  'APP/PRODUCT/TOOLBAR/LOAD_CAT_HEIRCHY_ERROR'

export const LOAD_LOCATION_HEIRCHY =
  'APP/PRODUCT/TOOLBAR/LOAD_LOCATION_HEIRCHY'
export const LOAD_LOCATION_HEIRCHY_SUCCESS =
  'APP/PRODUCT/TOOLBAR/LOAD_LOCATION_HEIRCHY_SUCCESS'
export const LOAD_LOCATION_HEIRCHY_ERROR =
  'APP/PRODUCT/TOOLBAR/LOAD_LOCATION_HEIRCHY_ERROR'
