export const LOAD_SUBSCRIBERS_LIST =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/LOAD_SUBSCRIBERS_LIST'
export const LOAD_SUBSCRIBERS_LIST_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/LOAD_SUBSCRIBERS_LIST_SUCCESS'
export const LOAD_SUBSCRIBERS_LIST_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/LOAD_SUBSCRIBERS_LIST_ERROR'

export const SYNDICATION_CLEAN_UP =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/CLEAN_UP'

export const NEW_SYNDICATION =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/NEW'
export const NEW_SYNDICATION_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/NEW_SUCCESS'
export const NEW_SYNDICATION_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/NEW_ERROR'

export const LOAD_SYNDICATION_LIST =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/LOAD_LIST'
export const LOAD_SYNDICATION_LIST_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/LOAD_LIST_SUCCESS'
export const LOAD_SYNDICATION_LIST_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/LOAD_LIST_ERROR'

export const SYNDICATION_TARGET =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/TARGET'
export const SYNDICATION_TARGET_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/TARGET_SUCCESS'
export const SYNDICATION_TARGET_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/TARGET_ERROR'

export const SYNDICATION_CONSENT =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/CONSENT'
export const SYNDICATION_CONSENT_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/CONSENT_SUCCESS'
export const SYNDICATION_CONSENT_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/CONSENT_ERROR'

export const DELETE_SYNDICATION =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/DELETE'
export const DELETE_SYNDICATION_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/DELETE_SUCCESS'
export const DELETE_SYNDICATION_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/DELETE_ERROR'

export const AUTO_UPDATE_SYNDICATION =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/AUTO_UPDATE'
export const AUTO_UPDATE_SYNDICATION_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/AUTO_UPDATE_SUCCESS'
export const AUTO_UPDATE_SYNDICATION_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/AUTO_UPDATE_ERROR'

export const GET_SYNDICATION_DETAIL =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/GET_SYNDICATION'
export const GET_SYNDICATION_DETAIL_SUCCESS =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/GET_SYNDICATION_SUCCESS'
export const GET_SYNDICATION_DETAIL_ERROR =
  'APP/CUSTOMERS/CREATE_SEGMENT/SYNDICATION/GET_SYNDICATION_ERROR'
