export const LOAD_ORDERS_DB = "APP/ORDERS/DB/LOAD_ORDERS_DB";
export const LOAD_ORDERS_DB_SUCCESS = "APP/ORDERS/DB/LOAD_ORDERS_DB_SUCCESS";
export const LOAD_ORDERS_DB_ERROR = "APP/ORDERS/DB/LOAD_ORDERS_DB_ERROR";

export const LOAD_ORDERS_DB_FOR_EXPORT = 'APP/ORDERS/DB/LOAD_ORDERS_DB_FOR_EXPORT'
export const LOAD_ORDERS_DB_FOR_EXPORT_SUCCESS =
  'APP/ORDERS/DB/LOAD_ORDERS_DB_FOR_EXPORT_SUCCESS'
export const LOAD_ORDERS_DB_FOR_EXPORT_ERROR =
  'APP/ORDERS/DB/LOAD_ORDERS_DB_FOR_EXPORT_ERROR'

export const LOAD_ORDERS_OPTIONS = "APP/ORDERS/DB/LOAD_OPTIONS";
export const LOAD_ORDERS_OPTIONS_SUCCESS = "APP/ORDERS/DB/LOAD_OPTIONS_SUCCESS";
export const LOAD_ORDERS_OPTIONS_ERROR = "APP/ORDERS/DB/LOAD_OPTIONS_ERROR";

export const LOAD_ORDER_FILTER_OPTIONS = "APP/ORDERS/DB/LOAD_FILTER_OPTIONS";
export const LOAD_ORDER_FILTER_OPTIONS_SUCCESS =
  "APP/ORDERS/DB/LOAD_FILTER_OPTIONS_SUCCESS";
export const LOAD_ORDER_FILTER_OPTIONS_ERROR =
  "APP/ORDERS/DB/LOAD_FILTER_OPTIONS_ERROR";

export const LOAD_ORDER_FILTER_BY_BRANDS_INITIALS =
  "APP/ORDERS/DB/LOAD_FILTER_BY_BRAND_INITIALS";
export const LOAD_ORDER_FILTER_BY_BRAND_INITIALS_SUCCESS =
  "APP/ORDERS/DB/LOAD_FILTER_BY_BRAND_INITIALS_SUCCESS";
export const LOAD_ORDER_FILTER_BY_BRAND_INITIALS_ERROR =
  "APP/ORDERS/DB/LOAD_FILTER_BY_BRAND_INITIALS_ERROR";

export const LOAD_ORDER_FILTER_BY_BRANDS = "APP/ORDERS/DB/LOAD_FILTER_BY_BRAND";
export const LOAD_ORDER_FILTER_BY_BRAND_SUCCESS =
  "APP/ORDERS/DB/LOAD_FILTER_BY_BRAND_SUCCESS";
export const LOAD_ORDER_FILTER_BY_BRAND_ERROR =
  "APP/ORDERS/DB/LOAD_FILTER_BY_BRAND_ERROR";

export const LOAD_ORDER_FILTER_BY_STORES =
  "APP/ORDERS/DB/LOAD_FILTER_BY_STORES";
export const LOAD_ORDER_FILTER_BY_STORES_SUCCESS =
  "APP/ORDERS/DB/LOAD_FILTER_BY_STORES_SUCCESS";
export const LOAD_ORDER_FILTER_BY_STORES_ERROR =
  "APP/ORDERS/DB/LOAD_FILTER_BY_STORES_ERROR";

export const ORDERS_DB_CLEAN_UP = "APP/ORDERS/DB/CLEAN_UP";
