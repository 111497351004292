/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { SegmentFilterOption } from 'containers/Customers/CreateSegment/NewSegment/types/reducer'
import mantixApiInstance from '../interceptors/interceptor'

const PAGE = 'customer/'
const LOAD_ATTRIBUTE_DATA_URL =
  process.env.REACT_APP_LOAD_ATTRIBUTE_DATA

const LOAD_SEGMENT_OPERATORS_URL =
  process.env.REACT_APP_LOAD_SEGMENT_OPERATORS

const SAVE_NEW_SEGMENT_URL = process.env.REACT_APP_SAVE_NEW_SEGMENT

const VALIDATE_CUSTOMER_SEGMENT_URL =
  process.env.REACT_APP_VALIDATE_CUSTOMER_SEGMENT

const DELETE_SEGMENT_URL = process.env.REACT_APP_DELETE_SEGMENT
const PREVIEW_SEGMENT_URL = process.env.REACT_APP_PREVIEW_SEGMENT
const EDIT_SEGMENT_URL = process.env.REACT_APP_EDIT_SEGMENT
const LOAD_SCORE_PREDICTION =
  process.env.REACT_APP_LOAD_SCORE_PREDICTION
const DOWNLOAD_SEGMENT_URL =
  process.env.REACT_APP_DOWNLOAD_CUST_SEGMENT

export const loadSegmentAttributeData = (params: {
  tenantId: string
  attribute: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_ATTRIBUTE_DATA_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}
export const loadDownloadSegmentData = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  segmentName: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${DOWNLOAD_SEGMENT_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const previewSegment = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
  },
  segmentFilterOptions: SegmentFilterOption[],
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${PREVIEW_SEGMENT_URL}`,
    method: 'post',
    data: segmentFilterOptions,
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadSegmentAttributeOperators = (params: {
  tenantId: string
  storeId: string
  segmentName: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SEGMENT_OPERATORS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const validateCustomersSegment = (
  params: {
    tenantId: string
    storeId: string
    segmentName: string
    from: string
    to: string
    action: string
  },
  segmentFilterOptions: SegmentFilterOption[],
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${VALIDATE_CUSTOMER_SEGMENT_URL}`,
    method: 'post',
    data: segmentFilterOptions,
    params,
  }
  return mantixApiInstance.request(config)
}

export const saveNewSegment = (
  params: {
    tenantId: string
    userId: string
    storeId: string
    segmentName: string
    isFixedSegment: boolean
    to: string
    from: string
  },
  segmentFilterOptions: SegmentFilterOption[],
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${SAVE_NEW_SEGMENT_URL}`,
    method: 'post',
    data: segmentFilterOptions,
    params,
  }
  return mantixApiInstance.request(config)
}

export const deleteSegment = (params: {
  tenantId: string
  storeId: string
  segmentName: string
  role: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${DELETE_SEGMENT_URL}`,
    method: 'delete',
    params,
  }
  return mantixApiInstance.request(config)
}

export const editSegment = (params: {
  tenantId: string
  storeId: string
  role: string
  segmentName: string
  action: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${EDIT_SEGMENT_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadScorePrediction = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: LOAD_SCORE_PREDICTION,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}
