import appReducer from 'app/appReducer'
import errorReducer from 'containers/Errors/reducer'
import signUpReducer from 'containers/SignUp/reducer'
import signUpVerifyReducer from 'containers/SignUpVerify/reducer'
import signInReducer from 'containers/SignIn/reducer'
import signInVerifyReducer from 'containers/SignInVerify/reducer'
import signOutReducer from 'containers/Header/reducer'
import forgotPasswordReducer from 'containers/ForgotPassword/reducer'
import forgotPasswordConfirmReducer from 'containers/ForgotPasswordConfirm/reducer'
import changePasswordReducer from 'containers/ChangePassword/reducer'
import kiboReducer from 'containers/Stores/PlatformComponent/Ecommerce/Kibo/reducer'
import magentoReducer from 'containers/Stores/PlatformComponent/Ecommerce/Magento/reducer'
import wooCommerceReducer from 'containers/Stores/PlatformComponent/Ecommerce/Woocommerce/reducer'
import suiteCRMReducer from 'containers/Stores/PlatformComponent/CRM/SuiteCRM/reducer'
import mailchimpReducer from 'containers/Stores/PlatformComponent/Email/Mailchimp/reducer'
import campaignMonitorReducer from 'containers/Stores/PlatformComponent/Email/CampaignMonitor/reducer'
import googleAnalyticsReducer from 'containers/Stores/PlatformComponent/Analytics/GoogleAnalytics/reducer'
import integrateGoogleAdsReducer from 'containers/Stores/PlatformComponent/Analytics/GoogleAds/reducer'
import shopifyReducer from 'containers/Stores/PlatformComponent/Ecommerce/Shopify/reducer'
import zendeskReducer from 'containers/Stores/PlatformComponent/CustomerService/Zendesk/reducer'
import teamMembersReducer from 'containers/TeamMembers/reducer'
import addStoreReducer from 'containers/AddStore/reducer'
import selectStoreReducer from 'containers/SelectStore/reducer'
import sidebarListReducer from 'containers/SideBar/reducer'
import customerDBReducer from 'containers/Customers/DB/reducer'
import customerProfileReducer from 'containers/Customers/Profile/reducer'
import customerHighlightsReducer from 'containers/Customers/Overview/reducer'
import customerLTVReducer from 'containers/Customers/LTV/reducer'
import customerSegmentsReducer from 'containers/Customers/Segments/reducer'
import ordersDBReducer from 'containers/Orders/DB/reducer'
import newSegmentReducer from 'containers/Customers/CreateSegment/NewSegment/reducer'
import GlobalFiltersReducer from 'containers/GlobalFilters/reducer'
import shippingReducer from 'containers/Orders/Shipping/reducer'
import discountsReducer from 'containers/Orders/Discounts/reducer'
import productListReducer from 'containers/Product/DB/reducer'
import productOverviewReducer from 'containers/Product/Overview/reducer'
import productCategoriesReducer from 'containers/Product/Categories/reducer'
import productInventoryReducer from 'containers/Product/Inventory/reducer'
import productDetailsReducer from 'containers/Product/Details/reducer'
import productCategoryReducer from 'containers/Product/Category/reducer'
import productSegmentReducer from 'containers/Product/Segments/reducer'
import addFilterSegmentReducer from 'containers/Product/Toolbar/reducer'
import dashboardReducer from 'containers/Dashboard/reducer'
import dashboardAccordionReducer from 'containers/Dashboard/AccordionCard/reducer'
import syndicationReducer from 'containers/Customers/CreateSegment/Syndication/reducer'
import marketingSourcesReducer from 'containers/Marketing/Sources/reducer'
import marketingAdsReducer from 'containers/Marketing/Advertising/reducer'
import marketingEmailsReducer from 'containers/Marketing/Emails/reducer'
import profileReducer from 'containers/Profile/reducer'
import abandonedCartReducer from 'containers/Marketing/AbandonedCarts/reducer'
import { AnyAction, combineReducers } from 'redux'
import { USER_SIGN_OUT_SUCCESS } from 'containers/Header/constants'

export const reducers = combineReducers({
  userAuth: appReducer,
  error: errorReducer,
  signUp: signUpReducer,
  signUpVerify: signUpVerifyReducer,
  signIn: signInReducer,
  signInVerify: signInVerifyReducer,
  signOut: signOutReducer,
  forgotPassword: forgotPasswordReducer,
  forgotPasswordConfirm: forgotPasswordConfirmReducer,
  changePassword: changePasswordReducer,
  integrateKibo: kiboReducer,
  integrateMagento: magentoReducer,
  integrateWoocommerce: wooCommerceReducer,
  integrateSuiteCRM: suiteCRMReducer,
  integrateMailchimp: mailchimpReducer,
  integrateCampaignMonitor: campaignMonitorReducer,
  integrateGoogleAnalytics: googleAnalyticsReducer,
  integrateGoogleAds: integrateGoogleAdsReducer,
  integrateShopify: shopifyReducer,
  integrateZendesk: zendeskReducer,
  teamMembers: teamMembersReducer,
  addStore: addStoreReducer,
  selectStore: selectStoreReducer,
  sidebarList: sidebarListReducer,
  customerDB: customerDBReducer,
  customerProfile: customerProfileReducer,
  customerHighlights: customerHighlightsReducer,
  customerLTV: customerLTVReducer,
  customerSegments: customerSegmentsReducer,
  ordersDB: ordersDBReducer,
  newSegment: newSegmentReducer,
  GlobalFilters: GlobalFiltersReducer,
  shipping: shippingReducer,
  discounts: discountsReducer,
  productList: productListReducer,
  productOverview: productOverviewReducer,
  productCategories: productCategoriesReducer,
  productInventory: productInventoryReducer,
  productDetails: productDetailsReducer,
  productCategory: productCategoryReducer,
  productSegment: productSegmentReducer,
  addFilterSegment: addFilterSegmentReducer,
  dashboard: dashboardReducer,
  dashboardAccordion: dashboardAccordionReducer,
  syndication: syndicationReducer,
  marketingSources: marketingSourcesReducer,
  marketingAds: marketingAdsReducer,
  marketingEmails: marketingEmailsReducer,
  profiles: profileReducer,
  abandonedCart: abandonedCartReducer,
})

export type AppState = ReturnType<typeof reducers>

const rootReducer = (
  state: AppState | undefined,
  action: AnyAction,
) => {
  if (action.type === USER_SIGN_OUT_SUCCESS) state = undefined
  return reducers(state, action)
}

export default rootReducer
