import { AppActionTypes } from './types/actions'
import { appData, appLocation, AppState } from './types/reducer'
import {
  GET_USER_AUTH,
  GET_USER_AUTH_SUCCESS,
  GET_USER_AUTH_ERROR,
  SET_STORE_LOGO,
  SET_LOCATION_INFO,
  SET_LOGIN_DATA,
} from './appConstants'

const storeLogo = localStorage.getItem('storeLogo')
const rememberMe = localStorage.getItem('rememberMe')

export const initialState: AppState = {
  isLoggedIn: false,
  isAuthenticating: true,
  locationInfo: appLocation,
  error: '',
  data: appData,
  loginData: {
    role: rememberMe === 'true' ? localStorage.getItem('role') : sessionStorage.getItem('role'),
    storeLogo: rememberMe === 'true' ? localStorage.getItem('storeLogo') : sessionStorage.getItem('storeLogo'),
    storeId: rememberMe === 'true' ? localStorage.getItem('storeId') : sessionStorage.getItem('storeId'),
    tenantId: rememberMe === 'true' ? localStorage.getItem('tenantId') : sessionStorage.getItem('tenantId'),
    companyName: rememberMe === 'true' ? localStorage.getItem('companyName') : sessionStorage.getItem('companyName'),
    userId: rememberMe === 'true' ? localStorage.getItem('userId') : sessionStorage.getItem('userId'),
    username: rememberMe === 'true' ? localStorage.getItem('username') : sessionStorage.getItem('username'),
    authToken: rememberMe === 'true' ? localStorage.getItem('authToken') : sessionStorage.getItem('authToken'),
    disableMFA: rememberMe === 'true' ? localStorage.getItem('disableMFA') : sessionStorage.getItem('disableMFA'),
    email: rememberMe === 'true' ? localStorage.getItem('email') : sessionStorage.getItem('email')
  },
  storeLogo: storeLogo || '',
}

const appReducer = (
  state: AppState = initialState,
  action: AppActionTypes,
): AppState => {
  switch (action.type) {
    case GET_USER_AUTH:
      return { ...state, isAuthenticating: true, isLoggedIn: false }
    case GET_USER_AUTH_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        isLoggedIn: true,
        data: {
          ...state.data,
          userId: action.payload,
        },
      }
    case GET_USER_AUTH_ERROR:
      return {
        ...state,
        isAuthenticating: false,
        isLoggedIn: false,
        error: action.payload,
      }
    case SET_STORE_LOGO:
      return {
        ...state,
        storeLogo: action.payload,
      }
    case SET_LOCATION_INFO:
      return {
        ...state,
        locationInfo: action.payload,
      }
      case SET_LOGIN_DATA:
        return {
          ...state,
          loginData: {
            ...state.loginData,
            ...action.payload
          },
        }
    default:
      return state
  }
}

export default appReducer
