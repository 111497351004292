export const LOAD_MARKETING_ABANDONED_CARTS_KPI =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_KPI'
export const LOAD_MARKETING_ABANDONED_CARTS_KPI_SUCCESS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_KPI_SUCCESS'
export const LOAD_MARKETING_ABANDONED_CARTS_KPI_ERROR =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_KPI_ERROR'
export const LOAD_MARKETING_ABANDONED_CARTS_SALESKPI =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_SALESKPI'
export const LOAD_MARKETING_ABANDONED_CARTS_SALESKPI_SUCCESS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_SALESKPI_SUCCESS'
export const LOAD_MARKETING_ABANDONED_CARTS_SALESKPI_ERROR =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_SALESKPI_ERROR'
export const LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_DETAILS'
export const LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS_SUCCESS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_DETAILS_SUCCESS'
export const LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS_ERROR =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_DETAILS_ERROR'
export const LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_SALES_DETAILS'
export const LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS_SUCCESS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_SALES_DETAILS_SUCCESS'
export const LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS_ERROR =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_SALES_DETAILS_ERROR'
export const LOAD_MARK_ABANDONEDCART_CHART =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_ABANDONEDCART_CHART'
export const LOAD_MARK_ABANDONEDCART_CHART_SUCCESS =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_ABANDONEDCART_CHART_SUCCESS '
export const LOAD_MARK_ABANDONEDCART_CHART_ERROR =
  'APP/MARKETING/ABANDONEDCARTS/LOAD_ABANDONEDCART_CHART_ERROR'

export const MARKETING_ABANDONED_CARTS_CLEAN_UP =
  'APP/MARKETING/ABANDONEDCARTS/CLEAN_UP'
