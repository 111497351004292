import { TeamMembersActionTypes } from './types/actions'
import { TeamMembersState, User } from './types/reducer'
import {
  ADD_TEAM_MEMBER,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_ERROR,
  TEAM_MEMBERS_CLEAN_UP,
  LOAD_ALL_STORES_ROLES,
  LOAD_ALL_STORES_ROLES_SUCCESS,
  LOAD_ALL_STORES_ROLES_ERROR,
  LOAD_USERS_LIST,
  LOAD_USERS_LIST_SUCCESS,
  LOAD_USERS_LIST_ERROR,
  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  UPDATE_MEMBER,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_ERROR,
  GET_USER_ACTIVITY_TIMELINE_SUCCESS,
  GET_USER_ACTIVITY_TIMELINE,
  GET_USER_ACTIVITY_TIMELINE_ERROR,
} from './constants'

export const initialState: TeamMembersState = {
  isLoading: true,
  addTeamMemberSuccess: false,
  deleteUserSuccess: false,
  updateUserSuccess: false,
  stores: [],
  roles: [],
  storesRolesDataSuccess: false,
  usersList: [],
  usersListLoading: false,
  usersListSuccess: false,
  userTimelineLoading: false,
  userTimeline: []
}

const deleteUser = (prevState: User[], userId: string) => {
  const updatedList = prevState.filter(
    (user) => user.userId !== userId,
  )
  return updatedList
}

const updateUser = (prevState: User[], updatedUser: User) => {
  const updatedList = prevState.map((user) => {
    if (user.email === updatedUser.email)
      return {
        ...user,
        role: updatedUser.role,
        stores: updatedUser.stores,
      }
    return user
  })
  return updatedList
}

const reducer = (
  state: TeamMembersState = initialState,
  action: TeamMembersActionTypes,
): TeamMembersState => {
  switch (action.type) {
    case LOAD_ALL_STORES_ROLES:
      return {
        ...state,
        isLoading: true,
        stores: initialState.stores,
        roles: initialState.roles,
        usersList: initialState.usersList,
      }
    case LOAD_ALL_STORES_ROLES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        storesRolesDataSuccess: true,
        stores: action.payload.stores,
        roles: action.payload.roles,
        usersList: action.payload.usersList,
      }
    case LOAD_ALL_STORES_ROLES_ERROR:
      return {
        ...state,
        isLoading: false,
        storesRolesDataSuccess: false,
        stores: initialState.stores,
        roles: initialState.roles,
        usersList: initialState.usersList,
      }

    case LOAD_USERS_LIST:
      return {
        ...state,
        usersListLoading: true,
      }
    case LOAD_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersListLoading: false,
        usersList: action.payload,
      }

    case LOAD_USERS_LIST_ERROR:
      return {
        ...state,
        usersListLoading: false,
      }
    case ADD_TEAM_MEMBER:
      return {
        ...state,
        isLoading: true,
        storesRolesDataSuccess: false,
        addTeamMemberSuccess: false,
      }
    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addTeamMemberSuccess: true,
        deleteUserSuccess: false,
      }
    case ADD_TEAM_MEMBER_ERROR:
      return {
        ...state,
        isLoading: false,
        addTeamMemberSuccess: false,
      }

    case DELETE_MEMBER:
      return {
        ...state,
        isLoading: true,
      }

    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteUserSuccess: true,
        usersList: deleteUser([...state.usersList], action.payload),
      }
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        isLoading: false,
        deleteUserSuccess: false,
      }

    case UPDATE_MEMBER:
      return { ...state, isLoading: true }
    case UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        isLoading: false,
        usersList: updateUser([...state.usersList], action.payload),
      }
    case UPDATE_MEMBER_ERROR:
      return { ...state, updateUserSuccess: false, isLoading: false }

    case GET_USER_ACTIVITY_TIMELINE:
      return { ...state, userTimelineLoading: true }

    case GET_USER_ACTIVITY_TIMELINE_SUCCESS:
      return { ...state, userTimeline: action.payload, userTimelineLoading: false }

    case GET_USER_ACTIVITY_TIMELINE_ERROR:
      return { ...state, userTimelineLoading: false }

    case TEAM_MEMBERS_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
