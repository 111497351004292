export const LOAD_DASHBOARD_SUMMARY = 'APP/DASHBOARD/LOAD_SUMMARY'
export const LOAD_DASHBOARD_SUMMARY_SUCCESS =
  'APP/DASHBOARD/LOAD_SUMMARY_SUCCESS'
export const LOAD_DASHBOARD_SUMMARY_ERROR =
  'APP/DASHBOARD/LOAD_SUMMARY_ERROR'

export const LOAD_CUST_PURCHASE_DETAILS =
  'APP/DASHBOARD/LOAD_CUSTOMER_PURCHASE_DETAILS'
export const LOAD_CUST_PURCHASE_DETAILS_SUCCESS =
  'APP/DASHBOARD/LOAD_CUSTOMER_PURCHASE_DETAILS_SUCCESS'
export const LOAD_CUST_PURCHASE_DETAILS_ERROR =
  'APP/DASHBOARD/LOAD_CUSTOMER_PURCHASE_DETAILS_ERROR'

export const LOAD_DASHBOARD_KPI = 'APP/DASHBOARD/LOAD_KPI'
export const LOAD_DASHBOARD_KPI_SUCCESS =
  'APP/DASHBOARD/LOAD_KPI_SUCCESS'
export const LOAD_DASHBOARD_KPI_ERROR = 'APP/DASHBOARD/LOAD_KPI_ERROR'

export const LOAD_TOP_PERFORMANCE_PRODUCTS =
  'APP/DASHBOARD/LOAD_TOP_PERFORMANCE_PRODUCTS'
export const LOAD_TOP_PERFORMANCE_PRODUCTS_SUCCESS =
  'APP/DASHBOARD/LOAD_TOP_PERFORMANCE_PRODUCTS_SUCCESS'
export const LOAD_TOP_PERFORMANCE_PRODUCTS_ERROR =
  'APP/DASHBOARD/LOAD_TOP_PERFORMANCE_PRODUCTS_ERROR'

export const LOAD_WEB_TRAFFIC_DATA =
  'APP/DASHBOARD/LOAD_WEB_TRAFFIC_DATA'
export const LOAD_WEB_TRAFFIC_DATA_SUCCESS =
  'APP/DASHBOARD/LOAD_WEB_TRAFFIC_DATA_SUCCESS'
export const LOAD_WEB_TRAFFIC_DATA_ERROR =
  'APP/DASHBOARD/LOAD_WEB_TRAFFIC_DATA_ERROR'

export const LOAD_CONVERSION_RATE =
  'APP/DASHBOARD/LOAD_CONVERSION_RATE'
export const LOAD_CONVERSION_RATE_SUCCESS =
  'APP/DASHBOARD/LOAD_CONVERSION_RATE_SUCCESS'
export const LOAD_CONVERSION_RATE_ERROR =
  'APP/DASHBOARD/LOAD_CONVERSION_RATE_ERROR'

export const LOAD_PRODUCTS_HIERARCHY =
  'APP/DASHBOARD/LOAD_PRODUCTS_HIERARCHY'
export const LOAD_PRODUCTS_HIERARCHY_SUCCESS =
  'APP/DASHBOARD/LOAD_PRODUCTS_HIERARCHY_SUCCESS'
export const LOAD_PRODUCTS_HIERARCHY_ERROR =
  'APP/DASHBOARD/LOAD_PRODUCTS_HIERARCHY_ERROR'

export const DASHBOARD_CLEAN_UP = 'APP/DASHBOARD/CLEAN_UP'
