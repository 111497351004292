import { MagentoActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_MAGENTO,
  INTEGRATE_MAGENTO_SUCCESS,
  INTEGRATE_MAGENTO_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    magneto: {
      username: '',
      password: '',
      url: '',
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: MagentoActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_MAGENTO:
      return { ...state, isLoading: true }
    case INTEGRATE_MAGENTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_MAGENTO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
