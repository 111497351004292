/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import mantixApiInstance from '../interceptors/interceptor'

const ADD_STORE_URL = process.env.REACT_APP_ADD_STORE

const LOAD_STORES_BY_USER_URL =
  process.env.REACT_APP_LOAD_STORES_BY_USER

const LOAD_ALL_STORES_BY_TENANT =
  process.env.REACT_APP_LOAD_ALL_STORES_BY_TENANT

type StoreDetails = {
  businessUrl: string
  storeName: string
  platform: string
  timezone: string
  userId: string
  role: any
}

export const loadStoresByUser = (
  userId: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
  }

  const config: AxiosRequestConfig = {
    url: `/${LOAD_STORES_BY_USER_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const loadAllStores = (
  tenantId: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    tenantId,
  }

  const config: AxiosRequestConfig = {
    url: `/${LOAD_ALL_STORES_BY_TENANT}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const addStore = (
  data: StoreDetails,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: ADD_STORE_URL,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}
