import { DashboardAccordionActionTypes } from './types/actions'
import { DashboardAccordionState } from './types/reducer'
import {
  LOAD_DASHBOARD_GRAPH,
  LOAD_DASHBOARD_GRAPH_SUCCESS,
  LOAD_DASHBOARD_GRAPH_ERROR,
  LOAD_DASHBOARD_CHART_OPTIONS,
  LOAD_DASHBOARD_CHART_OPTIONS_SUCCESS,
  LOAD_DASHBOARD_CHART_OPTIONS_ERROR,
  DASHBOARD_ACC_CLEAN_UP,
} from './constants'

export const initialState: DashboardAccordionState = {
  isLoading: false,
  success: false,
  data: {
    previousPeriod: { data: [], totalCount: 0, percentage: 0 },
    currentPeriod: { data: [], totalCount: 0, percentage: 0 },
    dateLabels: [],
  },
  chartOptionsLoading: false,
  chartOptionsSuccess: false,
  chartTypeOptions: [],
}

const reducer = (
  state: DashboardAccordionState = initialState,
  action: DashboardAccordionActionTypes,
): DashboardAccordionState => {
  switch (action.type) {
    case LOAD_DASHBOARD_GRAPH:
      return {
        ...state,
        isLoading: true,
        data: initialState.data,
      }
    case LOAD_DASHBOARD_GRAPH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        data: action.payload,
      }
    case LOAD_DASHBOARD_GRAPH_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }

    case LOAD_DASHBOARD_CHART_OPTIONS:
      return {
        ...state,
        chartOptionsLoading: true,
        chartTypeOptions: initialState.chartTypeOptions,
      }
    case LOAD_DASHBOARD_CHART_OPTIONS_SUCCESS:
      return {
        ...state,
        chartOptionsLoading: false,
        chartOptionsSuccess: true,
        chartTypeOptions: action.payload,
      }
    case LOAD_DASHBOARD_CHART_OPTIONS_ERROR:
      return {
        ...state,
        chartOptionsLoading: false,
        chartOptionsSuccess: false,
      }

    case DASHBOARD_ACC_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
