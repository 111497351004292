export const LOAD_PRODUCT_LIST = "APP/PRODUCT/DB/LOAD_LIST";
export const LOAD_PRODUCT_LIST_SUCCESS = "APP/PRODUCT/DB/LOAD_LIST_SUCCESS";
export const LOAD_PRODUCT_LIST_ERROR = "APP/PRODUCT/DB/LOAD_LIST_ERROR";

export const LOAD_PRODUCT_STATUS_OPTIONS = "APP/PRODUCT/DB/LOAD_STATUS_OPTIONS";
export const LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS =
  "APP/PRODUCT/DB/LOAD_STATUS_OPTIONS_SUCCESS";
export const LOAD_PRODUCT_STATUS_OPTIONS_ERROR =
  'APP/PRODUCT/DB/LOAD_STATUS_OPTIONS_ERROR'
export const PRODUCT_LIST_CLEAN_UP = 'APP/PRODUCT/DB/LIST/CLEAN_UP'
export const LOAD_DB_PRODUCTS_FOR_EXPORT = 'APP/DB/PRODUCT/EXPORT'
export const LOAD_DB_PRODUCTS_FOR_EXPORT_SUCCESS =
  'APP/DB/PRODUCT/EXPORT_SUCCESS'
export const LOAD_DB_PRODUCTS_FOR_EXPORT_ERROR =
  'APP/DB/PRODUCT/EXPORT_ERROR'
