export const LOAD_CUSTOMER_OPTIONS = "APP/CUSTOMERS/DB/LOAD_OPTIONS";
export const LOAD_CUSTOMER_OPTIONS_SUCCESS =
  "APP/CUSTOMERS/DB/LOAD_OPTIONS_SUCCESS";
export const LOAD_CUSTOMER_OPTIONS_ERROR =
  "APP/CUSTOMERS/DB/LOAD_OPTIONS_ERROR";

export const SEARCH_DB = "APP/CUSTOMERS/DB/SEARCH";
export const SEARCH_DB_ERROR = "APP/CUSTOMERS/DB/SEARCH_ERROR";
export const SEARCH_DB_SUCCESS = "APP/CUSTOMERS/DB/SEARCH_SUCCESS";

export const LOAD_SEARCH_DB_FOR_EXPORT =
  'APP/CUSTOMERS/DB/SEARCH/EXPORT'
export const LOAD_SEARCH_DB_FOR_EXPORT_ERROR =
  'APP/CUSTOMERS/DB/SEARCH/EXPORT/ERROR'
export const LOAD_SEARCH_DB_FOR_EXPORT_SUCCESS =
  'APP/CUSTOMERS/DB/SEARCH/EXPORT/SUCCESS'

export const CUSTOMER_DB_CLEAN_UP = 'APP/CUSTOMERS/DB/CLEAN_UP'
