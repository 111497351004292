export const LOAD_DISCOUNTS_LIST = 'APP/ORDERS/DISCOUNTS/LOAD_LIST'
export const LOAD_DISCOUNTS_LIST_SUCCESS =
  'APP/ORDERS/DISCOUNTS/LOAD_LIST_SUCCESS'
export const LOAD_DISCOUNTS_LIST_ERROR =
  'APP/ORDERS/DISCOUNTS/LOAD_LIST_ERROR'

export const LOAD_DISCOUNTS_KPI = 'APP/ORDERS/DISCOUNTS/LOAD_KPI'
export const LOAD_DISCOUNTS_KPI_SUCCESS =
  'APP/ORDERS/DISCOUNTS/LOAD_KPI_SUCCESS'
export const LOAD_DISCOUNTS_KPI_ERROR =
  'APP/ORDERS/DISCOUNTS/LOAD_KPI_ERROR'

export const LOAD_DISCOUNTS_OPTIONS =
  'APP/ORDERS/DISCOUNTS/LOAD_OPTIONS'
export const LOAD_DISCOUNTS_OPTIONS_SUCCESS =
  'APP/ORDERS/DISCOUNTS/LOAD_OPTIONS_SUCCESS'
export const LOAD_DISCOUNTS_OPTIONS_ERROR =
  'APP/ORDERS/DISCOUNTS/LOAD_OPTIONS_ERROR'

export const LOAD_DISCOUNTS_GRAPH = 'APP/ORDERS/DISCOUNTS/LOAD_GRAPH'
export const LOAD_DISCOUNTS_GRAPH_SUCCESS =
  'APP/ORDERS/DISCOUNTS/LOAD_GRAPH_SUCCESS'
export const LOAD_DISCOUNTS_GRAPH_ERROR =
  'APP/ORDERS/DISCOUNTS/LOAD_GRAPH_ERROR'

export const DISCOUNTS_LIST_CLEAN_UP =
  'APP/ORDERS/DISCOUNTS/LIST/CLEAN_UP'
