import { MarketingAdActionTypes } from "./types/actions";
import { MarketingAdState } from "./types/reducer";
import {
  LOAD_MARK_AD_FILTERS,
  LOAD_MARK_AD_FILTERS_ERROR,
  LOAD_MARK_AD_FILTERS_SUCCESS,
  LOAD_MARK_COUNTRY_FILTERS,
  LOAD_MARK_COUNTRY_FILTERS_ERROR,
  LOAD_MARK_COUNTRY_FILTERS_SUCCESS,
  LOAD_MARKETING_AD_KPI,
  LOAD_MARKETING_AD_KPI_SUCCESS,
  LOAD_MARKETING_AD_KPI_ERROR,
  LOAD_MARKETING_AD_LIST,
  LOAD_MARKETING_AD_LIST_SUCCESS,
  LOAD_MARKETING_AD_LIST_ERROR,
  LOAD_MARK_CAMPAIGN_CHART,
  LOAD_MARK_CAMPAIGN_CHART_SUCCESS,
  LOAD_MARK_CAMPAIGN_CHART_ERROR,
  MARKETING_AD_CLEAN_UP,
} from "./constants";

export const initialState: MarketingAdState = {
  isLoading: false,
  filters: [],
  filtersLoading: false,
  filtersSuccess: false,
  countryFilters: [],
  countryFiltersLoading: false,
  countryFiltersSuccess: false,
  kpiSuccess: false,
  kpiData: {
    clicks: "",
    conversions: "",
    roas: "",
    costPerClick: "",
    clickThroughRate: "",
  },
  listLoading: false,
  marketingAdListSuccess: false,
  marketingAdList: [],
  totalElements: 0,
  campaignChartData: {
    chartData: [],
    currentSpend: [],
    optimizedSpend: [],
  },
  campaignChartSuccess: false,
  campaignChartLoading: false,
};

const reducer = (
  state: MarketingAdState = initialState,
  action: MarketingAdActionTypes
): MarketingAdState => {
  switch (action.type) {
    case LOAD_MARK_AD_FILTERS:
      return {
        ...state,
        filtersLoading: true,
        filters: initialState.filters,
      };
    case LOAD_MARK_AD_FILTERS_SUCCESS:
      return {
        ...state,
        filtersLoading: false,
        filtersSuccess: true,
        filters: action.payload,
      };
    case LOAD_MARK_AD_FILTERS_ERROR:
      return {
        ...state,
        filtersLoading: false,
        filtersSuccess: false,
      };
    case LOAD_MARK_COUNTRY_FILTERS:
      return {
        ...state,
        countryFiltersLoading: true,
        countryFilters: initialState.countryFilters,
      };
    case LOAD_MARK_COUNTRY_FILTERS_SUCCESS:
      return {
        ...state,
        countryFiltersLoading: false,
        countryFiltersSuccess: true,
        countryFilters: action.payload,
      };
    case LOAD_MARK_COUNTRY_FILTERS_ERROR:
      return {
        ...state,
        countryFiltersLoading: false,
        countryFiltersSuccess: false,
      };
    case LOAD_MARKETING_AD_KPI:
      return {
        ...state,
        isLoading: true,
        kpiData: initialState.kpiData,
      };
    case LOAD_MARKETING_AD_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: true,
        kpiData: action.payload,
      };
    case LOAD_MARKETING_AD_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: false,
      };
    case LOAD_MARKETING_AD_LIST:
      return {
        ...state,
        listLoading: true,
        marketingAdList: initialState.marketingAdList,
        totalElements: initialState.totalElements,
      };
    case LOAD_MARKETING_AD_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        marketingAdListSuccess: true,
        marketingAdList: action.payload.marketingAdList,
        totalElements: action.payload.totalElements || 0,
      };
    case LOAD_MARKETING_AD_LIST_ERROR:
      return {
        ...state,
        listLoading: false,
        marketingAdListSuccess: false,
      };
    case LOAD_MARK_CAMPAIGN_CHART:
      return {
        ...state,
        campaignChartLoading: true,
        campaignChartData: initialState.campaignChartData,
      };
    case LOAD_MARK_CAMPAIGN_CHART_SUCCESS:
      return {
        ...state,
        campaignChartLoading: false,
        campaignChartSuccess: true,
        campaignChartData: action.payload,
      };
    case LOAD_MARK_CAMPAIGN_CHART_ERROR:
      return {
        ...state,
        campaignChartLoading: false,
        campaignChartSuccess: false,
      };

    case MARKETING_AD_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
