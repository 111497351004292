import { GlobalFiltersActionTypes } from './types/actions'
import { GlobalFiltersState } from './types/reducer'
import {
  LOAD_GLOBAL_FILTERS_LIST,
  LOAD_GLOBAL_FILTERS_LIST_SUCCESS,
  LOAD_GLOBAL_FILTERS_LIST_ERROR,
  SAVE_FILTERS_STATE,
  SAVE_FILTERS_STATE_ERROR,
  SAVE_FILTERS_STATE_SUCCESS,
  LOAD_PRODUCTS_FILTERS,
  LOAD_PRODUCTS_FILTERS_SUCCESS,
  LOAD_PRODUCTS_FILTERS_ERROR,
  FILTERS_LIST_CLEAN_UP,
} from './constants'

export const initialState: GlobalFiltersState = {
  otherFiltersLoading: false,
  otherFiltersSuccess: false,
  otherFilters: [],
  filterState: [],
  saveFiltersSuccess: false,
  proFilterSuccess: false,
  proFilterLoading: false,
  productsFilters: [],
}

const reducer = (
  state: GlobalFiltersState = initialState,
  action: GlobalFiltersActionTypes,
): GlobalFiltersState => {
  switch (action.type) {
    case LOAD_GLOBAL_FILTERS_LIST:
      return {
        ...state,
        otherFiltersLoading: true,
        otherFilters: initialState.otherFilters,
      }
    case LOAD_GLOBAL_FILTERS_LIST_SUCCESS:
      return {
        ...state,
        otherFiltersLoading: false,
        otherFiltersSuccess: true,
        otherFilters: action.payload,
      }
    case LOAD_GLOBAL_FILTERS_LIST_ERROR:
      return {
        ...state,
        otherFiltersLoading: false,
        otherFiltersSuccess: false,
      }

    case LOAD_PRODUCTS_FILTERS:
      return {
        ...state,
        proFilterLoading: true,
        proFilterSuccess: false,
        productsFilters: initialState.productsFilters,
      }
    case LOAD_PRODUCTS_FILTERS_SUCCESS:
      return {
        ...state,
        proFilterLoading: false,
        proFilterSuccess: true,
        productsFilters: action.payload,
      }
    case LOAD_PRODUCTS_FILTERS_ERROR:
      return {
        ...state,
        proFilterLoading: false,
        proFilterSuccess: false,
      }

    case SAVE_FILTERS_STATE:
      return { ...state, filterState: action.payload }
    case SAVE_FILTERS_STATE_SUCCESS:
      return {
        ...state,
        saveFiltersSuccess: true,
      }
    case SAVE_FILTERS_STATE_ERROR:
      return {
        ...state,
        saveFiltersSuccess: false,
      }

    case FILTERS_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
