import { ShopifyActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_SHOPIFY,
  INTEGRATE_SHOPIFY_SUCCESS,
  INTEGRATE_SHOPIFY_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    shopify: {
      apiKey: '',
      password: '',
      store: '',
      title: 'shopify',
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: ShopifyActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_SHOPIFY:
      return { ...state, isLoading: true }
    case INTEGRATE_SHOPIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_SHOPIFY_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
