import { GoogleAdsActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_GOOGLE_ADS,
  INTEGRATE_GOOGLE_ADS_SUCCESS,
  INTEGRATE_GOOGLE_ADS_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    googleAds: {
      clientCustomerId: '',
      developerToken: '',
      clientId: '',
      clientSecret: '',
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: GoogleAdsActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_GOOGLE_ADS:
      return { ...state, isLoading: true }
    case INTEGRATE_GOOGLE_ADS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_GOOGLE_ADS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
