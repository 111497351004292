import { AddStoreActionTypes } from './types/actions'
import { AddStoreState } from './types/reducer'
import {
  ADD_STORE,
  ADD_STORE_SUCCESS,
  ADD_STORE_ERROR,
  ADD_STORE_CLEAN_UP,
} from './constants'

export const initialState: AddStoreState = {
  isLoading: false,
  success: false,
}

const reducer = (
  state: AddStoreState = initialState,
  action: AddStoreActionTypes,
): AddStoreState => {
  switch (action.type) {
    case ADD_STORE:
      return { ...state, isLoading: true }
    case ADD_STORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      }
    case ADD_STORE_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case ADD_STORE_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
