export const LOAD_TIMELINE_DATA = "APP/CUSTOMERS/PROFILE/LOAD_TIMELINE_DATA";
export const LOAD_TIMELINE_DATA_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_TIMELINE_DATA_SUCCESS";
export const LOAD_TIMELINE_DATA_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_TIMELINE_DATA_ERROR";

export const LOAD_TIMELINE_KPI_DATA =
  'APP/CUSTOMERS/PROFILE/LOAD_TIMELINE_KPI_DATA'
export const LOAD_TIMELINE_KPI_DATA_SUCCESS =
  'APP/CUSTOMERS/PROFILE/LOAD_TIMELINE_KPI_DATA_SUCCESS'
export const LOAD_TIMELINE_KPI_DATA_ERROR =
  'APP/CUSTOMERS/PROFILE/LOAD_TIMELINE_KPI_DATA_ERROR'

export const LOAD_CUSTOMER_PROFILE =
  'APP/CUSTOMERS/PROFILE/LOAD_PROFILE'
export const LOAD_CUSTOMER_PROFILE_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_PROFILE_SUCCESS";
export const LOAD_CUSTOMER_PROFILE_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_PROFILE_ERROR";

export const LOAD_PROFILE_SUMMARY = "APP/CUSTOMERS/PROFILE/LOAD_SUMMARY";
export const LOAD_PROFILE_SUMMARY_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_SUMMARY_SUCCESS";
export const LOAD_PROFILE_SUMMARY_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_SUMMARY_ERROR";

export const LOAD_CATEGORY_PURCHASES =
  "APP/CUSTOMERS/PROFILE/LOAD_CATEGORY_PURCHASES";
export const LOAD_CATEGORY_PURCHASES_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_CATEGORY_PURCHASES_SUCCESS";
export const LOAD_CATEGORY_PURCHASES_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_CATEGORY_PURCHASES_ERROR";

export const LOAD_INTEREST_DATA = "APP/CUSTOMERS/PROFILE/LOAD_INTEREST_DATA";
export const LOAD_INTEREST_DATA_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_INTEREST_DATA_SUCCESS";
export const LOAD_INTEREST_DATA_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_INTEREST_DATA_ERROR";

export const LOAD_BRAND_AFFINITIES_DATA =
  "APP/CUSTOMERS/PROFILE/LOAD_BRAND_AFFINITIES_DATA";
export const LOAD_BRAND_AFFINITIES_DATA_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_BRAND_AFFINITIES_DATA_SUCCESS";
export const LOAD_BRAND_AFFINITIES_DATA_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_BRAND_AFFINITIES_DATA_ERROR";

export const LOAD_MATCHING_SEGMENTS =
  "APP/CUSTOMERS/PROFILE/LOAD_MATCHING_SEGMENTS";
export const LOAD_MATCHING_SEGMENTS_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_MATCHING_SEGMENTS_ERROR";
export const LOAD_MATCHING_SEGMENTS_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_MATCHING_SEGMENTS_SUCCESS";

export const LOAD_CUSTOMER_SUGGESTED_PRODUCTS =
  "APP/CUSTOMERS/PROFILE/LOAD_SUGGESTED_PRODUCTS";
export const LOAD_CUSTOMER_SUGGESTED_PRODUCTS_SUCCESS =
  "APP/CUSTOMERS/PROFILE/LOAD_SUGGESTED_PRODUCTS_SUCCESS";
export const LOAD_CUSTOMER_SUGGESTED_PRODUCTS_ERROR =
  "APP/CUSTOMERS/PROFILE/LOAD_SUGGESTED_PRODUCTS_ERROR";

export const CUSTOMER_PROFILE_CLEAN_UP = "APP/CUSTOMERS/PROFILE/CLEAN_UP";
