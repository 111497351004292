import { AddFilterSegmentActionTypes } from './types/actions'
import { AddFilterSegmentState, FilterData } from './types/reducer'
import {
  LOAD_ADD_FILTER_OPTIONS,
  LOAD_ADD_FILTER_OPTIONS_SUCCESS,
  LOAD_ADD_FILTER_OPTIONS_ERROR,
  LOAD_FILTER_OPERATOR_OPTIONS,
  LOAD_FILTER_OPERATOR_OPTIONS_ERROR,
  LOAD_FILTER_OPERATOR_OPTIONS_SUCCESS,
  ADD_FILTER_SEGMENT_CLEAN_UP,
  UPDATE_FILTER_VALUES,
  UPDATE_FILTER_VALUES_SUCCESS,
  UPDATE_FILTER_VALUES_ERROR,
  REMOVE_FILTER,
  REMOVE_FILTER_ERROR,
  REMOVE_FILTER_SUCCESS,
  SAVE_FILTER,
  SAVE_FILTER_ERROR,
  SAVE_FILTER_SUCCESS,
  LOAD_FILTER_CATEGORIES,
  LOAD_FILTER_CATEGORIES_ERROR,
  LOAD_FILTER_CATEGORIES_SUCCESS,
  LOAD_CAT_HEIRCHY,
  LOAD_CAT_HEIRCHY_SUCCESS,
  LOAD_CAT_HEIRCHY_ERROR,
  LOAD_LOCATION_HEIRCHY,
  LOAD_LOCATION_HEIRCHY_SUCCESS,
  LOAD_LOCATION_HEIRCHY_ERROR,
} from './constants'

export const initialState: AddFilterSegmentState = {
  isfilterOptionsLoading: false,
  addFilterOptionsSuccess: false,
  isOperatorOptionsLoading: false,
  operatorOptionsSuccess: false,
  isFilterActionSuccess: false,
  filterData: [],
  removeFilterSuccess: false,
  addFilterSuccess: false,
  isSaveLoading: false,
  saveSuccess: false,
  filterCatLoading: false,
  filterCatSuccess: false,
  filterCategories: [],
  categoryHeirchyData: [],
  locationHeirchyData: [],
  catHeirSuccess: false,
  locationHeirSuccess: false,
  isCatHeirLoading: false,
  isLocationHeirLoading: false,
}

const updateFilterOptions = (
  arr: FilterData[],
  options: string[],
  newId: number,
) => {
  const updatedArr = [...arr]
  updatedArr.push({
    filter: '',
    operator: '',
    filterValue: '',
    filterOptions: options,
    operatorOptions: [],
    filterValueOptions: [],
    id: newId,
  })
  return [...updatedArr]
}

const updateFilterOperatorsData = (
  arr: FilterData[],
  options: string[],
  data: string[],
  id: number,
  filter: string,
) => {
  const index = arr.findIndex((item) => item.id === id)
  const updatedArr = [...arr]
  updatedArr[index].filterValueOptions = data
  updatedArr[index].operatorOptions = options
  updatedArr[index].filter = filter
  updatedArr[index].filterValue = ''
  updatedArr[index].operator = ''
  return [...updatedArr]
}

const updateFilter = (
  arr: FilterData[],
  id: number,
  key: string,
  value: string | number,
) => {
  const index = arr.findIndex((item) => item.id === id)
  const updatedArr = [...arr]
  if (key === 'FILTER_OPERATOR')
    updatedArr[index].operator = value.toString()
  else updatedArr[index].filterValue = value
  return [...updatedArr]
}

const removeBlock = (tempArr: FilterData[], id: number) => {
  const index = tempArr.findIndex((item) => item.id === id)

  // filterData dynamic values start at 1, so index + 1 to slice
  const filteredArrToSend = tempArr
    .slice(0, index)
    .concat(tempArr.slice(index + 1))

  return [...filteredArrToSend]
}

const reducer = (
  state: AddFilterSegmentState = initialState,
  action: AddFilterSegmentActionTypes,
): AddFilterSegmentState => {
  switch (action.type) {
    case LOAD_ADD_FILTER_OPTIONS:
      return {
        ...state,
        isfilterOptionsLoading: true,
      }
    case LOAD_ADD_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        isfilterOptionsLoading: false,
        addFilterOptionsSuccess: true,
        filterData: updateFilterOptions(
          [...state.filterData],
          action.payload.options,
          action.payload.id,
        ),
      }
    case LOAD_ADD_FILTER_OPTIONS_ERROR:
      return {
        ...state,
        isfilterOptionsLoading: false,
        addFilterOptionsSuccess: false,
      }

    case LOAD_FILTER_OPERATOR_OPTIONS:
      return {
        ...state,
        isOperatorOptionsLoading: true,
      }
    case LOAD_FILTER_OPERATOR_OPTIONS_SUCCESS:
      return {
        ...state,
        isOperatorOptionsLoading: false,
        operatorOptionsSuccess: true,
        filterData: updateFilterOperatorsData(
          [...state.filterData],
          action.payload.filterOperators,
          action.payload.filterOperatorsData,
          action.payload.id,
          action.payload.filter,
        ),
      }
    case LOAD_FILTER_OPERATOR_OPTIONS_ERROR:
      return {
        ...state,
        isOperatorOptionsLoading: false,
        operatorOptionsSuccess: false,
      }

    case UPDATE_FILTER_VALUES:
      return {
        ...state,
        isFilterActionSuccess: false,
      }
    case UPDATE_FILTER_VALUES_SUCCESS:
      return {
        ...state,
        isFilterActionSuccess: true,
        filterData: updateFilter(
          [...state.filterData],
          action.payload.id,
          action.payload.key,
          action.payload.value,
        ),
      }
    case UPDATE_FILTER_VALUES_ERROR:
      return {
        ...state,
        isFilterActionSuccess: false,
      }

    case REMOVE_FILTER:
      return {
        ...state,
        removeFilterSuccess: false,
      }
    case REMOVE_FILTER_SUCCESS:
      return {
        ...state,
        removeFilterSuccess: true,
        filterData: removeBlock(
          [...state.filterData],
          action.payload,
        ),
      }
    case REMOVE_FILTER_ERROR:
      return {
        ...state,
        removeFilterSuccess: false,
      }

    case SAVE_FILTER:
      return {
        ...state,
        isSaveLoading: false,
      }
    case SAVE_FILTER_SUCCESS:
      return {
        ...state,
        saveSuccess: true,
        isSaveLoading: false,
      }
    case SAVE_FILTER_ERROR:
      return {
        ...state,
        saveSuccess: false,
        isSaveLoading: false,
      }

    case LOAD_FILTER_CATEGORIES:
      return {
        ...state,
        filterCatLoading: false,
        filterCategories: initialState.filterCategories,
      }
    case LOAD_FILTER_CATEGORIES_SUCCESS:
      return {
        ...state,
        filterCatSuccess: true,
        filterCatLoading: false,
        filterCategories: action.payload,
      }
    case LOAD_FILTER_CATEGORIES_ERROR:
      return {
        ...state,
        filterCatSuccess: false,
        filterCatLoading: false,
      }
    case LOAD_CAT_HEIRCHY:
      return {
        ...state,
        isCatHeirLoading: true,
        categoryHeirchyData: initialState.categoryHeirchyData,
      }
    case LOAD_CAT_HEIRCHY_SUCCESS:
      return {
        ...state,
        catHeirSuccess: true,
        isCatHeirLoading: false,
        categoryHeirchyData: action.payload,
      }
    case LOAD_CAT_HEIRCHY_ERROR:
      return {
        ...state,
        catHeirSuccess: false,
        isCatHeirLoading: false,
      }
    case LOAD_LOCATION_HEIRCHY:
      return {
        ...state,
        isLocationHeirLoading: true,
        locationHeirchyData: initialState.locationHeirchyData,
      }
    case LOAD_LOCATION_HEIRCHY_SUCCESS:
      return {
        ...state,
        locationHeirSuccess: true,
        isLocationHeirLoading: false,
        locationHeirchyData: action.payload,
      }
    case LOAD_LOCATION_HEIRCHY_ERROR:
      return {
        ...state,
        locationHeirSuccess: false,
        isLocationHeirLoading: false,
      }
    case ADD_FILTER_SEGMENT_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
