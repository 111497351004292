import { WooCommerceActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_WOO_COMMERCE,
  INTEGRATE_WOO_COMMERCE_SUCCESS,
  INTEGRATE_WOO_COMMERCE_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    wooCommerce: {
      consumerKey: '',
      consumerSecret: '',
      version: { title: '', value: '' },
      wordPressURL: '',
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: WooCommerceActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_WOO_COMMERCE:
      return { ...state, isLoading: true }
    case INTEGRATE_WOO_COMMERCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_WOO_COMMERCE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
