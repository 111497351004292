export const LOAD_MARK_SOURCES_FILTERS =
  'APP/MARKETING/SOURCES/LOAD_FILTERS'
export const LOAD_MARK_SOURCES_FILTERS_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_FILTERS_SUCCESS '
export const LOAD_MARK_SOURCES_FILTERS_ERROR =
  'APP/MARKETING/SOURCES/LOAD_FILTERS_ERROR'
export const LOAD_MARK_SOURCES_GRAPH_DATA =
  'APP/MARKETING/SOURCES/LOAD_GRAPH_DATA'
export const LOAD_MARK_SOURCES_GRAPH_DATA_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_GRAPH_DATA_SUCCESS'
export const LOAD_MARK_SOURCES_GRAPH_DATA_ERROR =
  'APP/MARKETING/SOURCES/LOAD_GRAPH_DATA_ERROR'
export const LOAD_MARK_COUNTRY_FILTERS =
  'APP/MARKETING/SOURCES/LOAD_COUNTRY_FILTERS'
export const LOAD_MARK_COUNTRY_FILTERS_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_COUNTRY_FILTERS_SUCCESS '
export const LOAD_MARK_COUNTRY_FILTERS_ERROR =
  'APP/MARKETING/SOURCES/LOAD_COUNTRY_FILTERS_ERROR'

export const LOAD_MARKETING_KPI = 'APP/MARKETING/SOURCES/LOAD_KPI'
export const LOAD_MARKETING_KPI_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_KPI_SUCCESS'
export const LOAD_MARKETING_KPI_ERROR =
  'APP/MARKETING/SOURCES/LOAD_KPI_ERROR'

export const LOAD_MARK_CHANNEL_GRAPH =
  'APP/MARKETING/SOURCES/LOAD_CHANNEL_GRAPH'
export const LOAD_MARK_CHANNEL_GRAPH_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_CHANNEL_GRAPH_SUCCESS'
export const LOAD_MARK_CHANNEL_GRAPH_ERROR =
  'APP/MARKETING/SOURCES/LOAD_CHANNEL_GRAPH_ERROR'

export const LOAD_MARK_TBL_DATA =
  'APP/MARKETING/SOURCES/LOAD_TBL_DATA'
export const LOAD_MARK_TBL_DATA_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_TBL_DATA_SUCCESS'
export const LOAD_MARK_TBL_DATA_ERROR =
  'APP/MARKETING/SOURCES/LOAD_TBL_DATA_ERROR'

export const LOAD_MARK_AD_METRICS =
  'APP/MARKETING/SOURCES/LOAD_METRICS'
export const LOAD_MARK_AD_METRICS_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_METRICS_SUCCESS'
export const LOAD_MARK_AD_METRICS_ERROR =
  'APP/MARKETING/SOURCES/LOAD_METRICS_ERROR'

export const LOAD_CHART_DATA = 'APP/MARKETING/SOURCES/LOAD_CHART_DATA'
export const LOAD_CHART_DATA_SUCCESS =
  'APP/MARKETING/SOURCES/LOAD_CHART_DATA_SUCCESS'
export const LOAD_CHART_DATA_ERROR =
  'APP/MARKETING/SOURCES/LOAD_CHART_DATA_ERROR'

export const MARKETING_LIST_CLEAN_UP =
  'APP/MARKETING/SOURCES/LIST/CLEAN_UP'
