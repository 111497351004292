import {
  EDIT_ADDRESS,
  EDIT_ADDRESS_ERROR,
  EDIT_ADDRESS_SUCCESS,
  EDIT_MY_PROFILE_DATA,
  EDIT_MY_PROFILE_DATA_ERROR,
  EDIT_MY_PROFILE_DATA_SUCCESS,
  EDIT_PHONE_NUMBER,
  EDIT_PHONE_NUMBER_ERROR,
  EDIT_PHONE_NUMBER_SUCCESS,
  GET_PROFILE_DATA,
  GET_PROFILE_DATA_ERROR,
  GET_PROFILE_DATA_SUCCESS,
  PROFILES_CLEANUP,
} from './constants'
import { ProfileActionTypes } from './types/actions'
import { ProfilesState } from './types/reducer'

export const initialState: ProfilesState = {
  isPersonalDataLoading: false,
  isPersonalDataSuccess: false,
  isPersonalDataError: false,
  isPhoneLoading: false,
  isPhoneSuccess: false,
  isProfileLoading: false,
  isProfileSuccess: false,
  profileData: {
    firstName: '',
    lastName: '',
    gender: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
  },
  isAddressLoading: false,
  isAddressSuccess: false,
}
const reducer = (
  state: ProfilesState = initialState,
  action: ProfileActionTypes,
): ProfilesState => {
  switch (action.type) {
    case GET_PROFILE_DATA:
      return {
        ...state,
        isProfileLoading: true,
        profileData: initialState.profileData,
        isAddressSuccess: false,
        isPhoneSuccess: false,
        isPersonalDataSuccess: false,
      }
    case GET_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isProfileLoading: false,
        isProfileSuccess: true,
        profileData: action.payload,
      }
    case GET_PROFILE_DATA_ERROR:
      return {
        ...state,
        isProfileLoading: false,
        isProfileSuccess: false,
      }
    case EDIT_MY_PROFILE_DATA:
      return {
        ...state,
        isPersonalDataLoading: true,
      }
    case EDIT_MY_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        isPersonalDataLoading: false,
        isPersonalDataSuccess: true,
      }
    case EDIT_MY_PROFILE_DATA_ERROR:
      return {
        ...state,
        isPersonalDataLoading: false,
        isPersonalDataSuccess: false,
      }
    case EDIT_PHONE_NUMBER:
      return {
        ...state,
        isPhoneLoading: true,
      }
    case EDIT_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        isPhoneLoading: false,
        isPhoneSuccess: true,
      }
    case EDIT_PHONE_NUMBER_ERROR:
      return {
        ...state,
        isPhoneLoading: false,
        isPhoneSuccess: false,
      }
    case EDIT_ADDRESS:
      return {
        ...state,
        isAddressLoading: true,
      }
    case EDIT_ADDRESS_SUCCESS:
      return {
        ...state,
        isAddressLoading: false,
        isAddressSuccess: true,
      }
    case EDIT_ADDRESS_ERROR:
      return {
        ...state,
        isAddressLoading: false,
        isAddressSuccess: false,
      }
    case PROFILES_CLEANUP:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
