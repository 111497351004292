export const GET_PROFILE_DATA =
  'APP/PROFILES/MY_PROFILE/GET_PROFILE_DATA'
export const GET_PROFILE_DATA_SUCCESS =
  'APP/PROFILES/MY_PROFILE/GET_PROFILE_DATA_SUCCESS'
export const GET_PROFILE_DATA_ERROR =
  'APP/PROFILES/MY_PROFILE/GET_PROFILE_DATA_ERROR'

export const EDIT_MY_PROFILE_DATA =
  'APP/PROFILES/MY_PROFILE/EDIT_DATA'
export const EDIT_MY_PROFILE_DATA_SUCCESS =
  'APP/PROFILES/MY_PROFILE/EDIT_DATA_SUCCESS'
export const EDIT_MY_PROFILE_DATA_ERROR =
  'APP/PROFILES/MY_PROFILE/EDIT_DATA_ERROR'

export const EDIT_PHONE_NUMBER =
  'APP/PROFILES/MY_PROFILE/EDIT_PHONE_NUMBER'
export const EDIT_PHONE_NUMBER_SUCCESS =
  'APP/PROFILES/MY_PROFILE/EDIT_PHONE_NUMBER_SUCCESS'
export const EDIT_PHONE_NUMBER_ERROR =
  'APP/PROFILES/MY_PROFILE/EDIT_PHONE_NUMBER_ERROR'

export const EDIT_ADDRESS =
  'APP/PROFILES/MANAGE_ADDRESSES/EDIT_ADDRESS'
export const EDIT_ADDRESS_SUCCESS =
  'APP/PROFILES/MANAGE_ADDRESSES/EDIT_ADDRESS_SUCCESS'
export const EDIT_ADDRESS_ERROR =
  'APP/PROFILES/MANAGE_ADDRESSES/EDIT_ADDRESS_ERROR'

export const DELETE_ADDRESS =
  'APP/PROFILES/MANAGE_ADDRESSES/DELETE_ADDRESS'
export const DELETE_ADDRESS_SUCCESS =
  'APP/PROFILES/MANAGE_ADDRESSES/DELETE_ADDRESS_SUCCESS'
export const DELETE_ADDRESS_ERROR =
  'APP/PROFILES/MANAGE_ADDRESSES/DELETE_ADDRESS_ERROR'
export const PROFILES_CLEANUP = 'APP/PROFILES/CLEAN_UP'
