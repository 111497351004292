import React, { useState, useCallback, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderComponent from 'components/Header/DashboardHeader'
import { RootStore } from 'store'
import { useHistory } from 'react-router-dom'
import { userSignOut, userSignOutCleanup } from '../actions'

const Header = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const [autoLogout, setAutoLogout] = useState(false)
  const [notificationAnchorEl, setNotiAnchorEl] = useState(null)
  const signOutData = useSelector((state: RootStore) => state.signOut)
  const {data} = useSelector((state: RootStore) => state.userAuth)

  const handleClick = useCallback(
    // eslint-disable-next-line
    (event: React.MouseEvent<any, MouseEvent>) => {
      if (anchorEl) {
        setAnchorEl(null)
        return
      }
      setAnchorEl(event.currentTarget)
      setNotiAnchorEl(null)
    },
    [anchorEl],
  )

  const handleNotificationClick = useCallback(
    // eslint-disable-next-line
    (event: React.MouseEvent<any, MouseEvent>) => {
      if (notificationAnchorEl) {
        setNotiAnchorEl(null)
        return
      }
      setNotiAnchorEl(event.currentTarget)
      setAnchorEl(null)
    },
    [notificationAnchorEl],
  )

  const handleClickAway = useCallback(() => {
    setAnchorEl(null)
    setNotiAnchorEl(null)
  }, [])

  const handleLogout = useCallback(() => {
    dispatch(userSignOut(data.userId, autoLogout, localStorage.getItem('MANTIX_AUTH_TOKEN')))
    history.push({
      pathname: '/signin',
    })
  }, [dispatch])

  const handleChangePassword = useCallback(() => {
    history.push({
      pathname: '/change-password',
    })
  }, [history])

  const clearAll = useCallback(() => {}, [])
  const handleProfile = useCallback(() => {
    history.push({
      pathname: '/profile',
    })
    setAnchorEl(null)
  }, [history])

  useEffect(() => {
    const updateProfile = localStorage.getItem('updateProfile')
    if (updateProfile) {
      dispatch(userSignOut(data.userId, autoLogout, localStorage.getItem('MANTIX_AUTH_TOKEN')))
      history.push({
        pathname: '/signin',
      })
    }
  }, [])

  useEffect(() => {
    if (signOutData.success) history.push('/signin')
  }, [signOutData.success, history])

  useEffect(() => {
    return () => {
      dispatch(userSignOutCleanup())
    }
  }, [dispatch])

  return (
    <HeaderComponent
      anchorEl={anchorEl}
      notificationAnchorEl={notificationAnchorEl}
      handleClick={handleClick}
      handleNotificationClick={handleNotificationClick}
      handleClickAway={handleClickAway}
      handleLogout={handleLogout}
      handleChangePassword={handleChangePassword}
      clearAll={clearAll}
      handleProfile={handleProfile}
    />
  )
}

export default memo(Header)
