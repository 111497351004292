import React, { FC } from 'react'
import { ThemeProvider } from '@material-ui/core'

import theme from './theme'

const Theme: FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

interface Props {
  children: React.ReactNode
}

export default Theme
