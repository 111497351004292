/* eslint-disable no-param-reassign */

import { SyndicationActionTypes } from './types/actions'
import { SyndicationList, SyndicationState } from './types/reducer'
import {
  SYNDICATION_CLEAN_UP,
  LOAD_SUBSCRIBERS_LIST,
  LOAD_SUBSCRIBERS_LIST_ERROR,
  LOAD_SUBSCRIBERS_LIST_SUCCESS,
  NEW_SYNDICATION,
  NEW_SYNDICATION_ERROR,
  NEW_SYNDICATION_SUCCESS,
  LOAD_SYNDICATION_LIST,
  LOAD_SYNDICATION_LIST_ERROR,
  LOAD_SYNDICATION_LIST_SUCCESS,
  SYNDICATION_CONSENT,
  SYNDICATION_CONSENT_ERROR,
  SYNDICATION_CONSENT_SUCCESS,
  SYNDICATION_TARGET,
  SYNDICATION_TARGET_ERROR,
  SYNDICATION_TARGET_SUCCESS,
  DELETE_SYNDICATION,
  DELETE_SYNDICATION_ERROR,
  DELETE_SYNDICATION_SUCCESS,
  AUTO_UPDATE_SYNDICATION,
  AUTO_UPDATE_SYNDICATION_ERROR,
  AUTO_UPDATE_SYNDICATION_SUCCESS,
  GET_SYNDICATION_DETAIL_SUCCESS,
} from './constants'

export const initialState: SyndicationState = {
  isLoading: false,
  success: false,
  audiences: [],
  creatingSyn: false,
  createSynSuccess: false,
  synLoading: false,
  synListSuccess: false,
  syndications: [],
  syndicationDetail: {
    name: '',
    description: '',
    target: '',
    segmentName: '' || null,
    audienceType: '',
    audienceName: '',
    consentStatus: '',
    autoUpdate: false,
    payload: [
      {
        email: '',
        firstName: '',
        lastName: '',
      },
    ],
  },
  totalElements: 0,
  targetSuccess: false,
  targets: [],
  consentSuccess: false,
  consents: [],
  deleting: false,
  deleteSuccess: false,
  autoUpdate: false,
  autoUpdateSuccess: false,
}

const reducer = (
  state: SyndicationState = initialState,
  action: SyndicationActionTypes,
): SyndicationState => {
  switch (action.type) {
    case LOAD_SUBSCRIBERS_LIST:
      return {
        ...state,
        isLoading: true,
        audiences: initialState.audiences,
      }
    case LOAD_SUBSCRIBERS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        audiences: action.payload,
      }
    case LOAD_SUBSCRIBERS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case NEW_SYNDICATION:
      return {
        ...state,
        creatingSyn: true,
      }
    case NEW_SYNDICATION_SUCCESS:
      return {
        ...state,
        creatingSyn: false,
        createSynSuccess: true,
      }
    case NEW_SYNDICATION_ERROR:
      return {
        ...state,
        creatingSyn: false,
        createSynSuccess: false,
      }
    case LOAD_SYNDICATION_LIST:
      return {
        ...state,
        synLoading: true,
        syndications: initialState.syndications,
      }
    case LOAD_SYNDICATION_LIST_SUCCESS:
      return {
        ...state,
        synLoading: false,
        synListSuccess: true,
        syndications: action.payload.list,
        totalElements: action.payload.total,
      }
    case LOAD_SYNDICATION_LIST_ERROR:
      return {
        ...state,
        synLoading: false,
        synListSuccess: false,
      }

    case SYNDICATION_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    case SYNDICATION_TARGET:
      return {
        ...state,
        isLoading: true,
        targets: initialState.targets,
      }
    case SYNDICATION_TARGET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        targetSuccess: true,
        targets: action.payload,
      }
    case SYNDICATION_TARGET_ERROR:
      return {
        ...state,
        isLoading: false,
        targetSuccess: false,
      }

    case SYNDICATION_CONSENT:
      return {
        ...state,
        isLoading: true,
        consents: initialState.consents,
      }
    case SYNDICATION_CONSENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        consentSuccess: true,
        consents: action.payload,
      }
    case SYNDICATION_CONSENT_ERROR:
      return {
        ...state,
        isLoading: false,
        consentSuccess: false,
      }

    case DELETE_SYNDICATION:
      return {
        ...state,
        deleting: true,
        deleteSuccess: initialState.deleteSuccess,
      }
    case DELETE_SYNDICATION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: true,
      }
    case DELETE_SYNDICATION_ERROR:
      return {
        ...state,
        deleting: false,
        deleteSuccess: false,
      }

    case AUTO_UPDATE_SYNDICATION:
      return {
        ...state,
        autoUpdate: true,
        autoUpdateSuccess: initialState.autoUpdateSuccess,
      }
    case AUTO_UPDATE_SYNDICATION_SUCCESS: {
      const syndications: SyndicationList[] = state.syndications.map(
        (syndication) => {
          if (syndication.name === action.payload.name)
            syndication.autoUpdate = !syndication.autoUpdate
          return syndication
        },
      )
      return {
        ...state,
        autoUpdateSuccess: true,
        syndications,
      }
    }
    case GET_SYNDICATION_DETAIL_SUCCESS: {
      return {
        ...state,
        syndicationDetail: action.payload,
      }
    }
    case AUTO_UPDATE_SYNDICATION_ERROR:
      return {
        ...state,
        autoUpdate: false,
        autoUpdateSuccess: false,
      }

    default:
      return state
  }
}

export default reducer
