import { ChangePasswordActionTypes } from './types/actions'
import { ChangePasswordState } from './types/reducer'
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_CLEAN_UP,
} from './constants'

export const initialState: ChangePasswordState = {
  isLoading: false,
  success: false,
}

const reducer = (
  state: ChangePasswordState = initialState,
  action: ChangePasswordActionTypes,
): ChangePasswordState => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state, isLoading: true }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      }
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case CHANGE_PASSWORD_CLEAN_UP:
      return { ...state, ...initialState }
    default:
      return state
  }
}

export default reducer
