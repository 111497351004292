export const LOAD_GLOBAL_FILTERS_LIST = 'APP/GLOBAL_FILTERS/LOAD_LIST'
export const LOAD_GLOBAL_FILTERS_LIST_SUCCESS =
  'APP/GLOBAL_FILTERS/LOAD_LIST_SUCCESS'
export const LOAD_GLOBAL_FILTERS_LIST_ERROR =
  'APP/GLOBAL_FILTERS/LOAD_LIST_ERROR'

export const LOAD_PRODUCTS_FILTERS =
  'APP/GLOBAL_FILTERS/LOAD_PRODUCTS_FILTERS'
export const LOAD_PRODUCTS_FILTERS_SUCCESS =
  'APP/GLOBAL_FILTERS/LOAD_PRODUCTS_FILTERS_SUCCESS'
export const LOAD_PRODUCTS_FILTERS_ERROR =
  'APP/GLOBAL_FILTERS/LOAD_PRODUCTS_FILTERS_ERROR'

export const SAVE_FILTERS_STATE =
  'APP/GLOBAL_FILTERS/SAVE_FILTERS_STATE'
export const SAVE_FILTERS_STATE_SUCCESS =
  'APP/GLOBAL_FILTERS/SAVE_FILTERS_STATE_SUCCESS'
export const SAVE_FILTERS_STATE_ERROR =
  'APP/GLOBAL_FILTERS/SAVE_FILTERS_STATE_ERROR'

export const FILTERS_LIST_CLEAN_UP =
  'APP/GLOBAL_FILTERS/LIST/CLEAN_UP'
