import { SidebarListActionTypes } from './types/actions'
import { SidebarListState } from './types/reducer'
import {
  LOAD_SIDEBAR_LIST,
  LOAD_SIDEBAR_LIST_SUCCESS,
  LOAD_SIDEBAR_LIST_ERROR,
  CLEAN_UP,
} from './constants'

export const initialState: SidebarListState = {
  isLoading: false,
  success: false,
  data: [],
}

const reducer = (
  state: SidebarListState = initialState,
  action: SidebarListActionTypes,
): SidebarListState => {
  switch (action.type) {
    case LOAD_SIDEBAR_LIST:
      return { ...state, isLoading: true }
    case LOAD_SIDEBAR_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        success: true,
      }
    case LOAD_SIDEBAR_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
