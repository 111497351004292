import { createStore, applyMiddleware, Store } from 'redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer, { AppState, reducers } from 'reducers'

const store = (): Store<AppState> => {
  const composeEnhancers = composeWithDevTools({})

  const storeConfig = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunk as ThunkMiddleware<AppState>),
    ),
  )

  return storeConfig
}

export type RootStore = ReturnType<typeof reducers>

export default store
