export function noWhiteSpace(event: any) {
  const k = event ? event.which : event.charCode
  if (k === 32) event.preventDefault()
}
export function noAlphabets(event: any) {
  const k = event ? event.which : event.charCode
  if ((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || k === 32)
    event.preventDefault()
}

export const regex = {
  passwordPattern: `^(?!.*\\s)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$`,
  passwordCheck: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
}

export const getUA = () => {
  let device = "Unknown";
  const ua: any = {
      "Generic Linux": /Linux/i,
      "Android": /Android/i,
      "BlackBerry": /BlackBerry/i,
      "Bluebird": /EF500/i,
      "Chrome OS": /CrOS/i,
      "Datalogic": /DL-AXIS/i,
      "Honeywell": /CT50/i,
      "iPad": /iPad/i,
      "iPhone": /iPhone/i,
      "iPod": /iPod/i,
      "macOS": /Macintosh/i,
      "Windows": /IEMobile|Windows/i,
      "Zebra": /TC70|TC55/i,
  }
  Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
  return device;
}