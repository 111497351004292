import { CustomerSegmentsActionTypes } from "./types/actions";
import { CustomerSegmentsState } from "./types/reducer";
import {
  LOAD_CUSTOMER_SEGMENTS,
  LOAD_CUSTOMER_SEGMENTS_SUCCESS,
  LOAD_CUSTOMER_SEGMENTS_ERROR,
  LOAD_SEGMENT_OPTIONS,
  LOAD_SEGMENT_OPTIONS_ERROR,
  LOAD_SEGMENT_OPTIONS_SUCCESS,
  LOAD_SEGMENT_GRAPH_DATA,
  LOAD_SEGMENT_GRAPH_DATA_ERROR,
  LOAD_SEGMENT_GRAPH_DATA_SUCCESS,
  LOAD_PRODUCTS_PURCHASED_LIST,
  LOAD_PRODUCTS_PURCHASED_LIST_SUCCESS,
  LOAD_PRODUCTS_PURCHASED_LIST_ERROR,
  LOAD_PERFORMANCE_DATA,
  LOAD_PERFORMANCE_DATA_SUCCESS,
  LOAD_PERFORMANCE_DATA_ERROR,
  DOWNLOAD_SEGMENT_DATA_SUCCESS,
  DOWNLOAD_SEGMENT_DATA,
  DOWNLOAD_SEGMENT_DATA_ERROR,
  LOAD_EMAIL_CAMPAIGN,
  LOAD_EMAIL_CAMPAIGN_SUCCESS,
  LOAD_EMAIL_CAMPAIGN_ERROR,
  CUSTOMER_SEGMENTS_CLEAN_UP,
} from "./constants";

export const initialState: CustomerSegmentsState = {
  isLoading: false,
  segmentTblsuccess: false,
  segmentDetails: {
    tableData: [],
    kpi: [],
  },
  segmentOptionsSuccess: false,
  segmentOptions: [],
  isGraphLoading: false,
  graphDataSuccess: false,
  graphData: {
    actual: {
      data: [],
    },
    projected: {
      data: [],
    },
    dateLabels: [],
  },
  productPurchasedLoading: false,
  productPurchasedSuccess: false,
  productPurchasedList: [],
  performanceDataLoading: false,
  performanceDataSuccess: false,
  performanceDataList: [],
  downloadSegmentDataLoading: false,
  downloadSegmentDataSuccess: false,
  downloadSegmentData: [],
  custEmailCampaignLoading: false,
  custEmailCampaignSuccess: false,
  custEmailCampaignList: [],
  totalElements: 0,
  totalPages: 0,
}

const reducer = (
  state: CustomerSegmentsState = initialState,
  action: CustomerSegmentsActionTypes
): CustomerSegmentsState => {
  switch (action.type) {
    case LOAD_CUSTOMER_SEGMENTS:
      return {
        ...state,
        isLoading: true,
        segmentDetails: initialState.segmentDetails,
      };
    case LOAD_CUSTOMER_SEGMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        segmentTblsuccess: true,
        segmentDetails: action.payload,
      };
    case LOAD_CUSTOMER_SEGMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        segmentTblsuccess: false,
      };

    case LOAD_SEGMENT_OPTIONS:
      return { ...state, isLoading: true };
    case LOAD_SEGMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        segmentOptionsSuccess: true,
        segmentOptions: action.payload,
      };
    case LOAD_SEGMENT_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        segmentOptionsSuccess: false,
      };

    case LOAD_SEGMENT_GRAPH_DATA:
      return {
        ...state,
        isGraphLoading: true,
        graphData: initialState.graphData,
      };
    case LOAD_SEGMENT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        isGraphLoading: false,
        graphDataSuccess: true,
        graphData: action.payload,
      };
    case LOAD_SEGMENT_GRAPH_DATA_ERROR:
      return {
        ...state,
        isGraphLoading: false,
        graphDataSuccess: false,
      };

    case LOAD_PRODUCTS_PURCHASED_LIST:
      return {
        ...state,
        productPurchasedLoading: true,
        productPurchasedList: initialState.productPurchasedList,
      };
    case LOAD_PRODUCTS_PURCHASED_LIST_SUCCESS:
      return {
        ...state,
        productPurchasedLoading: false,
        productPurchasedSuccess: true,
        productPurchasedList: action.payload.products,
        totalPages: action.payload.totalPages,
      }
    case LOAD_PRODUCTS_PURCHASED_LIST_ERROR:
      return {
        ...state,
        productPurchasedLoading: false,
        productPurchasedSuccess: false,
      };
    case DOWNLOAD_SEGMENT_DATA:
      return {
        ...state,
        downloadSegmentDataLoading: true,
        downloadSegmentData: initialState.downloadSegmentData,
      };
    case DOWNLOAD_SEGMENT_DATA_SUCCESS:
      return {
        ...state,
        downloadSegmentDataLoading: false,
        downloadSegmentDataSuccess: true,
        downloadSegmentData: action.payload,
      };
    case DOWNLOAD_SEGMENT_DATA_ERROR:
      return {
        ...state,
        downloadSegmentDataLoading: false,
        downloadSegmentDataSuccess: false,
      };

    case LOAD_PERFORMANCE_DATA:
      return {
        ...state,
        performanceDataLoading: true,
        performanceDataList: initialState.performanceDataList,
      };
    case LOAD_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        performanceDataLoading: false,
        performanceDataSuccess: true,
        performanceDataList: action.payload,
      };
    case LOAD_PERFORMANCE_DATA_ERROR:
      return {
        ...state,
        performanceDataLoading: false,
        performanceDataSuccess: false,
      };
    case LOAD_EMAIL_CAMPAIGN:
      return {
        ...state,
        custEmailCampaignLoading: true,
        custEmailCampaignList: initialState.custEmailCampaignList,
      };
    case LOAD_EMAIL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        custEmailCampaignLoading: false,
        custEmailCampaignSuccess: true,
        custEmailCampaignList: action.payload.custEmailCampaignList,
        totalElements: action.payload.totalElements,
      };
    case LOAD_EMAIL_CAMPAIGN_ERROR:
      return {
        ...state,
        custEmailCampaignLoading: false,
        custEmailCampaignSuccess: false,
      };
    case CUSTOMER_SEGMENTS_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
