import { SignInVerifyActionTypes } from './types/actions'
import { SignInVerifyState } from './types/reducer'
import {
  USER_SIGN_IN_VERIFY,
  USER_SIGN_IN_VERIFY_SUCCESS,
  USER_SIGN_IN_VERIFY_ERROR,
  SIGN_IN_VERIFY_CLEAN_UP,
  RESEND_CODE,
  RESEND_CODE_SUCCESS,
  RESEND_CODE_ERROR,
} from './constants'

export const initialState: SignInVerifyState = {
  isLoading: false,
  success: false,
  userId: '',
  resendSuccess: false,
  tenantId: '',
}

const reducer = (
  state: SignInVerifyState = initialState,
  action: SignInVerifyActionTypes,
): SignInVerifyState => {
  switch (action.type) {
    case USER_SIGN_IN_VERIFY:
      return { ...state, isLoading: true }
    case USER_SIGN_IN_VERIFY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        userId: action.payload.userId,
        tenantId: action.payload.tenantId,
      }
    case USER_SIGN_IN_VERIFY_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case SIGN_IN_VERIFY_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    case RESEND_CODE:
      return { ...state, isLoading: true }
    case RESEND_CODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resendSuccess: true,
      }
    case RESEND_CODE_ERROR:
      return {
        ...state,
        isLoading: false,
        resendSuccess: false,
      }
    default:
      return state
  }
}

export default reducer
