export const LOAD_CATEGORIES_LIST = "APP/PRODUCT/CATEGORIES/LOAD_LIST";
export const LOAD_CATEGORIES_LIST_SUCCESS =
  "APP/PRODUCT/CATEGORIES/LOAD_LIST_SUCCESS";
export const LOAD_CATEGORIES_LIST_ERROR =
  "APP/PRODUCT/CATEGORIES/LOAD_LIST_ERROR";

export const LOAD_CATEGORIES_SEARCH_OPTIONS =
  "APP/PRODUCT/CATEGORIES/LOAD_SEARCH_OPTIONS";
export const LOAD_CATEGORIES_SEARCH_OPTIONS_SUCCESS =
  "APP/PRODUCT/CATEGORIES/LOAD_SEARCH_OPTIONS_SUCCESS";
export const LOAD_CATEGORIES_SEARCH_OPTIONS_ERROR =
  "APP/PRODUCT/CATEGORIES/LOAD_SEARCH_OPTIONS_ERROR";

export const CATEGORIES_LIST_CLEAN_UP = "APP/PRODUCT/CATEGORIES/LIST/CLEAN_UP";

export const LOAD_CATEGORIES_GRAPH = "APP/PRODUCT/CATEGORIES/LOAD_GRAPH";
export const LOAD_CATEGORIES_GRAPH_SUCCESS =
  "APP/PRODUCT/CATEGORIES/LOAD_GRAPH_SUCCESS";
export const LOAD_CATEGORIES_GRAPH_ERROR =
  "APP/PRODUCT/CATEGORIES/LOAD_GRAPH_ERROR";

export const LOAD_CATEGORIES_PIE_GRAPH =
  "APP/PRODUCT/CATEGORIES/LOAD_PIE_GRAPH";
export const LOAD_CATEGORIES_PIE_GRAPH_SUCCESS =
  "APP/PRODUCT/CATEGORIES/LOAD_PIE_GRAPH_SUCCESS";
export const LOAD_CATEGORIES_PIE_GRAPH_ERROR =
  "APP/PRODUCT/CATEGORIES/LOAD_PIE_GRAPH_ERROR";
export const LOAD_PRODUCT_STATUS_OPTIONS =
  "APP/PRODUCT/CATEGORIES/LOAD_STATUS_OPTIONS";
export const LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS =
  "APP/PRODUCT/CATEGORIES/LOAD_STATUS_OPTIONS_SUCCESS";
export const LOAD_PRODUCT_STATUS_OPTIONS_ERROR =
  'APP/PRODUCT/CATEGORIES/LOAD_STATUS_OPTIONS_ERROR'
export const LOAD_CATEGORIES_FOR_EXPORT = 'APP/CATEGORIES/EXPORT'
export const LOAD_CATEGORIES_FOR_EXPORT_SUCCESS =
  'APP/CATEGORIES/EXPORT_SUCCESS'
export const LOAD_CATEGORIES_FOR_EXPORT_ERROR =
  'APP/CATEGORIES/EXPORT_ERROR'
