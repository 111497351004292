import React, { FC } from "react";
import { Provider } from "react-redux";
import ErrorNotification from "containers/Errors";
import Theme from "../theme";
import Routes from "./AppRoutes";
import configureStore from "../store";

export const store = configureStore();

const App: FC = (): JSX.Element => {
  
  return <Provider store={store}>
    <Theme>
      <ErrorNotification />
      <Routes />
    </Theme>
  </Provider>
};

export default App;
