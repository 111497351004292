import { SignOutActionTypes } from './types/actions'
import { SignOutState } from './types/reducer'
import {
  USER_SIGN_OUT,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_ERROR,
  SIGN_OUT_CLEAN_UP,
} from './constants'

export const initialState: SignOutState = {
  isLoading: false,
  success: false,
}

const reducer = (
  state: SignOutState = initialState,
  action: SignOutActionTypes,
): SignOutState => {
  switch (action.type) {
    case USER_SIGN_OUT:
      return { ...state, isLoading: true }
    case USER_SIGN_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      }
    case USER_SIGN_OUT_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case SIGN_OUT_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state
  }
}

export default reducer
