import { createTheme } from '@material-ui/core/styles'

declare module '@material-ui/core/styles/createTheme' {
  interface App {
    colors: {
      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      grey: React.CSSProperties['color']
      lightGrey: React.CSSProperties['color']
      darkGrey: React.CSSProperties['color']
      greyScrollColor: React.CSSProperties['color']
      headerBorderColor: React.CSSProperties['color']
      switchBtnDefault: React.CSSProperties['color']
      linkColor: React.CSSProperties['color']
      activeMenuItem: React.CSSProperties['color']
      dialogBackgroundColor: React.CSSProperties['color']
      wrapperBackground: React.CSSProperties['color']
      textBoxBackground: React.CSSProperties['color']
      textBoxBorder: React.CSSProperties['color']
      labelBackground: React.CSSProperties['color']
      darkLabelColor: React.CSSProperties['color']
      lightLabelColor: React.CSSProperties['color']
      sectionSeparator: React.CSSProperties['color']
      pink: React.CSSProperties['color']
      yellow: React.CSSProperties['color']
      green: React.CSSProperties['color']
      blue: React.CSSProperties['color']
      red: React.CSSProperties['color']
      orange: React.CSSProperties['color']
      dodgerBlue: React.CSSProperties['color']
      smallLabelColor: React.CSSProperties['color']
      lineChartLightColor: React.CSSProperties['color']
      lineChartDarkColor: React.CSSProperties['color']
      profileBackgroundColor: React.CSSProperties['color']
      darkNavyBlue: React.CSSProperties['color']
      sidebarRouteTextBlack: React.CSSProperties['color']
    }
    fonts: {
      textLarge: React.CSSProperties['fontSize']
      textMedium: React.CSSProperties['fontSize']
      textSmall: React.CSSProperties['fontSize']
      textExtraSmall: React.CSSProperties['fontSize']
      kpiNumbers: React.CSSProperties['fontSize']
      bigNumbers: React.CSSProperties['fontSize']
      mediumNumbers: React.CSSProperties['fontSize']
      pagetitle: React.CSSProperties['fontSize']
      smallIcon: React.CSSProperties['fontSize']
      mediumIcon: React.CSSProperties['fontSize']
    }
  }

  interface Theme {
    app: App
  }

  interface ThemeOptions {
    app: App
  }
}

const theme = createTheme({
  palette: {
    primary: {
      light: '#8885c7',
      main: '#4540BB',
      dark: '#302c82',
    },
    secondary: {
      main: '#1FD0A3',
    },
    background: {
      // default: '#eceff1',
    },
  },
  app: {
    colors: {
      white: '#fff',
      black: '#000',
      grey: 'rgba(196, 196, 196, 0.5)',
      lightGrey: '#F0F0F0',
      darkGrey: 'rgba(0, 0, 0, 0.54)',
      greyScrollColor: '#c1c1c1',
      headerBorderColor: '#eceff8',
      switchBtnDefault: 'rgba(31, 208, 163, 0.14)',
      linkColor: '#8E9AC4',
      activeMenuItem: '#635fc6',
      dialogBackgroundColor: '#6a66c982',
      wrapperBackground: '#EAEDF7',
      textBoxBackground: '#FAFCFE',
      textBoxBorder: '#D2D5E1',
      labelBackground: '#E1E3EC',
      darkLabelColor: '#636E95',
      lightLabelColor: '#9F9B9B',
      sectionSeparator: 'rgba(0, 0, 0, 0.3)',
      pink: '#FA5087',
      yellow: '#FFC800',
      green: '#1FD0A3',
      blue: '#369AFE',
      red: '#ff0000',
      orange: '#FFA500',
      dodgerBlue: '#0080ff',
      smallLabelColor: '#7C88B1',
      profileBackgroundColor: '#FFCABA',
      lineChartLightColor: 'rgba(28, 208, 163, 0.46)',
      lineChartDarkColor: 'rgba(133, 126, 255, 0.46)',
      darkNavyBlue: '#242F57',
      sidebarRouteTextBlack: '#343a40',
    },
    fonts: {
      // text font
      textLarge: '1.4rem',
      textMedium: '1.2rem',
      textSmall: '1rem',
      textExtraSmall: '0.8rem',

      // numbers font
      bigNumbers: '2.4rem',
      kpiNumbers: '1.9rem',
      mediumNumbers: '1.6rem',

      // others font
      pagetitle: '1.8rem',
      smallIcon: '1.6rem',
      mediumIcon: '2rem',
    },
  },
  typography: {
    allVariants: {
      fontFamily: `"-apple-system",
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        "Helvetica Neue",
        Arial,
        "sans-serif",
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol"`,
    },
    htmlFontSize: 10,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        fontSize: '1.2rem',
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        fontSize: '1.4rem',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.4rem',
      },
    },
    MuiTypography: {
      caption: {
        fontSize: '1.2rem',
      },
      body2: {
        fontSize: '1.2rem',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '1.2rem',
        padding: '1.5rem 0.5rem',
        '&:first-child': {
          paddingLeft: '2rem',
        },
        '&:last-child': {
          paddingRight: '2rem',
        },
      },
      head: {
        fontWeight: 'bold',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0px 0px 2px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 0,
      },
    },
  },
})

export default theme
