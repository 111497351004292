import React, { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  // error: Error
}

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      // error: {
      //   name: '',
      //   message: '',
      // },
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static getDerivedStateFromError(_error: Error): State {
    return {
      hasError: true,
      // error: { name: '', message: '' }
    };
  }

  retryPageLoading = () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem('PageHasBeenForceRefreshed') || "false"
    ) as boolean;
  
    if (!pageHasAlreadyBeenForceRefreshed) {
      window.localStorage.setItem('PageHasBeenForceRefreshed', "true");
      return window.location.reload();
    } else {
      window.localStorage.setItem('PageHasBeenForceRefreshed', "false");
    }
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    this.retryPageLoading();
    console.error(
      'Uncaught error, from Error Boundary:',
      error,
      errorInfo,
    )
    // if (/Loading chunk [\d]+ failed/.test(error)) {
    //   window.location.reload();
    // }
    // this.setState({
    //   error,
    // })
  }

  render(): ReactNode {
    const {
      state: {
        hasError,
        // error,
      },
      props: { children },
    } = this;
    if (hasError) {
      return (
        <>
          <h5>Something went wrong</h5>
          {/* <div>{error.name}</div>
          <div>{error.message}</div> */}
        </>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
