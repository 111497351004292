/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FilterObj } from "containers/Marketing/Advertising/types/reducer";
import { objToSend } from "containers/Marketing/Sources/types/reducer";
import mantixApiInstance from "../interceptors/interceptor";

const PAGE = "marketing/";

const LOAD_MARK_SOURCES_FILTERS_URL =
  process.env.REACT_APP_LOAD_MARK_SOURCES_FILTERS;
const LOAD_MARK_COUNTRY_FILTERS_URL =
  process.env.REACT_APP_LOAD_MARK_COUNTRY_FILTERS;

const LOAD_MARK_KPI_URL = process.env.REACT_APP_LOAD_MARK_KPI;
const LOAD_MARKETING_AD_KPI_URL = process.env.REACT_APP_LOAD_MARKETING_AD_KPI;
const LOAD_MARK_CHANNEL_GRAPH_URL =
  process.env.REACT_APP_LOAD_MARK_CHANNEL_GRAPH;
const LOAD_MARK_AD_METRICS_URL = process.env.REACT_APP_LOAD_MARK_AD_METRICS;
const LOAD_MARK_CHANNEL_TABLE_URL =
  process.env.REACT_APP_LOAD_MARK_CHANNEL_TABLE;
const LOAD_MARK_TABLE_URL = process.env.REACT_APP_LOAD_MARK_TABLE;
const REACT_APP_LOAD_MARK_FLOW_CHART_URL =
  process.env.REACT_APP_LOAD_MARK_FLOW_CHART;

const CHART_DATA_URL = process.env.REACT_APP_MARK_CHART_DATA;
const MARK_AD_CAMPAIGN_GRAPH_URL = process.env.REACT_APP_MARK_AD_CAMPAIGN_GRAPH;
const ABANDONED_CART_SALES_KPI_URL =
  process.env.REACT_APP_GET_ABANDONED_CART_SALES_KPI;
const ABANDONED_CART_KPI_URL = process.env.REACT_APP_GET_ABANDONED_CART_KPI;
const ABANDONED_CART_CHART_URL =
  process.env.REACT_APP_GET_ABANDONED_CART_GRAPH_DATA;
const ABANDONED_CART_CUSTOMER_DETAILS_URL =
  process.env.REACT_APP_GET_ABANDONED_CART_CUSTOMER_DETAILS;
const ABANDONED_CART_SALES_DETAILS_URL =
  process.env.REACT_APP_ABANDONED_CART_SALES_DETAILS;

export const loadMarketingFilters = (params: {
  type: string;
  tenantId: string;
  storeId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_SOURCES_FILTERS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarkCountryFilters = (params: {
  country: string | null;
  tenantId: string;
  storeId: string;
  type: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_COUNTRY_FILTERS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarkKpi = (
  params: {
    tenantId: string;
    storeId: string;
    from: string;
    to: string;
    type: string;
  },
  filterObject: objToSend[]
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_KPI_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadMarkChannelGraph = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_CHANNEL_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarkChannelTable = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  page: number;
  pageSize: number;
  searchText: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_CHANNEL_TABLE_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarketingAdMetrics = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_AD_METRICS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarketingAdKPI = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARKETING_AD_KPI_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarketingList = (
  params: {
    tenantId: string
    storeId: string
    from: string
    to: string
    page: number
    pageSize: number
    type: string
    sortOrder: string
    sortBy: string
  },
  filterObject: FilterObj[]
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MARK_TABLE_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadMarketingFlowChart = (
  params: {
    tenantId: string;
    storeId: string;
    from: string;
    to: string;
  },
  filterObject: objToSend[]
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${REACT_APP_LOAD_MARK_FLOW_CHART_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadMarkChartData = (
  params: {
    tenantId: string;
    storeId: string;
    from: string;
    to: string;
  },
  filterObject: objToSend[]
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${CHART_DATA_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadMarketingCampaignType = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: MARK_AD_CAMPAIGN_GRAPH_URL,
    method: "post",
    data,
  };
  return mantixApiInstance.request(config);
};

// abandoned cart api
export const loadMarkAbandonedCartKpi = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${ABANDONED_CART_SALES_KPI_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadMarkAbandonedCartSalesKpi = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${ABANDONED_CART_KPI_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMarkAbandonedCartChart = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  type: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${ABANDONED_CART_CHART_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadMarkAbandonedCartCustomerDetails = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  page: number;
  pageSize: number;
  text: string;
  type: string;
  sortOrder: string;
  sortBy: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${ABANDONED_CART_CUSTOMER_DETAILS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadMarkAbandonedCartSalesDetails = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  page: number;
  pageSize: number;
  text: string;
  type: string;
  sortOrder: string;
  sortBy: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${ABANDONED_CART_SALES_DETAILS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
