import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import useStyles from './styles'

const NotFound = (): JSX.Element => {
  const classes = useStyles()
  const history = useHistory()

  const redirect = () => {
    history.push('/')
  }

  return (
    <div className={classes.container}>
      <div>Oops, Page not found</div>
      <p>
        We are very sorry for the inconvenience.
        <br />
        It looks like you are trying to access a page that has been
        deleted or never even existed.
      </p>
      <Button
        id="homepageLink"
        name="homepageLink"
        color="primary"
        variant="contained"
        onClick={redirect}
      >
        Back to Homepage
      </Button>
    </div>
  )
}

export default NotFound
