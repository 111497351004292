import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import useStyles from './styles'

interface Props {
  onClose: () => void
  severity: 'success' | 'info' | 'warning' | 'error' | undefined
  id: string
}

const Alert: React.FC<Props> = ({ id, ...rest }: Props) => {
  const classes = useStyles()
  return (
    <MuiAlert
      id={id}
      elevation={6}
      variant="filled"
      {...rest}
      className={classes.alert}
    />
  )
}

export default Alert
