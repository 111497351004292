import { OverviewActionTypes } from './types/actions'
import { OverviewState } from './types/reducer'
import {
  LOAD_PRODUCT_CATEGORY_OPTIONS,
  LOAD_PRODUCT_CATEGORY_OPTIONS_ERROR,
  LOAD_PRODUCT_CATEGORY_OPTIONS_SUCCESS,
  LOAD_PRODUCT_SUB_CATEGORY_OPTIONS,
  LOAD_PRODUCT_SUB_CATEGORY_OPTIONS_ERROR,
  LOAD_PRODUCT_SUB_CATEGORY_OPTIONS_SUCCESS,
  LOAD_TOP_PERFORMANCE_DATA,
  LOAD_TOP_PERFORMANCE_DATA_ERROR,
  LOAD_TOP_PERFORMANCE_DATA_SUCCESS,
  LOAD_TOP_PRODUCT_LIST,
  LOAD_TOP_PRODUCT_LIST_ERROR,
  LOAD_TOP_PRODUCT_LIST_SUCCESS,
  PRODUCT_OVERVIEW_CLEAN_UP,
} from './constants'

export const initialState: OverviewState = {
  isLoading: false,
  success: false,
  data: '',
  catOptionsLoading: false,
  catOptionsSuccess: true,
  catSubOptionsLoading: false,
  catSubOptionsSuccess: true,
  topProductsLoading: false,
  topProductsSuccess: true,
  topPerformanceLoading: false,
  topPerformanceSuccess: true,
  productCategoryOptions: [],
  productSubCategoryOptions: [],
  topBrandPerformanceData: [],
  topProductsList: [],
}

const reducer = (
  state: OverviewState = initialState,
  action: OverviewActionTypes,
): OverviewState => {
  switch (action.type) {
    case LOAD_PRODUCT_CATEGORY_OPTIONS:
      return {
        ...state,
        catOptionsLoading: true,
        productCategoryOptions: initialState.productCategoryOptions,
      }
    case LOAD_PRODUCT_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        catOptionsLoading: false,
        catOptionsSuccess: true,
        productCategoryOptions: action.payload.productCategoryOptions,
      }
    case LOAD_PRODUCT_CATEGORY_OPTIONS_ERROR:
      return {
        ...state,
        catOptionsLoading: false,
        catOptionsSuccess: false,
      }
    case LOAD_PRODUCT_SUB_CATEGORY_OPTIONS:
      return {
        ...state,
        catSubOptionsLoading: true,
        productSubCategoryOptions:
          initialState.productSubCategoryOptions,
      }
    case LOAD_PRODUCT_SUB_CATEGORY_OPTIONS_SUCCESS:
      return {
        ...state,
        catSubOptionsLoading: false,
        catSubOptionsSuccess: true,
        productSubCategoryOptions:
          action.payload.productSubCategoryOptions,
      }
    case LOAD_PRODUCT_SUB_CATEGORY_OPTIONS_ERROR:
      return {
        ...state,
        catSubOptionsLoading: false,
        catSubOptionsSuccess: false,
      }
    case LOAD_TOP_PRODUCT_LIST:
      return {
        ...state,
        topProductsLoading: true,
        topProductsList: [],
      }
    case LOAD_TOP_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        topProductsLoading: false,
        topProductsSuccess: true,
        topProductsList: action.payload,
      }
    case LOAD_TOP_PRODUCT_LIST_ERROR:
      return {
        ...state,
        topProductsLoading: false,
        topProductsSuccess: false,
      }
    case LOAD_TOP_PERFORMANCE_DATA:
      return {
        ...state,
        topPerformanceLoading: true,
        topBrandPerformanceData: [],
      }
    case LOAD_TOP_PERFORMANCE_DATA_SUCCESS:
      return {
        ...state,
        topPerformanceLoading: false,
        topPerformanceSuccess: true,
        topBrandPerformanceData: action.payload,
      }
    case LOAD_TOP_PERFORMANCE_DATA_ERROR:
      return {
        ...state,
        topPerformanceLoading: false,
        topPerformanceSuccess: false,
      }
    case PRODUCT_OVERVIEW_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
