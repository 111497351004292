export const LOAD_MARK_EMAILS_FILTERS =
  'APP/MARKETING/EMAILS/LOAD_FILTERS'
export const LOAD_MARK_EMAILS_FILTERS_SUCCESS =
  'APP/MARKETING/EMAILS/LOAD_FILTERS_SUCCESS '
export const LOAD_MARK_EMAILS_FILTERS_ERROR =
  'APP/MARKETING/EMAILS/LOAD_FILTERS_ERROR'
export const LOAD_MARK_COUNTRY_FILTERS =
  'APP/MARKETING/EMAILS/LOAD_COUNTRY_FILTERS'
export const LOAD_MARK_COUNTRY_FILTERS_SUCCESS =
  'APP/MARKETING/EMAILS/LOAD_COUNTRY_FILTERS_SUCCESS '
export const LOAD_MARK_COUNTRY_FILTERS_ERROR =
  'APP/MARKETING/EMAILS/LOAD_COUNTRY_FILTERS_ERROR'
export const LOAD_MARKETING_EMAILS_LIST =
  'APP/MARKETING/EMAILS/LOAD_LIST'
export const LOAD_MARKETING_EMAILS_LIST_SUCCESS =
  'APP/MARKETING/EMAILS/LOAD_LIST_SUCCESS'
export const LOAD_MARKETING_EMAILS_LIST_ERROR =
  'APP/MARKETING/EMAILS/LOAD_LIST_ERROR'
export const LOAD_MARKETING_EMAIL_KPI =
  'APP/MARKETING/EMAILS/LOAD_KPI'
export const LOAD_MARKETING_EMAIL_KPI_SUCCESS =
  'APP/MARKETING/EMAILS/LOAD_KPI_SUCCESS'
export const LOAD_MARKETING_EMAIL_KPI_ERROR =
  'APP/MARKETING/EMAILS/LOAD_KPI_ERROR'
export const MARKETING_EMAIL_CLEAN_UP =
  'APP/MARKETING/EMAILS/CLEAN_UP'
