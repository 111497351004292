import { makeStyles, Theme } from '@material-ui/core'

const drawerWidth = '25rem'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    color: theme.app.colors.sidebarRouteTextBlack,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.app.colors.white,
  },
  appBarShift: {
    display: 'none',
  },
  openButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(10),
    color: theme.app.colors.sidebarRouteTextBlack,
    backgroundColor: theme.app.colors.white,
    '& >button': {
      padding: '1rem',
      borderRadius: 0,
      marginLeft: '1.5rem',
    },
    '&:hover': {
      '& > button': {
        backgroundColor: `${theme.palette.primary.main}22`,
      },
    },
  },
  menuButton: {},
  hide: {
    display: 'none',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: theme.spacing(10.5),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  sidebarContainer: {
    width: drawerWidth,
    height: '100%',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '1rem',
    },

    '&::-webkit-scrollbar-track': {
      background: theme.app.colors.white,
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        background: theme.app.colors.greyScrollColor,
      },
    },
  },
  sidebarFullHeight: {
    height: '100%',
  },
  sidebarList: {
    width: '100%',
    padding: 0,
    margin: '2rem 0',
  },
  listText: {
    textDecoration: 'none',
    color: theme.app.colors.darkGrey,
    background: 'transparent',
    width: '100%',
    margin: '0.2rem 0',
  },
  listItem: {
    width: '84%',
    minHeight: '5.5rem',
    margin: '0 0 0 2rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}22`,
      color: theme.palette.primary.main,
      transition: 'all .2s',
      '& > p': {
        fontWeight: 'bold',
      },
      '& > div': {
        '& > img': {
          opacity: 'unset',
        },
      },
    },
    '&:focus': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}22`,
      transition: 'all .2s',
      '& > p': {
        fontWeight: 'bold',
      },
      '& > div': {
        '& > img': {
          opacity: 'unset',
        },
      },
    },
  },
  logo: {
    height: '10rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 0 0 1.5rem',
    fontWeight: 'bold',
    '& > a': {
      textDecoration: 'none',
      color: theme.app.colors.sidebarRouteTextBlack,
      display: 'flex',
      alignItems: 'center',
      '& > p': {
        color: theme.palette.primary.main,
        textAlign: 'center',
      },
    },
  },
  logoWhite: {
    width: '3rem',
    height: `4rem`,
    margin: '0 2.1rem',
  },
  filters: {
    height: '5rem',
    alignItems: 'center',
    padding: '0 2rem 0 0.5rem',
    borderRadius: 0,
    justifyContent: 'flex-start',
    marginLeft: '2rem',
    width: '84%',
    '& > span >p': {
      fontSize: '1.5rem',
    },
    '&:hover ,&:focus': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}22`,
      '& > span >img': {
        opacity: 'unset',
      },
      '& > span >p': {
        fontWeight: 'bold',
      },
    },
  },
  filtersImg: {
    width: '2.5rem',
    height: '2.5rem',
    margin: '0 2.5rem 0 1rem',
    opacity: '0.5',
  },
  divider: {
    height: '0.2rem',
  },
  btnContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  accordion: {
    width: '92.3%',
    background: theme.app.colors.white,
    color: theme.app.colors.sidebarRouteTextBlack,
    boxShadow: 'none',
    '&::before': {
      position: 'static',
    },
    '&$expanded': {
      margin: 0,
    },
    '&$selectedTab': {
      '& > div > img': {
        opacity: 'unset',
      },
    },
  },
  selectedTab: {
    '& >.MuiAccordionSummary-root.Mui-expanded': {
      backgroundColor: `${theme.palette.primary.main}22`,
    },
  },
  notSelectedTab: {},
  accordionIcon: {
    marginRight: '2.6rem',
    width: '2.2rem',
    height: '2.2rem',
    opacity: '0.7',
  },
  accordionList: {
    width: '100%',
    padding: '0 0 0 2rem',
    display: 'flex',
    flexDirection: 'column',
    '& > a:last-child > div': {
      borderBottomRightRadius: 0,
    },
    '& > a:focus': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}22`,
      color: theme.palette.primary.main,
    },
    '& > a:hover': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}22`,
      color: theme.palette.primary.main,
    },
  },
  expandIcon: {
    fill: theme.app.colors.sidebarRouteTextBlack,
    opacity: '0.7',
  },
  routeName: {
    color: theme.app.colors.sidebarRouteTextBlack,
    textAlign: 'center',
    width: '2.125rem',
    height: '2.125rem',
    lineHeight: '2.125rem',
    position: 'absolute',
    left: '6.55rem',
    top: '50%',
    marginTop: '-1.063rem',
    fontSize: '1.6rem',
  },
  activeListText: {
    fontSize: theme.app.fonts.textLarge,
    fontWeight: 'bold',
    '& >div ': {
      backgroundColor: `${theme.palette.primary.main}22`,
      '& >p': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
    },
  },
  activeListsubText: {
    '& > p': {
      fontWeight: '900',
    },
    '& > div': {
      backgroundColor: `${theme.palette.primary.main}22`,
      color: theme.palette.primary.main,
      '& > p': {
        fontWeight: 'bold',
      },
      '&:hover, &:focus': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        backgroundColor: `${theme.palette.primary.main}22`,
      },
    },
  },
  hamburgeImgButton: {
    padding: '0.8rem',
    backgroundColor: `${theme.palette.primary.main}22`,
  },
  listItemLinks: {
    paddingLeft: '2rem',
    fontSize: theme.app.fonts.textMedium,
  },
  paddingZero: {
    padding: 0,
  },
  summaryRoot: {
    minHeight: '5.5rem',
    marginLeft: '2rem',
    '&$expanded': {
      minHeight: '4rem',
    },
    '&:hover ': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.primary.main}22`,
      '& > div > p': {
        fontWeight: 'bold',
      },
      '& > div': {
        '& > img': {
          opacity: 'unset',
        },
      },
    },
  },
  summaryContent: {
    margin: 0,
    width: '15rem',
    '&$expanded': {
      width: '15rem',
      margin: 0,
      '& > p': {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      },
      '& > img': {
        opacity: 'unset',
      },
    },
  },
  expanded: {},
  leftBorder: {
    display: 'flex',
    width: '100%',
    borderLeft: `0.4rem solid ${`${theme.palette.primary.main}22`}`,
    paddingTop: `0.2rem`,
    marginLeft: '4.5rem',
    // marginRight: '-1rem',
  },
  links: {
    display: 'block',
    color: theme.app.colors.sidebarRouteTextBlack,
    margin: '1rem 0',
    paddingLeft: `0.2rem`,
    '& > p': {
      fontSize: theme.app.fonts.textMedium,
    },
  },
  arrowIconWrapper: {
    margin: '0 0 0 1rem',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    color: theme.app.colors.sidebarRouteTextBlack,
    fontSize: '1.6rem',
    '& > span, & > button': {
      color: theme.app.colors.sidebarRouteTextBlack,
      paddingLeft: `1.2rem`,
      fontSize: theme.app.fonts.textLarge,
    },
    '&:hover ,&:focus': {
      '& >button': {
        cursor: 'pointer',
        opacity: 'unset',
        background: `${theme.palette.primary.main}22`,
        borderRadius: 0,
      },
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  collapseButton: {
    transform: 'rotate(180deg)',
  },
  main: {
    transition: 'all 0.5s ease 0s',
    width: '100%',
    margin: '0 auto',
    color: theme.app.colors.sidebarRouteTextBlack,
  },
  paddingRightToScrollbar: {
    marginRight: '1rem',
  },
  paddingLeft: {
    padding: 0,
    minWidth: 0,
  },
  backToMenuWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '4.8rem',
    width: '20rem',
    margin: '0 4rem 0 2rem',
    '& > span': {
      color: theme.app.colors.sidebarRouteTextBlack,
      fontSize: theme.app.fonts.textMedium,
    },
    '& > button': {
      opacity: '0.7',
    },
    '&:hover, &focus': {
      cursor: 'pointer',
      background: `${theme.palette.primary.main}22`,
      color: theme.palette.primary.main,
      '& > button': {
        opacity: 'unset',
      },
      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
}))

export default useStyles
