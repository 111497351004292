import { MarketingActionTypes } from "./types/actions";
import { MarketingState } from "./types/reducer";
import {
  LOAD_MARK_SOURCES_FILTERS,
  LOAD_MARK_SOURCES_FILTERS_ERROR,
  LOAD_MARK_SOURCES_FILTERS_SUCCESS,
  LOAD_MARK_COUNTRY_FILTERS,
  LOAD_MARK_COUNTRY_FILTERS_ERROR,
  LOAD_MARK_COUNTRY_FILTERS_SUCCESS,
  LOAD_MARKETING_KPI,
  LOAD_MARKETING_KPI_SUCCESS,
  LOAD_MARKETING_KPI_ERROR,
  LOAD_MARK_CHANNEL_GRAPH,
  LOAD_MARK_CHANNEL_GRAPH_ERROR,
  LOAD_MARK_CHANNEL_GRAPH_SUCCESS,
  MARKETING_LIST_CLEAN_UP,
  LOAD_MARK_AD_METRICS,
  LOAD_MARK_AD_METRICS_ERROR,
  LOAD_MARK_AD_METRICS_SUCCESS,
  LOAD_MARK_TBL_DATA,
  LOAD_MARK_TBL_DATA_SUCCESS,
  LOAD_MARK_TBL_DATA_ERROR,
  LOAD_MARK_SOURCES_GRAPH_DATA,
  LOAD_MARK_SOURCES_GRAPH_DATA_SUCCESS,
  LOAD_MARK_SOURCES_GRAPH_DATA_ERROR,
  LOAD_CHART_DATA,
  LOAD_CHART_DATA_SUCCESS,
  LOAD_CHART_DATA_ERROR,
} from "./constants";

export const initialState: MarketingState = {
  filters: [],
  filtersLoading: false,
  filtersSuccess: false,
  isFlowChartLoading: false,
  flowChartSuccess: false,
  flowChartData: {
    email: {
      sources: [],
      conversions: 0,
      revenue: 0,
    },
    paid: {
      sources: [],
      conversions: 0,
      revenue: 0,
    },
    organicMedia: {
      sources: [],
      conversions: 0,
      revenue: 0,
    },
    revenue: 0,
  },
  chartDataLoading: false,
  chartDataSuccess: false,
  chartDataList: [],
  countryFilters: [],
  countryFiltersLoading: false,
  countryFiltersSuccess: false,
  isLoading: false,
  kpiSuccess: false,
  kpiData: {
    visits: 0,
    conversionRate: "",
    totalOrderValue: "",
    conversions: "",
  },
  channelData: {
    description: "",
    graphContent: [],
    dateLabels: [],
    channelCount: [],
    total: "",
  },
  channelSuccess: false,
  channelLoading: false,
  metricsLoading: false,
  metricsSuccess: false,
  metrics: {
    shippingRevenue: "",
    refund: "",
    salesTax: "",
  },
  channelTableData: [],
  channelTblLoading: false,
  channelTblSuccess: false,
  totalElements: 0,
  tableDesc: "",
  totalRow: {
    totalCOGS: "",
    totalAOV: "",
    totalSaleTax: "",
    totalOrders: "",
    totalAdSpend: "",
    totalRevenue: "",
    totalRefunds: "",
  },
};

const reducer = (
  state: MarketingState = initialState,
  action: MarketingActionTypes
): MarketingState => {
  switch (action.type) {
    case LOAD_MARK_SOURCES_FILTERS:
      return {
        ...state,
        filtersLoading: true,
        filters: initialState.filters,
      };
    case LOAD_MARK_SOURCES_FILTERS_SUCCESS:
      return {
        ...state,
        filtersLoading: false,
        filtersSuccess: true,
        filters: action.payload,
      };
    case LOAD_MARK_SOURCES_FILTERS_ERROR:
      return {
        ...state,
        filtersLoading: false,
        filtersSuccess: false,
      };
    case LOAD_MARK_COUNTRY_FILTERS:
      return {
        ...state,
        countryFiltersLoading: true,
        countryFilters: initialState.countryFilters,
      };
    case LOAD_MARK_COUNTRY_FILTERS_SUCCESS:
      return {
        ...state,
        countryFiltersLoading: false,
        countryFiltersSuccess: true,
        countryFilters: action.payload,
      };
    case LOAD_MARK_COUNTRY_FILTERS_ERROR:
      return {
        ...state,
        countryFiltersLoading: false,
        countryFiltersSuccess: false,
      };
    case LOAD_MARKETING_KPI:
      return {
        ...state,
        isLoading: true,
        kpiData: initialState.kpiData,
      };
    case LOAD_MARKETING_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: true,
        kpiData: action.payload,
      };
    case LOAD_MARKETING_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: false,
      };

    case LOAD_MARK_AD_METRICS:
      return {
        ...state,
        metricsLoading: true,
        metrics: initialState.metrics,
      };
    case LOAD_MARK_AD_METRICS_SUCCESS:
      return {
        ...state,
        metricsLoading: false,
        metricsSuccess: true,
        metrics: action.payload,
      };
    case LOAD_MARK_AD_METRICS_ERROR:
      return {
        ...state,
        metricsLoading: false,
        metricsSuccess: false,
      };

    // Marketing Channel Graph
    case LOAD_MARK_CHANNEL_GRAPH:
      return {
        ...state,
        channelLoading: true,
        channelData: initialState.channelData,
      };
    case LOAD_MARK_CHANNEL_GRAPH_SUCCESS:
      return {
        ...state,
        channelLoading: false,
        channelSuccess: true,
        channelData: action.payload,
      };
    case LOAD_MARK_CHANNEL_GRAPH_ERROR:
      return {
        ...state,
        channelLoading: false,
        channelSuccess: false,
      };

    case LOAD_MARK_TBL_DATA:
      return {
        ...state,
        channelTblLoading: true,
        channelTableData: initialState.channelTableData,
        totalRow: initialState.totalRow,
        totalElements: initialState.totalElements,
        tableDesc: initialState.tableDesc,
      };
    case LOAD_MARK_TBL_DATA_SUCCESS:
      return {
        ...state,
        channelTblLoading: false,
        channelTblSuccess: true,
        channelTableData: action.payload.list,
        totalRow: action.payload.totalRow,
        totalElements: action.payload.total,
        tableDesc: action.payload.desc,
      };
    case LOAD_MARK_TBL_DATA_ERROR:
      return {
        ...state,
        channelTblLoading: false,
        channelTblSuccess: false,
      };

    case LOAD_MARK_SOURCES_GRAPH_DATA:
      return {
        ...state,
        isFlowChartLoading: true,
        flowChartSuccess: false,
        flowChartData: initialState.flowChartData,
      };
    case LOAD_MARK_SOURCES_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        isFlowChartLoading: false,
        flowChartSuccess: true,
        flowChartData: action.payload,
      };
    case LOAD_MARK_SOURCES_GRAPH_DATA_ERROR:
      return {
        ...state,
        isFlowChartLoading: false,
        flowChartSuccess: false,
      };

    case LOAD_CHART_DATA:
      return {
        ...state,
        chartDataLoading: true,
        chartDataList: initialState.chartDataList,
      };
    case LOAD_CHART_DATA_SUCCESS:
      return {
        ...state,
        chartDataLoading: false,
        chartDataSuccess: true,
        chartDataList: action.payload,
      };
    case LOAD_CHART_DATA_ERROR:
      return {
        ...state,
        chartDataLoading: false,
        chartDataSuccess: false,
      };
    case MARKETING_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
