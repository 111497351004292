import { userLogout } from "api/loginApi";
import axios from "axios";
import moment from "moment-timezone";

const BASE_URL = process.env.REACT_APP_BASE_BIMART;
const mantixApiInstance = axios.create({
  baseURL: BASE_URL,
  // timeout: 3000,
  // headers: {}
});

/**
 * @method requestHandler
 * @description This method will handle the request
 */
const requestHandler = (request: any) => {
  const rememberMe = localStorage.getItem("rememberMe");
  const authToken =
    rememberMe === "true"
      ? localStorage.getItem("MANTIX_AUTH_TOKEN")
      : sessionStorage.getItem("MANTIX_AUTH_TOKEN");
  if (authToken) request.headers.Authorization = `Bearer ${authToken}`;
  return request;
};

/**
 * @method responseHandler
 * @description This method will handle the response
 */
const responseHandler = (response: any) => {
  if (response.status === 401) {
    // localStorage.clear()
    // ;(<any>(
    //   window
    // )).location = `/signin?redirectUrl=${window.location.pathname}`
  }
  localStorage.setItem(
    "LAST_ACTIVITY",
    moment().utc().format("yyyy-MM-DD hh:mm:ss")
  );

  return response;
};

/**
 * @method errorHandler
 * @description This method will handle the error
 */
const errorHandler = (error: any) => {
  if (error && error.message === "Network Error") {
    return Promise.reject({ message: "NETWORK ERROR" });
  }
  switch (error.response.status) {
    case 401: {
      const rememberMe = localStorage.getItem("rememberMe");

      userLogout(
        rememberMe === "true"
          ? localStorage.getItem("userId")
          : sessionStorage.getItem("userId"),
        true,
        rememberMe === "true"
          ? localStorage.getItem("MANTIX_AUTH_TOKEN")
          : sessionStorage.getItem("MANTIX_AUTH_TOKEN")
      );

      if (rememberMe === "true") localStorage.clear();
      else sessionStorage.clear();

      sessionStorage.setItem("error", "401");
      (<any>(
        window
      )).location = `/signin?redirectUrl=${window.location.pathname}`;
      const err = { message: "" };
      return Promise.reject(err);
    }
    case 400:
    case 409: {
      const err =
        error.response && error.response.data ? error.response.data : null;
      return Promise.reject(err);
    }
    case 502: {
      const err = { message: "" };
      return Promise.reject(err);
    }
    default: {
      return Promise.reject(error);
    }
  }
};

mantixApiInstance.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

mantixApiInstance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default mantixApiInstance;
