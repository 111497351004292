import React, { useEffect, memo } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "store";
import IndexHeader from "containers/Header/Index";
import Loading from "components/common/Loading";
import ErrorBoundary from "containers/ErrorBoundary";
import { getUserAuth } from "./appActions";
import MainComponent from "../containers/Main";

interface Props {
  component: React.ComponentType<RouteProps>;
  path: string;
}

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  path,
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const { isLoggedIn, isAuthenticating } = useSelector(
    (state: RootStore) => state.userAuth
  );
  const { userId, isDashboardAccess } = useSelector((state: RootStore) => {
    const { userId, tenantId } = state.userAuth.loginData;
    return {
      userId,
      isDashboardAccess: tenantId || "",
    };
  });

  useEffect(() => {});
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    dispatch(getUserAuth());
  }, [dispatch]);

  return (
    <>
      {!isAuthenticating ? (
        <Route
          path={path}
          {...rest}
          render={(props) => {
            if (!isLoggedIn || !userId) {
              return (
                <Redirect
                  to={{
                    pathname: "/signin",
                    search:
                      params && params.redirectUrl
                        ? `?redirectUrl=${params.redirectUrl}`
                        : "",
                  }}
                />
              );
            }
            if (
              path === "/select-store" ||
              path === "/add-store" ||
              path === "/change-password"
            ) {
              return (
                <>
                  <ErrorBoundary>
                    <IndexHeader isLoggedIn={isLoggedIn} {...props} />
                  </ErrorBoundary>
                  <ErrorBoundary>
                    <Component {...props} />
                  </ErrorBoundary>
                </>
              );
            }
            if (isDashboardAccess)
              return (
                <MainComponent {...props}>
                  <ErrorBoundary>
                    <Component {...props} />
                  </ErrorBoundary>
                </MainComponent>
              );
            return <Redirect to={{ pathname: "/signin" }} />;
          }}
        />
      ) : (
        <Loading id="mainPage" />
      )}
    </>
  );
};

export default memo(PrivateRoute);
