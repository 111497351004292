import { CustomerLTVActionTypes } from "./types/actions";
import { CustomerLTVState } from "./types/reducer";
import {
  LOAD_CUSTOMER_LTV,
  LOAD_CUSTOMER_LTV_SUCCESS,
  LOAD_CUSTOMER_LTV_ERROR,
  LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN,
  LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN_SUCCESS,
  LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN_ERROR,
  CUSTOMER_LTV_CLEAN_UP,
  LOAD_NEW_CUSTOMER_CLTV_DATA_SUCCESS,
  LOAD_NEW_CUSTOMER_CLTV_DATA,
  LOAD_NEW_CUSTOMER_CLTV_DATA_ERROR,
} from "./constants";

export const initialState: CustomerLTVState = {
  isLoading: false,
  success: false,
  segmentsFilters: [],
  segmentsFiltersLoading: false,
  segmentsFiltersSuccess: false,
  donutData: [],
  barData: [],
  description: { title: "", details: "" },
  dateLables: [],
  newCustomerCLTVDataLoading: false,
  newCustomerCLTVData: undefined,
  newCustomerCLTVDataSuccess: false,
};

const reducer = (
  state: CustomerLTVState = initialState,
  action: CustomerLTVActionTypes
): CustomerLTVState => {
  switch (action.type) {
    case LOAD_CUSTOMER_LTV:
      return {
        ...state,
        isLoading: true,
        donutData: initialState.donutData,
        barData: initialState.barData,
        description: initialState.description,
        dateLables: initialState.dateLables,
      };
    case LOAD_CUSTOMER_LTV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        donutData: action.payload.donutData,
        barData: action.payload.barData,
        description: action.payload.description,
        dateLables: action.payload.dateLables,
      };
    case LOAD_CUSTOMER_LTV_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };

    case LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN:
      return {
        ...state,
        segmentsFiltersLoading: true,
        segmentsFilters: initialState.segmentsFilters,
      };
    case LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN_SUCCESS:
      return {
        ...state,
        segmentsFiltersLoading: false,
        segmentsFiltersSuccess: true,
        segmentsFilters: action.payload,
      };
    case LOAD_CUSTOMER_LTV_SEGMENTS_DROPDOWN_ERROR:
      return {
        ...state,
        segmentsFiltersLoading: false,
        segmentsFiltersSuccess: false,
      };
    case CUSTOMER_LTV_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };
    case LOAD_NEW_CUSTOMER_CLTV_DATA:
      return {
        ...state,
        newCustomerCLTVDataLoading: true,
        dateLables: initialState.dateLables,
        newCustomerCLTVData: initialState.newCustomerCLTVData,
      };
    case LOAD_NEW_CUSTOMER_CLTV_DATA_SUCCESS:
      return {
        ...state,
        dateLables: action.payload.dateLables,
        newCustomerCLTVData: action.payload.graphData,
        newCustomerCLTVDataLoading: false,
        newCustomerCLTVDataSuccess: true,
      };
    case LOAD_NEW_CUSTOMER_CLTV_DATA_ERROR:
      return {
        ...state,
        newCustomerCLTVDataLoading: false,
        newCustomerCLTVDataSuccess: false,
      };
    default:
      return state;
  }
};

export default reducer;
