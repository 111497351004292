export const LOAD_ALL_STORES_ROLES =
  'APP/TEAM_MEMBERS/LOAD_ALL_STORES_ROLES'
export const LOAD_ALL_STORES_ROLES_SUCCESS =
  'APP/TEAM_MEMBERS/LOAD_ALL_STORES_ROLES_SUCCESS'
export const LOAD_ALL_STORES_ROLES_ERROR =
  'APP/TEAM_MEMBERS/LOAD_ALL_STORES_ROLES_ERROR'
export const LOAD_USERS_LIST = 'APP/TEAM_MEMBERS/LOAD_USERS_LIST'
export const LOAD_USERS_LIST_SUCCESS =
  'APP/TEAM_MEMBERS/LOAD_USERS_LIST_SUCCESS'
export const LOAD_USERS_LIST_ERROR =
  'APP/TEAM_MEMBERS/LOAD_ALL_STORES_ROLES_ERROR'

export const ADD_TEAM_MEMBER = 'APP/TEAM_MEMBERS/ADD'
export const ADD_TEAM_MEMBER_SUCCESS = 'APP/TEAM_MEMBERS/ADD_SUCCESS'
export const ADD_TEAM_MEMBER_ERROR = 'APP/TEAM_MEMBERS/ADD_ERROR'

export const TEAM_MEMBERS_CLEAN_UP = 'APP/TEAM_MEMBERS/CLEAN_UP'

export const DELETE_MEMBER = 'APP/TEAM_MEMBERS/DELETE'
export const DELETE_MEMBER_SUCCESS = 'APP/TEAM_MEMBERS/DELETE_SUCCESS'
export const DELETE_MEMBER_ERROR = 'APP/TEAM_MEMBERS/DELETE_ERROR'

export const UPDATE_MEMBER = 'APP/TEAM_MEMBERS/UPDATE'
export const UPDATE_MEMBER_SUCCESS = 'APP/TEAM_MEMBERS/UPDATE_SUCCESS'
export const UPDATE_MEMBER_ERROR = 'APP/TEAM_MEMBERS/UPDATE_ERROR'

export const GET_USER_ACTIVITY_TIMELINE = 'APP/USER/ACTIVITY_TIMELINE'
export const GET_USER_ACTIVITY_TIMELINE_SUCCESS =
  'APP/USER/ACTIVITY_TIMELINE_SUCCESS'
export const GET_USER_ACTIVITY_TIMELINE_ERROR =
  'APP/USER/ACTIVITY_TIMELINE_ERROR'
