import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-45%, -50%)',
    textAlign: 'center',
    '& > div': {
      fontSize: theme.app.fonts.bigNumbers,
      margin: '2rem 0',
    },
    '& > p': {
      fontSize: theme.app.fonts.textMedium,
      margin: '2rem 0',
    },
    '& > button': {
      margin: '2rem 0',
      borderRadius: 0,
    },
  },
}))

export default useStyles
