import { Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { SET_ERROR } from "containers/Errors/constants";
import { ErrorActionTypes } from "containers/Errors/types/actions";
import { userLogout } from "api/loginApi";
import { SignOutActionTypes } from "./types/actions";
import {
  USER_SIGN_OUT,
  USER_SIGN_OUT_SUCCESS,
  USER_SIGN_OUT_ERROR,
  SIGN_OUT_CLEAN_UP,
} from "./constants";

const userSignout: SignOutActionTypes = {
  type: USER_SIGN_OUT,
};

const userSignOutSuccess: SignOutActionTypes = {
  type: USER_SIGN_OUT_SUCCESS,
};

const userSignOutError = (): SignOutActionTypes => ({
  type: USER_SIGN_OUT_ERROR,
});

export const userSignOut = (
  userId: string | null,
  isAutoLogout: boolean,
  token: string | null,
  lastTime?: string | undefined
): ThunkAction<Promise<void>, null, null, SignOutActionTypes> => async (
  dispatch: Dispatch<SignOutActionTypes | ErrorActionTypes>
): Promise<void> => {
  try {
    userLogout(userId, isAutoLogout, token, lastTime);
    dispatch(userSignout);
    const rememberMe = localStorage.getItem("rememberMe");

    if (rememberMe === "true") localStorage.clear();
    else sessionStorage.clear();

    dispatch(userSignOutSuccess);
  } catch (error) {
    const err = error && error.message ? error.message : error || "";
    dispatch(userSignOutError());
    dispatch({ type: SET_ERROR, payload: err });
  }
};

export const userSignOutCleanup = (): SignOutActionTypes => ({
  type: SIGN_OUT_CLEAN_UP,
});
