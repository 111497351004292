/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Kibo } from 'containers/Stores/PlatformComponent/Ecommerce/Kibo/types/reducer'
import { WooCommerce } from 'containers/Stores/PlatformComponent/Ecommerce/Woocommerce/types/reducer'
import { SuiteCRM } from 'containers/Stores/PlatformComponent/CRM/SuiteCRM/types/reducer'
import { CampaignMonitor } from 'containers/Stores/PlatformComponent/Email/CampaignMonitor/types/reducer'
import { GoogleAnalytics } from 'containers/Stores/PlatformComponent/Analytics/GoogleAnalytics/types/reducer'
import { GoogleAds } from 'containers/Stores/PlatformComponent/Analytics/GoogleAds/types/reducer'
import { Magento } from 'containers/Stores/PlatformComponent/Ecommerce/Magento/types/reducer'
import { Shopify } from 'containers/Stores/PlatformComponent/Ecommerce/Shopify/types/reducer'
import { Mailchimp } from 'containers/Stores/PlatformComponent/Email/Mailchimp/types/reducer'
import { Zendesk } from 'containers/Stores/PlatformComponent/CustomerService/Zendesk/types/reducer'

const GOOGLE_ADS_API_URL = process.env.REACT_APP_GOOGLE_ADS_API_URL
const KIBO_API_URL = process.env.REACT_APP_KIBO_API_BASE_URL
const WOOCOMMERCE_API_URL =
  process.env.REACT_APP_WOOCOMMERCE_API_BASE_URL
const SUITECRM_API_URL = process.env.REACT_APP_SUITECRM_API_URL
const CAMPAIGN_MONITOR_API_URL =
  process.env.REACT_APP_CAMPAIGN_MONITOR_API_URL
const GOOGLE_ANALYTICS_API_URL =
  process.env.REACT_APP_GOOGLE_ANALYTICS_API_URL
const MAGENTO_API_URL = process.env.REACT_APP_MAGENTO_API_URL
const SHOPIFY_API_URL = process.env.REACT_APP_SHOPIFY_API_URL
const MAILCHIMP_API_URL = process.env.REACT_APP_MAILCHIMP_API_URL
const ZENDESK_API_URL = process.env.REACT_APP_ZENDESK_API_URL

export const saveKiboConnector = (
  kibo: Kibo,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'kibo-connector',
    config: {
      'connector.class': 'com.ign.cdp.KiboConnector',
      'tasks.max': '1',
      'application.id': kibo.appKey,
      'shared.secret': kibo.sharedSecret,
      'site.id': kibo.siteId,
      'tenant.id': kibo.tenantId,
      name: 'kibo-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: KIBO_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveMagentoConnector = (
  magento: Magento,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'magento-connector',
    config: {
      'connector.class': 'com.ign.cdp.MagentoConnector',
      'tasks.max': '1',
      username: magento.username,
      password: magento.password,
      url: magento.url,
      name: 'magento-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: MAGENTO_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveShopifyConnector = (
  shopify: Shopify,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'shopify-connector',
    config: {
      'connector.class': 'com.ign.cdp.ShopifyConnector',
      'tasks.max': '1',
      'api.key': shopify.apiKey,
      password: shopify.password,
      store: shopify.store,
      name: 'shopify-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: SHOPIFY_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveWooCommerceConnector = (
  woocommerce: WooCommerce,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'woocommerce-connector',
    config: {
      'connector.class': 'com.ign.cdp.WooCommerceConnector',
      'tasks.max': '1',
      consumerKey: woocommerce.consumerKey,
      consumerSecret: woocommerce.consumerSecret,
      url: woocommerce.wordPressURL,
      version: woocommerce.version.value,
      name: 'woocommerce-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: WOOCOMMERCE_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveSuiteCRMConnector = (
  suiteCRM: SuiteCRM,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'suitecrm-connector',
    config: {
      'connector.class': 'com.ign.cdp.SuiteCRMConnector',
      'tasks.max': '1',
      url: suiteCRM.url,
      username: suiteCRM.username,
      password: suiteCRM.password,
      name: 'suitecrm-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: SUITECRM_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveMailchimpConnector = (
  mailchimp: Mailchimp,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'mailchimp-connector',
    config: {
      'connector.class': 'com.ign.cdp.MailchimpConnector',
      'tasks.max': '1',
      server: mailchimp.serverName,
      username: mailchimp.username,
      'api.key': mailchimp.apiKey,
      name: 'mailchimp-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: MAILCHIMP_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveCampaignMonitorConnector = (
  campaignMonitor: CampaignMonitor,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'campaign-monitor-connector',
    config: {
      'connector.class': 'com.ign.cdp.CampaignMonitorConnector',
      'tasks.max': '1',
      'api.key': campaignMonitor.apiKey,
      name: 'campaign-monitor-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: CAMPAIGN_MONITOR_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveGoogleAnalyticsConnector = (
  googleAnalytics: GoogleAnalytics,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'google-analytics-connector',
    config: {
      'connector.class': 'com.ign.cdp.GoogleAnalyticsConnector',
      'tasks.max': '1',
      'view.id': googleAnalytics.viewId,
      name: 'google-analytics-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: GOOGLE_ANALYTICS_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveZendeskConnector = (
  zendesk: Zendesk,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'zendesk-connector',
    config: {
      'connector.class': 'com.ign.cdp.ZendeskConnector',
      'tasks.max': '1',
      subdomain: zendesk.subDomain,
      'email.address': zendesk.emailAddress,
      password: zendesk.password,
      name: 'zendesk-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: ZENDESK_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}

export const saveGoogleAdsConnector = (
  googleAds: GoogleAds,
): Promise<AxiosResponse<any>> => {
  const data = {
    name: 'google-ads-connector',
    config: {
      'connector.class': 'com.ign.cdp.GoogleAdsConnector',
      'tasks.max': '1',
      'client.customer.id': googleAds.clientCustomerId,
      'developer.token': googleAds.developerToken,
      'client.id': googleAds.clientId,
      'client.secret': googleAds.clientSecret,
      name: 'google-ads-connector',
    },
  }

  const config: AxiosRequestConfig = {
    url: GOOGLE_ADS_API_URL,
    method: 'post',
    data,
  }
  return axios.request(config)
}
