import { ProductListActionTypes } from "./types/actions";
import { ProductListState } from "./types/reducer";
import {
  LOAD_PRODUCT_LIST,
  LOAD_PRODUCT_LIST_SUCCESS,
  LOAD_PRODUCT_LIST_ERROR,
  LOAD_PRODUCT_STATUS_OPTIONS,
  LOAD_PRODUCT_STATUS_OPTIONS_ERROR,
  LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS,
  PRODUCT_LIST_CLEAN_UP,
  LOAD_DB_PRODUCTS_FOR_EXPORT_SUCCESS,
  LOAD_DB_PRODUCTS_FOR_EXPORT,
  LOAD_DB_PRODUCTS_FOR_EXPORT_ERROR,
} from './constants'

export const initialState: ProductListState = {
  isLoading: false,
  productSegmentOptions: [],
  searchByOptions: [],
  success: false,
  listLoading: false,
  productListSuccess: false,
  productList: [],
  totalElements: 0,
  totalRow: {
    totalAmountRefunded: "",
    totalOrders: "",
    totalQtyRefunded: "",
    totalQtySold: "",
    totalRevenue: "",
  },
  exportDBProduct: [],
  exportListLoading: false,
  exportTotalRows: undefined
}

const reducer = (
  state: ProductListState = initialState,
  action: ProductListActionTypes
): ProductListState => {
  switch (action.type) {
    case LOAD_PRODUCT_STATUS_OPTIONS:
      return {
        ...state,
        isLoading: true,
        searchByOptions: initialState.searchByOptions,
        productSegmentOptions: initialState.productSegmentOptions,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        searchByOptions: action.payload.searchByOptions,
        productSegmentOptions: action.payload.productSegmentOptions,
      };
    case LOAD_PRODUCT_STATUS_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      };

    case LOAD_PRODUCT_LIST:
      return {
        ...state,
        listLoading: true,
        productList: initialState.productList,
        totalElements: initialState.totalElements,
        totalRow: initialState.totalRow,
      };
    case LOAD_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        listLoading: false,
        productListSuccess: true,
        productList: action.payload.productList,
        totalElements: action.payload.totalElements || 0,
        totalRow: action.payload.totalRow,
      };
    case LOAD_PRODUCT_LIST_ERROR:
      return {
        ...state,
        listLoading: false,
        productListSuccess: false,
      };

    case PRODUCT_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }
    case LOAD_DB_PRODUCTS_FOR_EXPORT:
      return {
        ...state,
        exportListLoading: true,
      }
    case LOAD_DB_PRODUCTS_FOR_EXPORT_SUCCESS:
      return {
        ...state,
        exportDBProduct: action.payload.list,
        exportTotalRows: action.payload.totalRowValues,
        exportListLoading: false,
        isLoading: false,
      }
    case LOAD_DB_PRODUCTS_FOR_EXPORT_ERROR:
      return {
        ...state,
        exportListLoading: false,
      }
    default:
      return state;
  }
};

export default reducer;
