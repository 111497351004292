export const LOAD_SEGMENT = 'APP/PRODUCT/SEGMENTS/LOAD_SEGMENT'
export const LOAD_SEGMENT_SUCCESS =
  'APP/PRODUCT/SEGMENTS/LOAD_SEGMENT_SUCCESS'
export const LOAD_SEGMENT_ERROR =
  'APP/PRODUCT/SEGMENTS/LOAD_SEGMENT_ERROR'
export const LOAD_PRODUCT_STATUS_OPTIONS =
  'APP/PRODUCT/SEGMENTS/LOAD_STATUS_OPTIONS'
export const LOAD_PRODUCT_STATUS_OPTIONS_SUCCESS =
  'APP/PRODUCT/SEGMENTS/LOAD_STATUS_OPTIONS_SUCCESS'
export const LOAD_PRODUCT_STATUS_OPTIONS_ERROR =
  'APP/PRODUCT/SEGMENTS/LOAD_STATUS_OPTIONS_ERROR'

export const SEGMENT_CLEAN_UP = 'APP/PRODUCT/SEGMENTS/CLEAN_UP'
