/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { lazy, Suspense, ComponentType } from 'react'
import Loading from 'components/common/Loading'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Loadable = <T extends ComponentType<any>>(
  importFunc: () => Promise<{ default: T }>,
) => {
  const LazyComponent = lazy(importFunc)

  return (props: React.ComponentProps<T>): JSX.Element => (
    <Suspense fallback={<Loading id="fallback" />}>
      <LazyComponent {...props} />
    </Suspense>
  )
}

export default Loadable
