import { ShippingListActionTypes } from './types/actions'
import { ShippingListState } from './types/reducer'
import {
  LOAD_SHIPPING_LIST,
  LOAD_SHIPPING_LIST_SUCCESS,
  LOAD_SHIPPING_LIST_ERROR,
  LOAD_SHIPPING_KPI,
  LOAD_SHIPPING_KPI_SUCCESS,
  LOAD_SHIPPING_KPI_ERROR,
  LOAD_SHIPPING_OPTIONS,
  LOAD_SHIPPING_OPTIONS_ERROR,
  LOAD_SHIPPING_OPTIONS_SUCCESS,
  SHIPPING_LIST_CLEAN_UP,
} from './constants'

export const initialState: ShippingListState = {
  isLoading: false,
  shippingListSuccess: false,
  kpiSuccess: false,
  shippingList: [],
  graphOrListLoading: false,
  shippingGraphData: {
    carrier: '',
    content: [],
    dateLabels: [],
    carriersOrderShippedData: [],
  },
  kpiData: {
    shippingCost: '',
    shippingPaid: '',
    orderShipped: 0,
    totalUnits: 0,
    shippingMargin: '',
    avgCostPerOrder: '',
  },
  totalElements: 0,
  totalRow: {
    totalOrderShipped: '',
    totalUnitsSold: '',
    totalShippingPaid: '',
    totalShippingCost: '',
    totalShippingMargin: '',
    totalAvgCostPerOrder: '',
  },
  shippingOptions: [],
  shippingOptionsSuccess: false,
}

const reducer = (
  state: ShippingListState = initialState,
  action: ShippingListActionTypes,
): ShippingListState => {
  switch (action.type) {
    case LOAD_SHIPPING_KPI:
      return {
        ...state,
        isLoading: true,
        kpiData: initialState.kpiData,
      }
    case LOAD_SHIPPING_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: true,
        kpiData: action.payload,
      }
    case LOAD_SHIPPING_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        kpiSuccess: false,
      }

    // Shipping Options
    case LOAD_SHIPPING_OPTIONS:
      return {
        ...state,
        isLoading: true,
        shippingOptions: initialState.shippingOptions,
      }
    case LOAD_SHIPPING_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shippingOptionsSuccess: true,
        shippingOptions: action.payload,
      }
    case LOAD_SHIPPING_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        shippingOptionsSuccess: false,
      }

    // Shipping List
    case LOAD_SHIPPING_LIST:
      return {
        ...state,
        graphOrListLoading: true,
        shippingList: initialState.shippingList,
        shippingGraphData: initialState.shippingGraphData,
        totalElements: initialState.totalElements,
        totalRow: initialState.totalRow,
      }
    case LOAD_SHIPPING_LIST_SUCCESS:
      return {
        ...state,
        graphOrListLoading: false,
        shippingListSuccess: true,
        shippingList:
          action.payload.type === 'Shipping Carrier Summary' &&
          action.payload.shippingList
            ? action.payload.shippingList
            : initialState.shippingList,
        shippingGraphData:
          action.payload.type !== 'Shipping Carrier Summary' &&
          action.payload.graphData
            ? action.payload.graphData
            : initialState.shippingGraphData,
        totalElements: action.payload.totalElements || 0,
        totalRow: action.payload.totalRow || initialState.totalRow,
      }
    case LOAD_SHIPPING_LIST_ERROR:
      return {
        ...state,
        graphOrListLoading: false,
        shippingListSuccess: false,
      }

    case SHIPPING_LIST_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
