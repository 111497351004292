import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from 'store'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from 'components/common/Alert'
import { REMOVE_ERROR } from './constants'

const ErrorNotification: FC = () => {
  const dispatch = useDispatch()
  const errorData = useSelector((state: RootStore) => state.error)
  const { isOpen, error, message } = errorData

  function handleClose() {
    dispatch({ type: REMOVE_ERROR })
  }

  return (
    <>
      {error && (
        <Snackbar
          open={isOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            id="errorAlert"
            onClose={handleClose}
            severity="error"
          >
            {error}
          </Alert>
        </Snackbar>
      )}
      {message && (
        <Snackbar
          open={isOpen}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            id="messageAlert"
            onClose={handleClose}
            severity="info"
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}

export default ErrorNotification
