import React, { useCallback, FC, useEffect, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HeaderComponent from 'components/Header/IndexHeader'
import { RootStore } from 'store'
import { useHistory } from 'react-router-dom'
import { userSignOut, userSignOutCleanup } from '../actions'

interface Props {
  isLoggedIn: boolean
}

const Header: FC<Props> = ({ isLoggedIn }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const signOutData = useSelector((state: RootStore) => state.signOut)
  const {data} = useSelector((state: RootStore) => state.userAuth)
  const [autoLogout, setAutoLogout] = useState(false)

  const handleLogout = useCallback(() => {
    dispatch(userSignOut(data.userId, autoLogout, localStorage.getItem('MANTIX_AUTH_TOKEN')))
    history.push({
      pathname: '/signin',
    })
  }, [dispatch])

  useEffect(() => {
    if (signOutData.success) history.push('/signin')
  }, [signOutData.success, history])

  useEffect(() => {
    return () => {
      dispatch(userSignOutCleanup())
    }
  }, [dispatch])

  return (
    <HeaderComponent
      isLoggedIn={isLoggedIn}
      handleLogout={handleLogout}
    />
  )
}

export default memo(Header)
