export const LOAD_DASHBOARD_GRAPH =
  'APP/DASHBOARD/ACCORDIAN_CARD/LOAD_GRAPH'
export const LOAD_DASHBOARD_GRAPH_SUCCESS =
  'APP/DASHBOARD/ACCORDIAN_CARD/LOAD_GRAPH_SUCCESS'
export const LOAD_DASHBOARD_GRAPH_ERROR =
  'APP/DASHBOARD/ACCORDIAN_CARD/LOAD_GRAPH_ERROR'

export const LOAD_DASHBOARD_CHART_OPTIONS =
  'APP/DASHBOARD/ACCORDIAN_CARD/LOAD_CHART_OPTIONS'
export const LOAD_DASHBOARD_CHART_OPTIONS_SUCCESS =
  'APP/DASHBOARD/ACCORDIAN_CARD/LOAD_CHART_OPTIONS_SUCCESS'
export const LOAD_DASHBOARD_CHART_OPTIONS_ERROR =
  'APP/DASHBOARD/ACCORDIAN_CARD/LOAD_CHART_OPTIONS_ERROR'

export const DASHBOARD_ACC_CLEAN_UP =
  'APP/DASHBOARD/ACCORDIAN_CARD/CLEAN_UP'
