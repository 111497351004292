export const LOAD_CUSTOMER_SEGMENTS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_SEGMENTS'
export const LOAD_CUSTOMER_SEGMENTS_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_SEGMENTS_SUCCESS'
export const LOAD_CUSTOMER_SEGMENTS_ERROR =
  'APP/CUSTOMERS/SEGMENTS/LOAD_SEGMENTS_ERROR'

export const LOAD_SEGMENT_OPTIONS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_OPTIONS'
export const LOAD_SEGMENT_OPTIONS_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_OPTIONS_SUCCESS'
export const LOAD_SEGMENT_OPTIONS_ERROR =
  'APP/CUSTOMERS/SEGMENTS/LOAD_OPTIONS_ERROR'

export const CUSTOMER_SEGMENTS_CLEAN_UP =
  'APP/CUSTOMERS/SEGMENTS/CLEAN_UP'

export const LOAD_SEGMENT_GRAPH_DATA =
  'APP/CUSTOMERS/SEGMENTS/LOAD_GRAPH_DATA'
export const LOAD_SEGMENT_GRAPH_DATA_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_GRAPH_DATA_SUCCESS'
export const LOAD_SEGMENT_GRAPH_DATA_ERROR =
  'APP/CUSTOMERS/SEGMENTS/LOAD_GRAPH_DATA_ERROR'

export const LOAD_PRODUCTS_PURCHASED_LIST =
  'APP/CUSTOMERS/SEGMENTS/LOAD_PRODUCTS_PURCHASED_LIST'
export const LOAD_PRODUCTS_PURCHASED_LIST_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_PRODUCTS_PURCHASED_LIST_SUCCESS'
export const LOAD_PRODUCTS_PURCHASED_LIST_ERROR =
  'APP/CUSTOMERS/SEGMENTS/LOAD_PRODUCTS_PURCHASED_LIST_ERROR'

export const LOAD_PERFORMANCE_DATA =
  'APP/CUSTOMERS/SEGMENTS/LOAD_PERFORMANCE_DATA'
export const LOAD_PERFORMANCE_DATA_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_PERFORMANCE_DATA_SUCCESS'
export const LOAD_PERFORMANCE_DATA_ERROR =
  'APP/CUSTOMERS/SEGMENTS/LOAD_PERFORMANCE_DATA_ERROR'
export const DOWNLOAD_SEGMENT_DATA =
  'APP/CUSTOMERS/SEGMENTS/DOWNLOAD_DATA'
export const DOWNLOAD_SEGMENT_DATA_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/DOWNLOAD_DATA_SUCCESS'
export const DOWNLOAD_SEGMENT_DATA_ERROR =
  'APP/CUSTOMERS/SEGMENTS/DOWNLOAD_DATA_ERROR'

export const LOAD_EMAIL_CAMPAIGN =
  'APP/CUSTOMERS/SEGMENTS/LOAD_EMAIL_CAMPAIGN'
export const LOAD_EMAIL_CAMPAIGN_SUCCESS =
  'APP/CUSTOMERS/SEGMENTS/LOAD_EMAIL_CAMPAIGN_SUCCESS'
export const LOAD_EMAIL_CAMPAIGN_ERROR =
  'APP/CUSTOMERS/SEGMENTS/LOAD_EMAIL_CAMPAIGN_ERROR'
