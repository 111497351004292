/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { FiltersState } from 'containers/GlobalFilters/types/reducer'
import mantixApiInstance from '../interceptors/interceptor'

const PAGE = 'sidebar/'
const LOAD_SIDEBAR_LIST_URL =
  process.env.REACT_APP_ACCESS_MENU_PRIVILEGES

const LOAD_GLOBAL_FILTERS_URL =
  process.env.REACT_APP_LOAD_GLOBAL_FILTERS

const APPLY_GLOBAL_FILTERS_URL =
  process.env.REACT_APP_APPLY_GLOBAL_FILTERS

const LOAD_PRODUCT_FILTERS_URL =
  process.env.REACT_APP_LOAD_PRODUCT_FILTERS

export const loadSidebarList = (
  role: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    role,
  }

  const config: AxiosRequestConfig = {
    url: `/${LOAD_SIDEBAR_LIST_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const loadGlobalFilters = (params: {
  tenantId: string
  storeId: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_GLOBAL_FILTERS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const applyGlobalFilters = (
  params: {
    tenantId: string
    storeId: string
    text: string
  },
  filtersState: FiltersState[],
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: APPLY_GLOBAL_FILTERS_URL,
    method: 'post',
    data: filtersState,
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadProductFilters = (params: {
  tenantId: string
  storeId: string
  categories: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PRODUCT_FILTERS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}
