/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */

import { NewSegmentActionTypes } from './types/actions'
import {
  CategoriesTree,
  NewSegmentState,
  ObjToSend,
} from './types/reducer'
import {
  LOAD_ALL_SEGMENTS,
  LOAD_ALL_SEGMENTS_ERROR,
  LOAD_ALL_SEGMENTS_SUCCESS,
  LOAD_ATTRIBUTE_VALUE,
  LOAD_ATTRIBUTE_VALUE_ERROR,
  LOAD_ATTRIBUTE_VALUE_SUCCESS,
  LOAD_ATTRIBUTE_OPTIONS,
  LOAD_ATTRIBUTE_OPTIONS_SUCCESS,
  LOAD_ATTRIBUTE_OPTIONS_ERROR,
  ADD_NEW_SIMPLE_BLOCK,
  ADD_NEW_SIMPLE_BLOCK_SUCCESS,
  ADD_NEW_SIMPLE_BLOCK_ERROR,
  REMOVE_SIMPLE_BLOCK,
  REMOVE_SIMPLE_BLOCK_SUCCESS,
  REMOVE_SIMPLE_BLOCK_ERROR,
  SAVE_NEW_SEGMENT,
  SAVE_NEW_SEGMENT_SUCCESS,
  SAVE_NEW_SEGMENT_ERROR,
  NEW_SEGMENTS_CLEAN_UP,
  LOAD_FIRST_OPTIONS_SUCCESS,
  LOAD_FIRST_OPTIONS_ERROR,
  LOAD_FIRST_OPTIONS,
  LOAD_OPERATOR_OPTIONS,
  LOAD_OPERATOR_OPTIONS_SUCCESS,
  LOAD_OPERATOR_OPTIONS_ERROR,
  PREVIEW_SEGMENT,
  PREVIEW_SEGMENT_ERROR,
  PREVIEW_SEGMENT_SUCCESS,
  LOAD_SEGMENT_KPI_OPTIONS,
  LOAD_SEGMENT_KPI_OPTIONS_SUCCESS,
  LOAD_SEGMENT_KPI_OPTIONS_ERROR,
  VALIDATE_SEGMENT,
  VALIDATE_SEGMENT_SUCCESS,
  VALIDATE_SEGMENT_ERROR,
  DELETE_SEGMENT,
  DELETE_SEGMENT_ERROR,
  DELETE_SEGMENT_SUCCESS,
  EDIT_SEGMENT,
  EDIT_SEGMENT_ERROR,
  EDIT_SEGMENT_SUCCESS,
  CLEAR_BLOCKS,
  CLEAR_BLOCKS_SUCCESS,
  CLEAR_BLOCKS_ERROR,
  VALIDATE_EDIT_COPY,
  VALIDATE_EDIT_COPY_SUCCESS,
  VALIDATE_EDIT_COPY_ERROR,
  SCORE_PREDICTION,
  SCORE_PREDICTION_ERROR,
  SCORE_PREDICTION_SUCCESS,
} from './constants'

export const initialState: NewSegmentState = {
  isLoading: false,
  updateAttrSuccess: false,
  updateOptionsSuccess: false,
  firstOptionsLoading: false,
  firstOptionsSuccess: false,
  firstOptions: [],
  addNewBlockSuccess: false,
  removeBlockSuccess: false,
  isSaveLoading: false,
  saveSuccess: false,
  arrToSend: [],
  isPreviewLoading: false,
  previewSuccess: false,
  customers: [],
  kpiLoading: false,
  kpiSuccess: false,
  validating: false,
  validateSuccess: false,
  totalCustomers: 0,
  segments: [],
  segmentsLoading: false,
  segmentsSuccess: false,
  deleteSuccess: false,
  deleting: false,
  previewCustomers: [],
  editsegmentLoading: false,
  editSegmentSuccess: false,
  editError: false,
  validateEditCopySuccess: false,
  warningError: false,
  warningMessage: '',
  segmentData: {
    to: '',
    from: '',
    filterOptions: [],
    fixedSegment: false,
  },
  clearBlockLoading: false,
  clearBlockSuccess: false,
  scoreLoading: false,
  scoreSuccess: false,
  score: 0,
}

const addEditBlock = (
  arr: ObjToSend[],
  nextId: number,
  firstOptions: string[],
) => {
  arr.push({
    firstValue: '',
    secondValue: '',
    operatorValues: '',
    operatorDataValues: '',
    selectedCondition: '',
    firstOptions,
    secondOptions: [],
    operatorOptions: [],
    operatorDataOptions: [],
    isTreeData: false,
    kpiOptions: [],
    kpi: '',
    id: nextId,
  })
  return [...arr]
}

const updateValue = (
  id: number,
  attribute: string,
  value: string | CategoriesTree[],
  code: string,
  isTreeData: boolean,
  optionsArray: ObjToSend[],
  firstOptions: string[],
) => {
  let clonedArray = optionsArray.map((a) => ({ ...a }))

  const isFilterOption = clonedArray.find((item) => item.id === id)

  if (!isFilterOption) {
    clonedArray = addEditBlock(optionsArray, id, firstOptions)
    // return [...updated]
  }
  clonedArray.map((item: ObjToSend) => {
    if (item.id === id) {
      item.operatorDataId = code || ''
      if (attribute === 'operatorDataValues' && isTreeData) {
        item.isTreeData = isTreeData
        item.operatorDataOptions = value as CategoriesTree[]
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(item as any)[attribute] = value as string
      }
      if (attribute === 'firstValue') {
        item.secondOptions = []
        item.secondValue = ''
        item.operatorOptions = []
        item.operatorValues = ''
        item.operatorDataId = ''
        item.operatorDataOptions = []
        item.operatorDataValues = ''
      } else if (attribute === 'secondValue') {
        item.operatorOptions = []
        item.operatorValues = ''
        item.operatorDataId = ''
        item.operatorDataOptions = []
        item.operatorDataValues = ''
        item.kpi = ''
      }
    }
    return item
  })
  return [...clonedArray]
}

const updateOptions = (
  list: string[],
  id: number,
  attribute: string,
  optionsArray: ObjToSend[],
) => {
  const clonedArray = optionsArray.map((a) => ({ ...a }))
  clonedArray.map((item) => {
    if (item.id === id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(item as any)[attribute] = list
    }
    return item
  })
  return [...clonedArray]
}

const updateKPIOptions = (
  kpiOptions: string[],
  id: number,
  optionsArray: ObjToSend[],
) => {
  const clonedArray = optionsArray.map((a) => ({ ...a }))
  clonedArray.map((item) => {
    if (item.id === id) {
      item.kpiOptions = kpiOptions
    }
    return item
  })
  return [...clonedArray]
}

const updateOperatorOptions = (
  id: number,
  operatorOptions: string[],
  operatorDataOptions: string[],
  isTreeData: boolean,
  optionsArray: ObjToSend[],
) => {
  const clonedArray = optionsArray.map((a) => ({ ...a }))
  clonedArray.map((item) => {
    if (item.id === id) {
      item.operatorOptions = operatorOptions
      item.operatorDataOptions = operatorDataOptions
      item.isTreeData = isTreeData
    }
    return item
  })
  return [...clonedArray]
}

const addNewBlock = (arr: ObjToSend[], firstOptions: string[]) => {
  let maxId = 0
  if (arr.length > 0) {
    maxId = Math.max(
      ...arr.map((x) => {
        return x.id
      }),
    )
  }
  arr.push({
    firstValue: '',
    secondValue: '',
    operatorValues: '',
    operatorDataValues: '',
    selectedCondition: maxId === 0 ? '' : 'And',
    firstOptions,
    secondOptions: [],
    operatorOptions: [],
    operatorDataOptions: [],
    isTreeData: false,
    kpiOptions: [],
    kpi: '',
    id: maxId + 1,
  })
  return [...arr]
}

const removeBlock = (tempArr: ObjToSend[], id: number) => {
  const index = tempArr.findIndex((item) => item.id === id)

  // optionsArray dynamic values start at 1, so index + 1 to slice
  const filteredArrToSend = tempArr
    .slice(0, index)
    .concat(tempArr.slice(index + 1))

  return [...filteredArrToSend]
}

const reducer = (
  state: NewSegmentState = initialState,
  action: NewSegmentActionTypes,
): NewSegmentState => {
  switch (action.type) {
    case LOAD_ALL_SEGMENTS:
      return {
        ...state,
        segmentsLoading: true,
        segmentsSuccess: false,
        segments: initialState.segments,
      }
    case LOAD_ALL_SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentsLoading: false,
        segmentsSuccess: true,
        segments: action.payload,
      }
    case LOAD_ALL_SEGMENTS_ERROR:
      return {
        ...state,
        segmentsLoading: false,
        segmentsSuccess: false,
      }

    case LOAD_ATTRIBUTE_VALUE:
      return {
        ...state,
        isLoading: true,
        updateAttrSuccess: false,
      }
    case LOAD_ATTRIBUTE_VALUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateAttrSuccess: true,
        arrToSend: updateValue(
          action.payload.id,
          action.payload.attribute,
          action.payload.value,
          action.payload.code,
          action.payload.isTreeData,
          [...state.arrToSend],
          state.firstOptions,
        ),
      }

    case LOAD_ATTRIBUTE_VALUE_ERROR:
      return {
        ...state,
        isLoading: false,
        updateAttrSuccess: false,
      }

    case LOAD_ATTRIBUTE_OPTIONS:
      return {
        ...state,
        isLoading: true,
        updateOptionsSuccess: false,
      }
    case LOAD_ATTRIBUTE_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateOptionsSuccess: true,
        arrToSend: updateOptions(
          action.payload.list,
          action.payload.id,
          action.payload.attribute,
          [...state.arrToSend],
        ),
      }

    case LOAD_ATTRIBUTE_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        updateOptionsSuccess: false,
      }

    case LOAD_OPERATOR_OPTIONS:
      return {
        ...state,
        isLoading: true,
        updateOptionsSuccess: false,
      }
    case LOAD_OPERATOR_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateOptionsSuccess: true,
        arrToSend: updateOperatorOptions(
          action.payload.id,
          action.payload.operatorOptions,
          action.payload.operatorDataOptions,
          action.payload.isTreeData,
          [...state.arrToSend],
        ),
      }

    case LOAD_OPERATOR_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        updateOptionsSuccess: false,
      }

    case ADD_NEW_SIMPLE_BLOCK:
      return {
        ...state,
        isLoading: true,
        addNewBlockSuccess: false,
      }
    case ADD_NEW_SIMPLE_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addNewBlockSuccess: true,
        arrToSend: addNewBlock(
          [...state.arrToSend],
          state.firstOptions,
        ),
      }
    case ADD_NEW_SIMPLE_BLOCK_ERROR:
      return {
        ...state,
        isLoading: false,
        addNewBlockSuccess: false,
      }

    case LOAD_FIRST_OPTIONS:
      return {
        ...state,
        firstOptionsLoading: true,
        firstOptions: initialState.firstOptions,
      }
    case LOAD_FIRST_OPTIONS_SUCCESS:
      return {
        ...state,
        firstOptionsLoading: false,
        firstOptionsSuccess: true,
        firstOptions: action.payload,
      }
    case LOAD_FIRST_OPTIONS_ERROR:
      return {
        ...state,
        firstOptionsLoading: false,
        firstOptionsSuccess: false,
      }

    case REMOVE_SIMPLE_BLOCK:
      return {
        ...state,
        isLoading: true,
        removeBlockSuccess: false,
      }
    case REMOVE_SIMPLE_BLOCK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        removeBlockSuccess: true,
        arrToSend: removeBlock([...state.arrToSend], action.payload),
      }
    case REMOVE_SIMPLE_BLOCK_ERROR:
      return {
        ...state,
        isLoading: false,
        removeBlockSuccess: false,
      }

    case VALIDATE_SEGMENT:
      return {
        ...state,
        validating: true,
        validateSuccess: false,
      }
    case VALIDATE_SEGMENT_SUCCESS:
      return {
        ...state,
        validating: false,
        validateSuccess: true,
        customers: action.payload.list,
        totalCustomers: action.payload.total,
      }
    case VALIDATE_SEGMENT_ERROR:
      return {
        ...state,
        validating: false,
        validateSuccess: false,
      }

    case PREVIEW_SEGMENT:
      return {
        ...state,
        isPreviewLoading: true,
        previewSuccess: false,
      }
    case PREVIEW_SEGMENT_SUCCESS:
      return {
        ...state,
        isPreviewLoading: false,
        previewSuccess: true,
        previewCustomers: action.payload,
      }
    case PREVIEW_SEGMENT_ERROR:
      return {
        ...state,
        isPreviewLoading: false,
        previewSuccess: false,
      }

    case SAVE_NEW_SEGMENT:
      return {
        ...state,
        isSaveLoading: true,
        saveSuccess: false,
      }
    case SAVE_NEW_SEGMENT_SUCCESS:
      return {
        ...state,
        isSaveLoading: false,
        saveSuccess: true,
      }
    case SAVE_NEW_SEGMENT_ERROR:
      return {
        ...state,
        isSaveLoading: false,
        saveSuccess: false,
      }

    case DELETE_SEGMENT:
      return {
        ...state,
        deleting: true,
        deleteSuccess: false,
      }
    case DELETE_SEGMENT_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteSuccess: true,
      }
    case DELETE_SEGMENT_ERROR:
      return {
        ...state,
        deleting: false,
        deleteSuccess: false,
      }

    case VALIDATE_EDIT_COPY:
      return {
        ...state,
        validateEditCopySuccess: false,
        warningError: false,
        warningMessage: initialState.warningMessage,
      }
    case VALIDATE_EDIT_COPY_SUCCESS:
      return {
        ...state,
        validateEditCopySuccess: true,
        warningError: false,
      }
    case VALIDATE_EDIT_COPY_ERROR:
      return {
        ...state,
        validateEditCopySuccess: false,
        warningError: true,
        warningMessage: action.payload,
      }

    case EDIT_SEGMENT:
      return {
        ...state,
        editsegmentLoading: true,
        editSegmentSuccess: false,
        editError: false,
      }
    case EDIT_SEGMENT_SUCCESS:
      return {
        ...state,
        editsegmentLoading: false,
        editSegmentSuccess: true,
        segmentData: action.payload,
      }
    case EDIT_SEGMENT_ERROR:
      return {
        ...state,
        editsegmentLoading: false,
        editSegmentSuccess: false,
        editError: true,
      }

    case NEW_SEGMENTS_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    case LOAD_SEGMENT_KPI_OPTIONS:
      return {
        ...state,
        kpiLoading: true,
      }
    case LOAD_SEGMENT_KPI_OPTIONS_SUCCESS:
      return {
        ...state,
        kpiLoading: false,
        kpiSuccess: true,
        arrToSend: updateKPIOptions(
          action.payload.kpiOptions,
          action.payload.id,
          [...state.arrToSend],
        ),
      }
    case LOAD_SEGMENT_KPI_OPTIONS_ERROR:
      return {
        ...state,
        kpiLoading: false,
        kpiSuccess: false,
      }

    case CLEAR_BLOCKS:
      return {
        ...state,
        clearBlockLoading: true,
        clearBlockSuccess: false,
      }
    case CLEAR_BLOCKS_SUCCESS:
      return {
        ...state,
        clearBlockLoading: false,
        clearBlockSuccess: true,
        arrToSend: initialState.arrToSend,
      }
    case CLEAR_BLOCKS_ERROR:
      return {
        ...state,
        clearBlockLoading: false,
        clearBlockSuccess: false,
      }

    case SCORE_PREDICTION:
      return {
        ...state,
        scoreLoading: true,
        scoreSuccess: false,
      }
    case SCORE_PREDICTION_SUCCESS:
      return {
        ...state,
        scoreLoading: false,
        scoreSuccess: true,
        score: action.payload,
      }
    case SCORE_PREDICTION_ERROR:
      return {
        ...state,
        scoreLoading: false,
        scoreSuccess: false,
      }

    default:
      return state
  }
}

export default reducer
