/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import {
  ManageAddressessData,
  MyProfilePersonalData,
} from 'containers/Profile/types/reducer'
import mantixApiInstance from 'interceptors/interceptor'

const REACT_APP_UPDATE_USER_PROFILE_URL =
  process.env.REACT_APP_UPDATE_USER_PROFILE
const REACT_APP_GET_PROFILE_DATA_URL =
  process.env.REACT_APP_GET_PROFILE_DATA

export const getProfileData = (
  userId: string,
  tenantId: string,
  email: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
    tenantId,
    emailAddress: email,
  }

  const config: AxiosRequestConfig = {
    url: `/${REACT_APP_GET_PROFILE_DATA_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const updatePersonalData = (
  formState: MyProfilePersonalData,
  userId: string,
  tenantId: string,
  email: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
    tenantId,
    email,
    firstName: formState.firstName,
    lastName: formState.lastName,
    gender: formState.gender,
  }

  const config: AxiosRequestConfig = {
    // url: REACT_APP_UPDATE_USER_PROFILE_URL,
    url: `/${REACT_APP_UPDATE_USER_PROFILE_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const updateAddressData = (
  formState: ManageAddressessData,
  userId: string,
  tenantId: string,
  email: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
    tenantId,
    email,
    userAddress: formState.address,
    city: formState.city,
    state: formState.state,
    zipcode: formState.zipcode,
  }

  const config: AxiosRequestConfig = {
    // url: REACT_APP_UPDATE_USER_PROFILE_URL,
    url: `/${REACT_APP_UPDATE_USER_PROFILE_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const updatePhoneNumber = (
  phoneNumber: string,
  userId: string,
  tenantId: string,
  email: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
    tenantId,
    email,
    phoneNumber,
  }

  const config: AxiosRequestConfig = {
    // url: REACT_APP_UPDATE_USER_PROFILE_URL,
    url: `/${REACT_APP_UPDATE_USER_PROFILE_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}
