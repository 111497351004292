import React, { useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from 'store'
import Header from 'containers/Header/Index'
import ErrorBoundary from 'containers/ErrorBoundary'
import { getUserAuth } from './appActions'

interface Props {
  component: React.ComponentType<RouteProps>
  path: string
}

const IndexRoute: React.FC<Props> = ({
  component: Component,
  path,
  ...rest
}: Props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state: RootStore) => state.userAuth)
  const selectStoreData = useSelector(
    (state: RootStore) => state.selectStore,
  )
  const signInData = useSelector((state: RootStore) => state.signIn)
  const { isLoggedIn } = auth

  useEffect(() => {
    dispatch(getUserAuth())
  }, [dispatch])
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!isLoggedIn)
          return (
            <>
              <ErrorBoundary>
                <Header isLoggedIn={false} />
              </ErrorBoundary>
              <ErrorBoundary>
                <Component {...props} />
              </ErrorBoundary>
            </>
          )
        if (
          selectStoreData.stores.length > 1 ||
          signInData.data.firstLogin ||
          signInData.changePasswordSuccess
        )
          return <Redirect to={{ pathname: '/select-store' }} />
        return <Redirect to={{ pathname: params && params.redirectUrl ? params.redirectUrl : '/dashboard' }} />
      }}
    />
  )
}

export default IndexRoute
