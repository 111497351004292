export const USER_SIGN_UP_VERIFY = 'APP/SIGN_UP_VERIFY/USER'
export const USER_SIGN_UP_VERIFY_SUCCESS =
  'APP/SIGN_UP_VERIFY/USER_SUCCESS'
export const USER_SIGN_UP_VERIFY_ERROR =
  'APP/SIGN_UP_VERIFY/USER_ERROR'
export const SIGN_UP_VERIFY_CLEAN_UP = 'APP/SIGN_UP_VERIFY/CLEAN_UP'

export const RESEND_CODE = 'APP/SIGN_UP_VERIFY/RESEND_CODE'
export const RESEND_CODE_SUCCESS =
  'APP/SIGN_UP_VERIFY/RESEND_CODE_SUCCESS'
export const RESEND_CODE_ERROR =
  'APP/SIGN_UP_VERIFY/RESEND_CODE_ERROR'
