/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Store } from 'containers/TeamMembers/types/reducer'
import mantixApiInstance from 'interceptors/interceptor'

const ADD_TEAM_MEMBER_URL = process.env.REACT_APP_ADD_TEAM_MEMBER

const LOAD_TEAM_MEMBERS_URL = process.env.REACT_APP_LOAD_TEAM_MEMBERS

const DELETE_TEAM_MEMBER_URL =
  process.env.REACT_APP_DELETE_TEAM_MEMBER

const REACT_APP_USER_ACTIVITY_TIMELINE_URL =
  process.env.REACT_APP_USER_ACTIVITY_TIMELINE

export const loadTeamMembers = (
  tenantId: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    tenantId,
  }

  const config: AxiosRequestConfig = {
    url: `/${LOAD_TEAM_MEMBERS_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const addTeamMember = (data: {
  tenantId: string
  email: string
  roleName: string
  stores: Store[]
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${ADD_TEAM_MEMBER_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const updateTeamMember = (data: {
  tenantId: string
  userId: string
  roleName: string
  stores: Store[]
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${ADD_TEAM_MEMBER_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const deleteTeamMember = (
  userId: string,
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${DELETE_TEAM_MEMBER_URL}`,
    method: 'post',
    data: {
      userId,
    },
  }
  return mantixApiInstance.request(config)
}

export const getUserActivityTimeline = (
  userId: string,
  env: string,
  email: string,
): Promise<AxiosResponse<any>> => {
  const params = {
    userId,
    env,
    email,
  }

  const config: AxiosRequestConfig = {
    url: `/${REACT_APP_USER_ACTIVITY_TIMELINE_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}
