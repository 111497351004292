/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { CustomField } from 'containers/Customers/CreateSegment/Syndication/types/reducer'
import mantixApiInstance from '../interceptors/interceptor'

const PAGE = 'customer/'

const AUDIENCE_LIST_URL = process.env.REACT_APP_AUDIENCE_LIST

const CREATE_SYNDICATION_URL =
  process.env.REACT_APP_CREATE_SYNDICATION

const LOAD_SYNDICATION_LIST_URL =
  process.env.REACT_APP_LOAD_SYNDICATION_LIST

const LOAD_SYNDICATION_TARGET_URL =
  process.env.REACT_APP_SYNDICATION_TARGET

const SYNDICATION_CONSENT_OPTIONS_URL =
  process.env.REACT_APP_SYNDICATION_CONSENT

const DELETE_SYNDICATION_URL =
  process.env.REACT_APP_DELETE_SYNDICATION

const AUTO_UPDATE_SYNDICATION_URL =
  process.env.REACT_APP_AUTO_UPDATE_SYNDICATION

const REACT_APP_LOAD_SYNDICATION_DETAIL_URL =
  process.env.REACT_APP_LOAD_SYNDICATION_DETAIL

export const audienceList = (params: {
  tenantId: string
  storeId: string
  target: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${AUDIENCE_LIST_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const newSyndication = (
  params: {
    tenantId: string | null
    storeId: string | null
    target: string | null
    name: string | null
    description: string | null
    segmentName: string | null
    listName: string | null
    listTitle: string | null
    consentToTrack: string | null
    isEditing: boolean
  },
  customFields: CustomField[],
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${CREATE_SYNDICATION_URL}`,
    method: 'post',
    data: customFields.length > 0 ? customFields : [],
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadSyndications = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  pageSize: number
  page: number
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SYNDICATION_LIST_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const syndicationTargets = (params: {
  tenantId: string
  storeId: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SYNDICATION_TARGET_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const syndicationConsent = (params: {
  tenantId: string
  storeId: string
  target: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${SYNDICATION_CONSENT_OPTIONS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const deleteSyndication = (params: {
  tenantId: string
  storeId: string
  name: string
  role: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${DELETE_SYNDICATION_URL}`,
    method: 'delete',
    params,
  }
  return mantixApiInstance.request(config)
}

export const autoUpdateSyndication = (params: {
  tenantId: string
  storeId: string
  name: string
  value: boolean
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${AUTO_UPDATE_SYNDICATION_URL}`,
    method: 'post',
    data: [],
    params,
  }
  return mantixApiInstance.request(config)
}

export const getSyndicationDetail = (params: {
  tenantId: string
  storeId: string
  name: string
  role: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${REACT_APP_LOAD_SYNDICATION_DETAIL_URL}`,
    method: 'get',
    data: [],
    params,
  }
  return mantixApiInstance.request(config)
}
