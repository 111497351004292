export const LOAD_MARKETING_AD_KPI =
  'APP/MARKETING/ADVERTISING/LOAD_KPI'
export const LOAD_MARKETING_AD_KPI_SUCCESS =
  'APP/MARKETING/ADVERTISING/LOAD_KPI_SUCCESS'
export const LOAD_MARKETING_AD_KPI_ERROR =
  'APP/MARKETING/ADVERTISING/LOAD_KPI_ERROR'
export const LOAD_MARKETING_AD_LIST =
  'APP/MARKETING/ADVERTISING/LOAD_LIST'
export const LOAD_MARKETING_AD_LIST_SUCCESS =
  'APP/MARKETING/ADVERTISING/LOAD_LIST_SUCCESS'
export const LOAD_MARKETING_AD_LIST_ERROR =
  'APP/MARKETING/ADVERTISING/LOAD_LIST_ERROR'
export const LOAD_MARK_AD_FILTERS =
  'APP/MARKETING/ADVERTISING/LOAD_FILTERS'
export const LOAD_MARK_AD_FILTERS_SUCCESS =
  'APP/MARKETING/ADVERTISING/LOAD_FILTERS_SUCCESS '
export const LOAD_MARK_AD_FILTERS_ERROR =
  'APP/MARKETING/ADVERTISING/LOAD_FILTERS_ERROR'
export const LOAD_MARK_COUNTRY_FILTERS =
  'APP/MARKETING/ADVERTISING/LOAD_COUNTRY_FILTERS'
export const LOAD_MARK_COUNTRY_FILTERS_SUCCESS =
  'APP/MARKETING/ADVERTISING/LOAD_COUNTRY_FILTERS_SUCCESS '
export const LOAD_MARK_COUNTRY_FILTERS_ERROR =
  'APP/MARKETING/ADVERTISING/LOAD_COUNTRY_FILTERS_ERROR'
export const LOAD_MARK_CAMPAIGN_CHART =
  'APP/MARKETING/ADVERTISING/LOAD_CAMPAIGN_CHART'
export const LOAD_MARK_CAMPAIGN_CHART_SUCCESS =
  'APP/MARKETING/ADVERTISING/LOAD_CAMPAIGN_CHART_SUCCESS '
export const LOAD_MARK_CAMPAIGN_CHART_ERROR =
  'APP/MARKETING/ADVERTISING/LOAD_CAMPAIGN_CHART_ERROR'

export const MARKETING_AD_CLEAN_UP =
  'APP/MARKETING/ADVERTISING/CLEAN_UP'
