import { KiboActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_KIBO,
  INTEGRATE_KIBO_SUCCESS,
  INTEGRATE_KIBO_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    kibo: {
      appKey: '',
      sharedSecret: '',
      siteId: 0,
      tenantId: 0,
      endpointURL: 'https://endpoint-url.com', // readonly
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: KiboActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_KIBO:
      return { ...state, isLoading: true }
    case INTEGRATE_KIBO_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_KIBO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
