export const USER_SIGN_IN = 'APP/SIGN_IN/USER'
export const USER_SIGN_IN_SUCCESS = 'APP/SIGN_IN/USER_SUCCESS'
export const USER_SIGN_IN_ERROR = 'APP/SIGN_IN/USER_ERROR'

export const SIGN_IN_CLEAN_UP = 'APP/SIGN_IN/CLEAN_UP'

export const UPDATE_PROFILE = 'APP/SIGN_IN/UPDATE_PROFILE'
export const VERIFY_DETAILS = 'APP/SIGN_IN/VERIFY_DETAILS'
export const VERIFY_DETAILS_SUCCESS =
  'APP/SIGN_IN/VERIFY_DETAILS_SUCCESS'
export const VERIFY_DETAILS_ERROR = 'APP/SIGN_IN/VERIFY_DETAILS_ERROR'

export const CHANGE_PASSWORD = 'APP/SIGN_IN/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS =
  'APP/SIGN_IN/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR =
  'APP/SIGN_IN/CHANGE_PASSWORD_ERROR'
