import { GoogleAnalyticsActionTypes } from './types/actions'
import { initState } from './types/reducer'
import {
  INTEGRATE_GOOGLE_ANALYTICS,
  INTEGRATE_GOOGLE_ANALYTICS_SUCCESS,
  INTEGRATE_GOOGLE_ANALYTICS_ERROR,
} from './constants'

export const initialState: initState = {
  isLoading: false,
  error: {
    message: '',
  },
  data: {
    googleAnalytics: {
      viewId: 0,
    },
  },
}

const reducer = (
  state: initState = initialState,
  action: GoogleAnalyticsActionTypes,
): initState => {
  switch (action.type) {
    case INTEGRATE_GOOGLE_ANALYTICS:
      return { ...state, isLoading: true }
    case INTEGRATE_GOOGLE_ANALYTICS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case INTEGRATE_GOOGLE_ANALYTICS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: {
          message: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
