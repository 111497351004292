/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import bcrypt from 'bcryptjs'
import { NewUser } from 'containers/SignUp/types/reducer'
import mantixApiInstance from 'interceptors/interceptor'

const USER_REGISTRATION_URL = process.env.REACT_APP_USER_REGISTRATION

const FORGOT_PASSWORD_VERIFICATION_CODE_URL =
  process.env.REACT_APP_FORGOT_PASSWORD_VERIFICATION_CODE
const FORGOT_PASSWORD_CONFIRM_URL =
  process.env.REACT_APP_FORGOT_PASSWORD_CONFIRM

const VALIDATE_USER_OTP_URL = process.env.REACT_APP_VALIDATE_USER_OTP

const salt = '$2a$12$Ju8y39RAdMpyob7.HyEe/u'

export const registerUser = (
  formState: NewUser,
): Promise<AxiosResponse<any>> => {
  const hashedPassword = bcrypt.hashSync(formState.password, salt)
  const data = {
    firstName: formState.firstName,
    lastName: formState.lastName,
    emailAddress: formState.email,
    phoneNumber: `${formState.code}${formState.phoneNumber}`,
    password: hashedPassword,
  }

  const config: AxiosRequestConfig = {
    url: `/${USER_REGISTRATION_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const forgotPasswordSendVerificationCode = (
  emailAddress: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    emailAddress,
  }

  const config: AxiosRequestConfig = {
    url: `/${FORGOT_PASSWORD_VERIFICATION_CODE_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const forgotPasswordConfirm = (
  emailAddress: string,
  password: string,
  verificationCode: string,
): Promise<AxiosResponse<any>> => {
  const hashedPassword = bcrypt.hashSync(password, salt)
  const data = {
    emailAddress,
    password: hashedPassword,
    verificationCode,
  }

  const config: AxiosRequestConfig = {
    url: `/${FORGOT_PASSWORD_CONFIRM_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const validateOTP = (
  userId: string,
  otp: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
    otp,
  }

  const config: AxiosRequestConfig = {
    url: VALIDATE_USER_OTP_URL,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}
