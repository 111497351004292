export const LOAD_PRODUCT_CATEGORY_OPTIONS =
  'APP/PRODUCT/OVERVIEW/LOAD_CATEGORY_OPTIONS'
export const LOAD_PRODUCT_CATEGORY_OPTIONS_SUCCESS =
  'APP/PRODUCT/OVERVIEW/LOAD_CATEGORY_OPTIONS_SUCCESS'
export const LOAD_PRODUCT_CATEGORY_OPTIONS_ERROR =
  'APP/PRODUCT/OVERVIEW/LOAD_CATEGORY_OPTIONS_ERROR'
export const LOAD_PRODUCT_SUB_CATEGORY_OPTIONS =
  'APP/PRODUCT/OVERVIEW/LOAD_SUB_CATEGORY_OPTIONS'
export const LOAD_PRODUCT_SUB_CATEGORY_OPTIONS_SUCCESS =
  'APP/PRODUCT/OVERVIEW/LOAD_SUB_CATEGORY_OPTIONS_SUCCESS'
export const LOAD_PRODUCT_SUB_CATEGORY_OPTIONS_ERROR =
  'APP/PRODUCT/OVERVIEW/LOAD_SUB_CATEGORY_OPTIONS_ERROR'
export const LOAD_TOP_PRODUCT_LIST =
  'APP/PRODUCT/OVERVIEW/LOAD_TOP_LIST'
export const LOAD_TOP_PRODUCT_LIST_SUCCESS =
  'APP/PRODUCT/OVERVIEW/LOAD_TOP_LIST_SUCCESS'
export const LOAD_TOP_PRODUCT_LIST_ERROR =
  'APP/PRODUCT/OVERVIEW/LOAD_TOP_LIST_ERROR'
export const LOAD_TOP_PERFORMANCE_DATA =
  'APP/PRODUCT/OVERVIEW/LOAD_TOP_BRAND_PERFORMANCE_DATA'
export const LOAD_TOP_PERFORMANCE_DATA_SUCCESS =
  'APP/PRODUCT/OVERVIEW/LOAD_TOP_BRAND_PERFORMANCE_DATA_SUCCESS'
export const LOAD_TOP_PERFORMANCE_DATA_ERROR =
  'APP/PRODUCT/OVERVIEW/LOAD_TOP_BRAND_PERFORMANCE_DATA_ERROR'

export const PRODUCT_OVERVIEW_CLEAN_UP =
  'APP/PRODUCT/OVERVIEW/CLEAN_UP'
