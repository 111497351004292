/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from 'axios'
import mantixApiInstance from '../interceptors/interceptor'

const PAGE = 'order/'
const ORDERS_SEGMENTS = 'customer/'

const LOAD_ORDERS_DB_URL = process.env.REACT_APP_LOAD_ORDERS_DB

const LOAD_SEGMENT_OPTIONS_URL =
  process.env.REACT_APP_LOAD_SEGMENT_OPTIONS

const LOAD_SHIPPING_LIST_URL =
  process.env.REACT_APP_LOAD_SHIPPING_LIST

const LOAD_SHIPPING_KPI_URL = process.env.REACT_APP_LOAD_SHIPPING_KPI

const LOAD_DISCOUNTS_KPI_URL =
  process.env.REACT_APP_LOAD_DISCOUNTS_KPI

const LOAD_DISCOUNTS_LIST_URL =
  process.env.REACT_APP_LOAD_DISCOUNTS_LIST

const LOAD_DISCOUNTS_GRAPH_URL =
  process.env.REACT_APP_LOAD_DISCOUNTS_GRAPH
const LOAD_FILTER_BY_BRANDS_URL =
  process.env.REACT_APP_LOAD_FILTER_BY_BRANDS

export const loadOrdersOptions = (params: {
  tenantId: string
  category: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${ORDERS_SEGMENTS}${LOAD_SEGMENT_OPTIONS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadOrdersDB = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  page: number
  pageSize: number
  orderBy: string
  orderNumber: string
  filter: string
  sortOrder: string
  sortBy: string
  filterValue: string
  exportFlag: boolean
}): Promise<AxiosResponse<any>> => {
  const param: any = {
    ...params,
    export: params.exportFlag,
  }
  delete param.exportFlag
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_ORDERS_DB_URL}`,
    method: 'get',
    params: param,
  }
  return mantixApiInstance.request(config)
}

export const loadShippingList = (params: {
  type: string
  tenantId: string
  storeId: string
  from: string
  to: string
  page: number
  pageSize: number
  period: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SHIPPING_LIST_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadShippingKPI = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SHIPPING_KPI_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadShippingOptions = (params: {
  tenantId: string
  category: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SEGMENT_OPTIONS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadDiscountsKPI = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  discountStatus: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_DISCOUNTS_KPI_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadDiscountsList = (params: {
  tenantId: string
  storeId: string
  from: string
  to: string
  page: number
  pageSize: number
  discountType: string
  searchKey: string
  searchValue: string
  sortOrder: string
  sortBy: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_DISCOUNTS_LIST_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadDiscountsOptions = (params: {
  tenantId: string
  description: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${ORDERS_SEGMENTS}${LOAD_SEGMENT_OPTIONS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}

export const loadDiscountsGraph = (params: {
  type: string
  tenantId: string
  storeId: string
  from: string
  to: string
  previousYearEnabled: boolean
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_DISCOUNTS_GRAPH_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}
export const loadOrdersbyBrands = (params: {
  type: string
  tenantId: string
  storeId: string
  page: number
  pageSize: number
  value?: string
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_FILTER_BY_BRANDS_URL}`,
    method: 'get',
    params,
  }
  return mantixApiInstance.request(config)
}
