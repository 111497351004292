import React, { useEffect, useState, memo } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterIconBlack from "assets/img/FilterIconBlack.svg";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import redLogo from "assets/img/redLogo.svg";
import Button from "@material-ui/core/Button";
import { RouteList } from "containers/SideBar/types/reducer";
import Loadable from "utils/Loadable";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { RootStore } from "store";

interface Props {
  children: React.ReactNode;
  routes: RouteList;
}

const GlobalFilters = Loadable(() => import("containers/GlobalFilters"));

const Sidebar = ({ children, routes }: Props): JSX.Element => {
  const history = useHistory();
  const [focusParentEle, setFocusParentEle] = useState<string | boolean>("");
  const [expanded, setExpanded] = useState<string | boolean>("");
  const [open, setOpen] = React.useState(true);
  const [showCustomerDBFilters, setShowCustomerDBFilters] = useState(false);
  const { companyName, storeLogo } = useSelector(
    (state: RootStore) => state.userAuth.loginData
  );

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setExpanded(false);
  };

  const onParentNavClick = (routeName: string) => (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    setFocusParentEle(routeName);
    setOpen(true);
  };

  const onNavClick = () => {
    setFocusParentEle(false);
    setExpanded(false);
  };
  const onListItemClick = (routeName: string) => (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    event: React.MouseEvent<unknown>
  ) => {
    history.push(routeName);
  };

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) setOpen(true);
  };

  const showCustomerDBFiltersBar = () => {
    setShowCustomerDBFilters(true);
    setOpen(true);
  };

  const hideCustomerDBFilters = () => {
    setShowCustomerDBFilters(false);
  };

  useEffect(() => {
    const path = history.location.pathname;
    const tab = path.includes("-") ? path.split("/")[1].split("-")[0] : "";
    const upperCaseTab = tab ? tab.replace(tab[0], tab[0].toUpperCase()) : "";
    if (upperCaseTab) {
      setExpanded(upperCaseTab);
      setFocusParentEle(upperCaseTab);
    }
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <nav aria-label="sidebar menu">
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <AppBar
            position="relative"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar className={classes.openButton}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon color="primary" />
              </IconButton>
            </Toolbar>
          </AppBar>
          <div
            className={clsx(classes.sidebarContainer, {
              [classes.sidebarFullHeight]: showCustomerDBFilters,
            })}
          >
            <div className={classes.logo}>
              <Link id="homepageLink" to="/">
                <img
                  className={classes.logoWhite}
                  src={storeLogo}
                  alt="logo"
                  width="180px"
                  height="80px"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = redLogo;
                  }}
                />
                <p>{companyName}</p>
              </Link>
              {!showCustomerDBFilters && (
                <div className={classes.arrowIconWrapper}>
                  <div
                    role="button"
                    tabIndex={0}
                    className={open ? classes.toolbar : classes.hide}
                    onClick={handleDrawerClose}
                    onKeyDown={handleDrawerClose}
                  >
                    <IconButton className={classes.collapseButton}>
                      <DoubleArrowIcon color="primary" />
                    </IconButton>
                  </div>
                </div>
              )}
            </div>
            <Divider />
            {!showCustomerDBFilters ? (
              <>
                <Button
                  id="GlobalFiltersButton"
                  role="link"
                  onClick={showCustomerDBFiltersBar}
                  className={classes.filters}
                  disabled // disabling for bimart
                >
                  <img
                    className={classes.filtersImg}
                    src={FilterIconBlack}
                    alt="logo"
                    width="180px"
                    height="80px"
                  />
                  <p>Filters</p>
                </Button>
                <Divider className={classes.divider} />
                <List className={classes.sidebarList}>
                  {routes.map((route) =>
                    route.isDropdown ? (
                      <Accordion
                        id={route.name}
                        key={route.name}
                        className={classes.accordion}
                        expanded={expanded === route.name}
                        onChange={handleChange(route.name)}
                        classes={{
                          expanded: classes.expanded,
                          root:
                            focusParentEle === route.name
                              ? classes.selectedTab
                              : classes.notSelectedTab,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.expandIcon} />
                          }
                          aria-controls="menu-content"
                          id="menu-header"
                          className={classes.summaryRoot}
                          classes={{
                            expanded: classes.expanded,
                            content: classes.summaryContent,
                          }}
                        >
                          <img
                            className={classes.accordionIcon}
                            src={route.icon}
                            alt={route.name}
                          />

                          <Typography
                            id={route.name}
                            className={classes.routeName}
                          >
                            {route.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.paddingZero}>
                          <div className={classes.leftBorder}>
                            <List className={classes.accordionList}>
                              {route.subList &&
                                route.subList.map(
                                  (list) =>
                                    list.view && (
                                      <NavLink
                                        id={`${list.name}link`}
                                        key={list.name}
                                        to={list.route}
                                        className={classes.listText}
                                        activeClassName={
                                          classes.activeListsubText
                                        }
                                        onClick={onParentNavClick(route.name)}
                                        tabIndex={-1}
                                      >
                                        <ListItem
                                          button
                                          id={`${list.name}name`}
                                          onClick={onListItemClick(list.route)}
                                        >
                                          <Typography
                                            className={classes.listItemLinks}
                                          >
                                            {list.name}
                                          </Typography>
                                        </ListItem>
                                      </NavLink>
                                    )
                                )}
                            </List>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      route.view && (
                        <NavLink
                          id={route.name}
                          key={route.name}
                          to={route.route as string}
                          className={classes.listText}
                          activeClassName={classes.activeListText}
                          onClick={onNavClick}
                          tabIndex={-1}
                        >
                          <ListItem
                            className={classes.listItem}
                            button
                            onClick={onListItemClick(route.route as string)}
                          >
                            <ListItemIcon
                              classes={{ root: classes.paddingLeft }}
                            >
                              <img
                                src={route.icon}
                                alt={route.name}
                                className={classes.accordionIcon}
                              />
                            </ListItemIcon>

                            <Typography className={classes.links}>
                              {route.name}
                            </Typography>
                          </ListItem>
                        </NavLink>
                      )
                    )
                  )}
                </List>
              </>
            ) : (
              <div>
                <div
                  role="button"
                  tabIndex={0}
                  id="backToMenuButton"
                  className={classes.backToMenuWrapper}
                  onClick={hideCustomerDBFilters}
                  onKeyDown={handleDrawerClose}
                >
                  <IconButton>
                    <img src={FilterIconBlack} alt="backToMenu" />
                  </IconButton>
                  <span>Back to Main Menu</span>
                </div>
                <GlobalFilters />
              </div>
            )}
          </div>
        </Drawer>
      </nav>
      <main className={classes.main}>
        <>{children}</>
      </main>
      <div className={classes.paddingRightToScrollbar} />
    </div>
  );
};

export default memo(Sidebar);
