import { ForgotPasswordConfirmActionTypes } from './types/actions'
import { ForgotPasswordConfirmState } from './types/reducer'
import {
  FORGOT_PASSWORD_CONFIRM,
  FORGOT_PASSWORD_CONFIRM_SUCCESS,
  FORGOT_PASSWORD_CONFIRM_ERROR,
  FORGOT_PASSWORD_CONFIRM_CLEAN_UP,
} from './constants'

export const initialState: ForgotPasswordConfirmState = {
  isLoading: false,
  success: false,
  emailAddress: '',
  password: '',
  verificationCode: '',
}

const reducer = (
  state: ForgotPasswordConfirmState = initialState,
  action: ForgotPasswordConfirmActionTypes,
): ForgotPasswordConfirmState => {
  switch (action.type) {
    case FORGOT_PASSWORD_CONFIRM:
      return { ...state, isLoading: true }
    case FORGOT_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        emailAddress: action.payload,
        password: action.payload,
        verificationCode: action.payload,
      }
    case FORGOT_PASSWORD_CONFIRM_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case FORGOT_PASSWORD_CONFIRM_CLEAN_UP:
      return { ...state, ...initialState }
    default:
      return state
  }
}

export default reducer
