import { ForgotPasswordActionTypes } from './types/actions'
import { ForgotPasswordState } from './types/reducer'
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_CLEAN_UP,
} from './constants'

export const initialState: ForgotPasswordState = {
  isLoading: false,
  success: false,
  emailAddress: '',
}

const reducer = (
  state: ForgotPasswordState = initialState,
  action: ForgotPasswordActionTypes,
): ForgotPasswordState => {
  switch (action.type) {
    case FORGOT_PASSWORD:
      return { ...state, isLoading: true }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        emailAddress: action.payload,
      }
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }
    case FORGOT_PASSWORD_CLEAN_UP:
      return { ...state, ...initialState }
    default:
      return state
  }
}

export default reducer
