import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    // top: '7rem',
    padding: '1rem',
    // right: '4.8%',
    minHeight: '20%',
    // zIndex: 2,
  },
  pointAlignment: {
    display: 'flex',
    alignItems: 'baseline',
    marginLeft: '1rem',
    gap: '1rem',
  },
  timelineHeading: {
    color: theme.palette.primary.main,
    marginBottom: '1rem',
  },
  txt: {
    fontSize: theme.app.fonts.textLarge,
    margin: '1rem',
  },
  txt2: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4rem',
    color: theme.palette.primary.main,
  },
  moreItemIcon: {
    fontSize: 'small',
    marginBottom: 'auto 0 auto 4rem',
  },
  btn: {
    width: '22%',
    marginLeft: '34rem',
    marginTop: '0.5rem',
  },
  headingSection: {
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'column',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.app.fonts.textMedium,
    marginLeft: '6rem',
    marginBottom: '1rem',
    rowGap: '1rem',
  },
  popper: {
    width: '45rem',
    right: 0,
  },
  divider: {
    width: '100%',
  },
  icon: {
    width: '0.7rem',
    height: '0.7rem',
    borderRadius: '100%',
    background: theme.app.colors.blue,
  },
}))

export default useStyles
