/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import bcrypt from 'bcryptjs'
import moment from 'moment'
import { getUA } from 'utils/utils'
import mantixApiInstance from '../interceptors/interceptor'

const BASE_URL = process.env.REACT_APP_BASE_BIMART

const USER_LOGIN_URL = process.env.REACT_APP_USER_LOGIN

const VALIDATE_USER_OTP_URL = process.env.REACT_APP_VALIDATE_USER_OTP

const UPDATE_PROFILE_URL = process.env.REACT_APP_UPDATE_PROFILE

const CHANGE_PASSWORD_URL = process.env.REACT_APP_CHANGE_PASSWORD
const REACT_APP_LOAD_SIGN_IN_VERIFY_URL =
  process.env.REACT_APP_LOAD_SIGN_IN_VERIFY
const REACT_APP_LOGOUT_URL = process.env.REACT_APP_LOGOUT

const salt = '$2a$12$Ju8y39RAdMpyob7.HyEe/u'
const getCurrentURL = () => {
  return window.location.href
}
export const userLogin = (
  email: string,
  password: string,
  ipAddress: string | undefined,
  location: string | undefined,
): Promise<AxiosResponse<any>> => {
  const hashedPassword = bcrypt.hashSync(password, salt)
  const data = {
    emailAddress: email,
    password: hashedPassword,
    ipAddress,
    location,
    device: getUA(),
    loginTime: moment().utc().format('yyyy-MM-DD hh:mm:ss'),
    url: getCurrentURL(),
  }

  const config: AxiosRequestConfig = {
    url: `/${USER_LOGIN_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const validateLoginOTP = (
  userId: string,
  otp: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    userId,
    otp,
  }

  const config: AxiosRequestConfig = {
    url: `/${VALIDATE_USER_OTP_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const updateProfile = (datas: {
  userId: string
  firstName: string
  lastName: string
  password: string
  phone: string
}): Promise<AxiosResponse<any>> => {
  const hashedPassword = bcrypt.hashSync(datas.password, salt)
  const data = { ...datas, password: hashedPassword }
  const config: AxiosRequestConfig = {
    url: `/${UPDATE_PROFILE_URL}`,
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(
        'MANTIX_AUTH_TOKEN',
      )}`,
    },
  }
  return mantixApiInstance.request(config)
}

export const changePassword = (datas: {
  userId: string
  currentPassword: string
  newPassword: string
}): Promise<AxiosResponse<any>> => {
  const currentHashPwd = bcrypt.hashSync(datas.currentPassword, salt)
  const newHashPassword = bcrypt.hashSync(datas.newPassword, salt)
  const data = {
    ...datas,
    currentPassword: currentHashPwd,
    newPassword: newHashPassword,
  }
  const config: AxiosRequestConfig = {
    url: `/${CHANGE_PASSWORD_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}
export const VerifySignIn = (
  emailAddress: string,
  password: string,
  verificationCode: string,
): Promise<AxiosResponse<any>> => {
  const hashedPassword = bcrypt.hashSync(password, salt)
  const data = {
    emailAddress,
    password: hashedPassword,
    verificationCode,
  }

  const config: AxiosRequestConfig = {
    url: `/${REACT_APP_LOAD_SIGN_IN_VERIFY_URL}`,
    method: 'post',
    data,
  }
  return mantixApiInstance.request(config)
}

export const userLogout = (
  userId: string | null,
  isAutoLogout: boolean,
  token: string | null,
  lastTime?: string | undefined,
): Promise<AxiosResponse<any>> => {
  // const lastActivityTime = localStorage.getItem('LAST_ACTIVITY')
  const data = {
    userId,
    isAutoLogout,
    token,
    logoutTime: isAutoLogout
      ? lastTime
      : moment().utc().format('yyyy-MM-DD hh:mm:ss'),
  }

  const config: AxiosRequestConfig = {
    url: `${BASE_URL}/${REACT_APP_LOGOUT_URL}`,
    method: 'post',
    data,
    headers: {
      authorization: `Bearer ${token}`,
    },
  }
  return mantixApiInstance.request(config)
}
