import { ProductDetailActionTypes } from './types/actions'
import { ProductDetailsState } from './types/reducer'
import {
  LOAD_PRODUCT_KPI,
  LOAD_PRODUCT_KPI_ERROR,
  LOAD_PRODUCT_KPI_SUCCESS,
  LOAD_PRODUCT_DETAILS,
  LOAD_PRODUCT_DETAILS_SUCCESS,
  LOAD_PRODUCT_DETAILS_ERROR,
  LOAD_SALES_CHART_DATA,
  LOAD_SALES_CHART_DATA_ERROR,
  LOAD_SALES_CHART_DATA_SUCCESS,
  LOAD_INVENTORY_CHART_DATA,
  LOAD_INVENTORY_CHART_DATA_ERROR,
  LOAD_INVENTORY_CHART_DATA_SUCCESS,
  LOAD_REFUNDS_CHART_DATA,
  LOAD_REFUNDS_CHART_DATA_ERROR,
  LOAD_REFUNDS_CHART_DATA_SUCCESS,
  LOAD_PERFORMANCE_LIST,
  LOAD_PERFORMANCE_LIST_SUCCESS,
  LOAD_PERFORMANCE_LIST_ERROR,
  LOAD_CUSTOMERS_LIST,
  LOAD_CUSTOMERS_LIST_ERROR,
  LOAD_CUSTOMERS_LIST_SUCCESS,
  LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS,
  LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS_ERROR,
  LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS_SUCCESS,
  LOAD_BUNDLED_PRODUCTS,
  LOAD_BUNDLED_PRODUCTS_ERROR,
  LOAD_BUNDLED_PRODUCTS_SUCCESS,
  PRODUCT_DETAILS_CLEAN_UP,
} from './constants'

export const initialState: ProductDetailsState = {
  isLoading: false,
  success: false,
  kpi: {
    revenue: '',
    qtySold: '',
    cogs: '',
    grossProfit: '',
    grossMargin: '',
    cltv: '',
  },
  data: [],
  productDetailsSuccess: false,
  productDetails: {
    productType: '',
    variableProduct: '',
    categoryName: '',
    subCategoryName: '',
    subSubCategoryName: '',
    imageUrl: '',
    productName: '',
  },
  variableProductsLoading: false,
  variableProductsSuccess: false,
  variableProducts: [],
  isSalesChartLoading: false,
  isRefundsChartLoading: false,
  isInventoryChartLoading: false,
  chartDataSuccess: false,
  chartData: {
    content: {
      type: '',
      total: '',
      trend: [],
      inventorySummary: {
        holdingCost: '',
        outOfStockDate: '',
        qtyAvailable: '',
        sellThroughRate: '',
        velocity: '',
      },
      salesSummaryData: {
        avgPrice: '',
        costPerUnit: '',
        retailValue: '',
      },
      refundsSummaryData: {
        avgAmntRefunded: '',
        percentRefunded: '',
        totalRefunded: '',
        unitsRefunded: '',
      },
    },
    dateLabels: [],
  },
  performanceListLoading: false,
  performanceList: [],
  performanceSuccess: false,
  performanceTotalElements: 0,
  performanceTotalRow: {
    totalRevenue: '',
    totalOrders: 0,
    totalCOGS: '',
    totalQtySold: 0,
    totalQtyAvailable: 0,
    totalGrossProfit: '',
    totalMargin: '',
  },
  customersListLoading: false,
  customersList: [],
  customersSuccess: false,
  customersTotalElements: 0,
  customersTotalRow: {
    totalRevenue: '',
    totalLifetimeProductsOrders: '',
    totalOrders: '',
    totalLifetimeOrders: '',
  },
  customerSearchOptions: [],
  performanceSearchOptions: [],
  searchOptionsSuccess: false,
  bundledProductsSuccess: false,
  bundledProductsLoading: false,
  bundledProducts: [],
}

const reducer = (
  state: ProductDetailsState = initialState,
  action: ProductDetailActionTypes,
): ProductDetailsState => {
  switch (action.type) {
    case LOAD_PRODUCT_KPI:
      return {
        ...state,
        isLoading: true,
        kpi: initialState.kpi,
      }
    case LOAD_PRODUCT_KPI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
        kpi: action.payload,
      }
    case LOAD_PRODUCT_KPI_ERROR:
      return {
        ...state,
        isLoading: false,
        success: false,
      }

    case LOAD_PRODUCT_DETAILS:
      return {
        ...state,
        isLoading: true,
        productDetails: initialState.productDetails,
      }
    case LOAD_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productDetailsSuccess: true,
        productDetails: action.payload,
      }
    case LOAD_PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        isLoading: false,
        productDetailsSuccess: false,
      }

    case LOAD_SALES_CHART_DATA:
      return {
        ...state,
        chartData: initialState.chartData,
        isSalesChartLoading: true,
      }
    case LOAD_SALES_CHART_DATA_SUCCESS:
      return {
        ...state,
        isSalesChartLoading: false,
        chartDataSuccess: true,
        chartData: action.payload,
      }
    case LOAD_SALES_CHART_DATA_ERROR:
      return {
        ...state,
        isSalesChartLoading: false,
        chartDataSuccess: false,
      }

    case LOAD_INVENTORY_CHART_DATA:
      return {
        ...state,
        chartData: initialState.chartData,
        isInventoryChartLoading: true,
      }
    case LOAD_INVENTORY_CHART_DATA_SUCCESS:
      return {
        ...state,
        isInventoryChartLoading: false,
        chartDataSuccess: true,
        chartData: action.payload,
      }
    case LOAD_INVENTORY_CHART_DATA_ERROR:
      return {
        ...state,
        isInventoryChartLoading: false,
        chartDataSuccess: false,
      }

    case LOAD_REFUNDS_CHART_DATA:
      return {
        ...state,
        chartData: initialState.chartData,
        isRefundsChartLoading: true,
      }
    case LOAD_REFUNDS_CHART_DATA_SUCCESS:
      return {
        ...state,
        isRefundsChartLoading: false,
        chartDataSuccess: true,
        chartData: action.payload,
      }
    case LOAD_REFUNDS_CHART_DATA_ERROR:
      return {
        ...state,
        isRefundsChartLoading: false,
        chartDataSuccess: false,
      }

    case LOAD_PERFORMANCE_LIST:
      return {
        ...state,
        performanceListLoading: true,
        performanceList: initialState.performanceList,
        performanceTotalElements:
          initialState.performanceTotalElements,
        performanceTotalRow: initialState.performanceTotalRow,
      }
    case LOAD_PERFORMANCE_LIST_SUCCESS:
      return {
        ...state,
        performanceListLoading: false,
        performanceSuccess: true,
        performanceList: action.payload.list,
        performanceTotalRow: action.payload.totalRow,
        performanceTotalElements: action.payload.totalElements || 0,
      }
    case LOAD_PERFORMANCE_LIST_ERROR:
      return {
        ...state,
        performanceListLoading: false,
        performanceSuccess: false,
      }

    case LOAD_CUSTOMERS_LIST:
      return {
        ...state,
        customersListLoading: true,
        customersList: initialState.customersList,
        customersTotalElements: initialState.customersTotalElements,
        customersTotalRow: initialState.customersTotalRow,
      }
    case LOAD_CUSTOMERS_LIST_SUCCESS:
      return {
        ...state,
        customersListLoading: false,
        customersSuccess: true,
        customersList: action.payload.list,
        customersTotalRow: action.payload.totalRow,
        customersTotalElements: action.payload.totalElements || 0,
      }
    case LOAD_CUSTOMERS_LIST_ERROR:
      return {
        ...state,
        customersListLoading: false,
        customersSuccess: false,
      }

    case LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS:
      return {
        ...state,
        isLoading: true,
        performanceSearchOptions:
          initialState.performanceSearchOptions,
        customerSearchOptions: initialState.customerSearchOptions,
      }
    case LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        searchOptionsSuccess: true,
        performanceSearchOptions: action.payload.performanceOptions,
        customerSearchOptions: action.payload.customerOptions,
      }
    case LOAD_PRODUCT_DETAILS_SEARCH_OPTIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        searchOptionsSuccess: false,
      }

    case PRODUCT_DETAILS_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      }

    case LOAD_BUNDLED_PRODUCTS:
      return {
        ...state,
        bundledProductsLoading: true,
        bundledProducts: initialState.bundledProducts,
      }
    case LOAD_BUNDLED_PRODUCTS_SUCCESS:
      return {
        ...state,
        bundledProductsLoading: false,
        bundledProductsSuccess: true,
        bundledProducts: action.payload,
      }
    case LOAD_BUNDLED_PRODUCTS_ERROR:
      return {
        ...state,
        bundledProductsLoading: false,
        bundledProductsSuccess: false,
      }

    default:
      return state
  }
}

export default reducer
