/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FilterObj } from "containers/Customers/Overview/types/reducer";
import mantixApiInstance from "../interceptors/interceptor";

const PAGE = "customer/";
const EMAIL_CAMPAIGN = "marketing/";
const CUSTOMERS_PURCHASES = "product/";
const LOAD_CUSTOMER_OPTIONS_URL = process.env.REACT_APP_LOAD_SEGMENT_OPTIONS;

const LOAD_MATCHING_SEGMENTS_URL = process.env.REACT_APP_LOAD_MATCHING_SEGMENTS;

// const BASE_URL = process.env.REACT_APP_LOAD_CUSTOMER_INTERESTS

const LOAD_CUSTOMER_BRAND_AFFINITIES_URL =
  process.env.REACT_APP_LOAD_CUSTOMER_BRAND_AFFINITIES;

// const LOAD_CUSTOMER_PROFILE_URL =
//   process.env.REACT_APP_LOAD_CUSTOMER_PROFILE

const LOAD_PROFILE_TOP_SECTION_URL =
  process.env.REACT_APP_LOAD_PROFILE_TOP_SECTION;

const LOAD_CUSTOMER_HIGHLIGHTS_URL =
  process.env.REACT_APP_LOAD_CUSTOMER_HIGHLIGHTS;

const LOAD_CUSTOMER_OVERVIEW_GRAPH_URL =
  process.env.REACT_APP_LOAD_CUSTOMER_OVERVIEW_GRAPH;

const LOAD_CUSTOMER_LTV_URL = process.env.REACT_APP_LOAD_CUSTOMER_LTV;

const SEARCH_DB_URL = process.env.REACT_APP_SEARCH_DB;

const LOAD_CUSTOMER_SEGMENTS_URL = process.env.REACT_APP_LOAD_CUSTOMER_SEGMENTS;

const LOAD_CUSTOMER_RFM_ANALYSIS_URL =
  process.env.REACT_APP_LOAD_CUSTOMER_RFM_ANALYSIS;

const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_URL =
  process.env.REACT_APP_LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS;
const LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_URL =
  process.env.REACT_APP_LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON;

const LOAD_CATEGORY_LIST_URL = process.env.REACT_APP_LOAD_CATEGORY_LIST;

const LOAD_ATTRIBUTE_DATA_URL = process.env.REACT_APP_LOAD_ATTRIBUTE_DATA;

const LOAD_SEGMENT_OPERATORS_URL = process.env.REACT_APP_LOAD_SEGMENT_OPERATORS;

const SEGMENT_GRAPH_URL = process.env.REACT_APP_SEGMENT_GRAPH;

const PRODUCTS_PURCHASED_URL =
  process.env.REACT_APP_SEGMENTS_PRODUCTS_PURCHASED;

const PERFORMANCE_DATA_URL = process.env.REACT_APP_SEGMENTS_PERFORMANCE_DATA;

const LOAD_CATEGORY_PURCHASES_URL =
  process.env.REACT_APP_CUSTOMER_CATEGORY_PURCHASES;

const LOAD_TIMELINE_DETAILS_URL =
  process.env.REACT_APP_CUSTOMER_TIMELINE_DETAILS;

const LOAD_SUGGESTED_CUSTOMER_PRODUCTS_URL =
  process.env.REACT_APP_LOAD_SUGGESTED_CUSTOMER_PRODUCTS;
// const LOAD_CUSTOMERS_EMAILS_DATA_URL =
//   process.env.REACT_APP_LOAD_CUSTOMERS_EMAILS_DATA_URL
const LOAD_MARK_TABLE_URL = process.env.REACT_APP_LOAD_MARK_TABLE;
const LOAD_TIMELINE_KPI_URL = process.env.REACT_APP_CUSTOMER_TIMELINE_KPI;
const LOAD_NEW_CUSTOMER_CLTV_GRAPH_URL =
  process.env.REACT_APP_NEW_CUSTOMER_CLTV_GRAPH;

export const loadCustomerOptions = (params: {
  tenantId: string;
  category: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_OPTIONS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadMatchingSegments = (params: {
  tenantId: string;
  storeId: string;
  customerId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_MATCHING_SEGMENTS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerInterests = (params: {
  tenantId: string;
  storeId: string;
  customerId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: "",
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadCustomerBrandAfffinities = (params: {
  tenantId: string;
  storeId: string;
  customerId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_BRAND_AFFINITIES_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const searchDB = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  page: number;
  pageSize: number;
  text: string;
  segmentName: string;
  sortOrder: string;
  sortBy: string;
  exportFlag: boolean;
}): Promise<AxiosResponse<any>> => {
  const param: any = {
    ...params,
    export: params.exportFlag,
  };
  delete param.exportFlag;
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${SEARCH_DB_URL}`,
    method: "get",
    params: param,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerProfile = (
  email: string
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${email}`,
    method: "get",
    params: {},
  };
  return mantixApiInstance.request(config);
};

export const loadProfileSummary = (params: {
  customerId: string;
  tenantId: string;
  storeId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_PROFILE_TOP_SECTION_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerHighlights = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_HIGHLIGHTS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerOverviewGraph = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  period: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_OVERVIEW_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerRfmAnalysis = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_RFM_ANALYSIS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustRFMFiltersOptions = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
export const loadCustRFMFiltersSubmit = (filterObject: {
  startDate: string;
  endDate: string;
  recencyOptions: string | null;
  monetizationOptions: string | null;
  frequencyOptions: string | null;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: LOAD_CUSTOMER_RFM_ANALYSIS_FILTERS_BUTTON_URL,
    method: "post",
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadCategoryList = (params: {
  label: string;
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  page: number;
  pageSize: number;
  recency: string;
  monetization: string;
  frequency: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CATEGORY_LIST_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerLTV = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  segmentName: string;
  cltvKpiType: string;
  customerType: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_LTV_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCustomerSegments = (params: {
  tenantId: string;
  storeId: string;
  userId: string;
  from: string;
  to: string;
  segment: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_SEGMENTS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSegmentOptions = (params: {
  tenantId: string;
  category: string;
  userId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_CUSTOMER_OPTIONS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSegmentAttributeData = (params: {
  tenantId: string;
  attribute: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_ATTRIBUTE_DATA_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSegmentAttributeOperators = (params: {
  tenantId: string;
  storeId: string;
  segmentName: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SEGMENT_OPERATORS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSegmentGraph = (params: {
  tenantId: string;
  storeId: string;
  userId: string;
  from: string;
  to: string;
  segmentType: string;
  segment: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${SEGMENT_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSegmentProductsPurchased = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  segment: string;
  page: number;
  pageSize: number;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${PRODUCTS_PURCHASED_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSegmentPerformanceData = (params: {
  tenantId: string;
  storeId: string;
  from: string;
  to: string;
  segment: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${PERFORMANCE_DATA_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadCategoryPurchase = (params: {
  tenantId: string;
  storeId: string;
  customerId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${CUSTOMERS_PURCHASES}${LOAD_CATEGORY_PURCHASES_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadTimelineKpi = (params: {
  tenantId: string;
  storeId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${CUSTOMERS_PURCHASES}${LOAD_CATEGORY_PURCHASES_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

// export const loadTimelineKpi = (params: {
//   tenantId: string
//   storeId: string
// }): Promise<AxiosResponse<any>> => {
//   const config: AxiosRequestConfig = {
//     url: `/${PAGE}${LOAD_TIMELINE_KPI_URL}`,
//     method: 'get',
//     params,
//   };
//   return mantixApiInstance.request(config);
// };

export const loadTimelineDetails = (params: {
  tenantId: string;
  storeId: string;
  customerId: string;
  type: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_TIMELINE_DETAILS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadExpandedData = (params: {
  tenantId: string;
  storeId: string;
  segmentName: string;
  categories: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SEGMENT_OPERATORS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};

export const loadSuggestedCustomerProduct = (params: {
  tenantId: string;
  storeId: string;
  customerId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_SUGGESTED_CUSTOMER_PRODUCTS_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
// export const loadCustomerSegmentEmailData = (params: {
//   tenantId: string
//   storeId: string
//   from: string
//   to: string
//   segmentName: string
// }): Promise<AxiosResponse<any>> => {
//   const config: AxiosRequestConfig = {
//     url: LOAD_CUSTOMERS_EMAILS_DATA_URL,
//     method: 'get',
//     params,
//   }
//   return mantixApiInstance.request(config)
// }
export const loadCustomerSegmentEmailData = (
  params: {
    tenantId: string;
    storeId: string;
    from: string;
    to: string;
    page: number;
    pageSize: number;
    type: string;
    sortOrder: string;
    sortBy: string;
  },
  filterObject: FilterObj[]
): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${EMAIL_CAMPAIGN}${LOAD_MARK_TABLE_URL}`,
    method: "post",
    params,
    data: filterObject,
  };
  return mantixApiInstance.request(config);
};

export const loadNewCustomerCLTVData = (params: {
  tenantId: string;
  storeId: string;
}): Promise<AxiosResponse<any>> => {
  const config: AxiosRequestConfig = {
    url: `/${PAGE}${LOAD_NEW_CUSTOMER_CLTV_GRAPH_URL}`,
    method: "get",
    params,
  };
  return mantixApiInstance.request(config);
};
