import {
  LOAD_MARKETING_ABANDONED_CARTS_KPI,
  LOAD_MARKETING_ABANDONED_CARTS_KPI_SUCCESS,
  LOAD_MARKETING_ABANDONED_CARTS_KPI_ERROR,
  LOAD_MARKETING_ABANDONED_CARTS_SALESKPI,
  LOAD_MARKETING_ABANDONED_CARTS_SALESKPI_ERROR,
  LOAD_MARKETING_ABANDONED_CARTS_SALESKPI_SUCCESS,
  LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS,
  LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS_SUCCESS,
  LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS_ERROR,
  LOAD_MARK_ABANDONEDCART_CHART,
  LOAD_MARK_ABANDONEDCART_CHART_ERROR,
  LOAD_MARK_ABANDONEDCART_CHART_SUCCESS,
  LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS,
  LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS_ERROR,
  LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS_SUCCESS,
} from './constants'
import { MarketingAbandonedCartsActionTypes } from './types/actions'
import { MarketingAbandonedCartState } from './types/reducer'

export const initialState: MarketingAbandonedCartState = {
  kpiIsLoading: false,
  kpiSuccess: false,
  kpiData: {
    lossInSale: '',
    salesEmail1: '',
    salesEmail2: '',
    salesEmail3: '',
    grossSaleRecovery: '',
    percentSalesUplift: '',
  },
  salesKpiLoading: false,
  salesKpiSuccess: false,
  saleskpiData: {
    firstEmail: {
      sent: '',
      clicks: '',
      open: '',
    },
    secondEmail: {
      sent: '',
      clicks: '',
      open: '',
    },
    thirdEmail: {
      sent: '',
      clicks: '',
      open: '',
    },
    hardBounced: '',
    unsubscribed: '',
  },
  customerDetails: [],
  totalElements: 0,
  detailsLoading: false,
  detailsSuccess: false,
  graphLoading: false,
  graphSuccess: false,
  graphData: {
    dateLabels: [],
    graphData: [],
  },
}
const reducer = (
  state: MarketingAbandonedCartState = initialState,
  action: MarketingAbandonedCartsActionTypes,
): MarketingAbandonedCartState => {
  switch (action.type) {
    case LOAD_MARKETING_ABANDONED_CARTS_KPI:
      return {
        ...state,
        kpiIsLoading: true,
        kpiData: initialState.kpiData,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_KPI_SUCCESS:
      return {
        ...state,
        kpiIsLoading: false,
        kpiSuccess: true,
        kpiData: action.payload,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_KPI_ERROR:
      return {
        ...state,
        kpiIsLoading: false,
        kpiSuccess: false,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_SALESKPI:
      return {
        ...state,
        salesKpiLoading: true,
        saleskpiData: initialState.saleskpiData,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_SALESKPI_SUCCESS:
      return {
        ...state,
        salesKpiLoading: false,
        salesKpiSuccess: true,
        saleskpiData: action.payload,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_SALESKPI_ERROR:
      return {
        ...state,
        salesKpiLoading: false,
        salesKpiSuccess: false,
      }
    case LOAD_MARK_ABANDONEDCART_CHART:
      return {
        ...state,
        graphLoading: true,
        graphData: initialState.graphData,
      }
    case LOAD_MARK_ABANDONEDCART_CHART_SUCCESS:
      return {
        ...state,
        graphLoading: false,
        graphSuccess: true,
        graphData: action.payload,
      }
    case LOAD_MARK_ABANDONEDCART_CHART_ERROR:
      return {
        ...state,
        graphLoading: false,
        graphSuccess: false,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        customerDetails: initialState.customerDetails,
        totalElements: initialState.totalElements,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        detailsSuccess: true,
        customerDetails: action.payload.details,
        totalElements: action.payload.totalElements,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_CUSTOMER_DETAILS_ERROR:
      return {
        ...state,
        salesKpiLoading: false,
        salesKpiSuccess: false,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        customerDetails: initialState.customerDetails,
        totalElements: initialState.totalElements,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS_SUCCESS:
      return {
        ...state,
        detailsLoading: false,
        detailsSuccess: true,
        customerDetails: action.payload.details,
        totalElements: action.payload.totalElements,
      }
    case LOAD_MARKETING_ABANDONED_CARTS_SALES_DETAILS_ERROR:
      return {
        ...state,
        salesKpiLoading: false,
        salesKpiSuccess: false,
      }
    default:
      return state
  }
}

export default reducer
