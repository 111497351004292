import { DashboardActionTypes } from "./types/actions";
import { DashboardState } from "./types/reducer";
import {
  LOAD_DASHBOARD_SUMMARY,
  LOAD_DASHBOARD_SUMMARY_SUCCESS,
  LOAD_DASHBOARD_SUMMARY_ERROR,
  LOAD_CUST_PURCHASE_DETAILS,
  LOAD_CUST_PURCHASE_DETAILS_ERROR,
  LOAD_CUST_PURCHASE_DETAILS_SUCCESS,
  LOAD_DASHBOARD_KPI,
  LOAD_DASHBOARD_KPI_ERROR,
  LOAD_DASHBOARD_KPI_SUCCESS,
  LOAD_TOP_PERFORMANCE_PRODUCTS,
  LOAD_TOP_PERFORMANCE_PRODUCTS_ERROR,
  LOAD_TOP_PERFORMANCE_PRODUCTS_SUCCESS,
  LOAD_WEB_TRAFFIC_DATA,
  LOAD_WEB_TRAFFIC_DATA_ERROR,
  LOAD_WEB_TRAFFIC_DATA_SUCCESS,
  LOAD_CONVERSION_RATE,
  LOAD_CONVERSION_RATE_SUCCESS,
  LOAD_CONVERSION_RATE_ERROR,
  DASHBOARD_CLEAN_UP,
  LOAD_PRODUCTS_HIERARCHY,
  LOAD_PRODUCTS_HIERARCHY_SUCCESS,
  LOAD_PRODUCTS_HIERARCHY_ERROR,
} from "./constants";

export const initialState: DashboardState = {
  isLoading: false,
  summarySuccess: false,
  summaryData: [],
  custPurchaseDetailsLoading: false,
  custPurchaseDetailsSuccess: false,
  custPurchaseDetails: [],
  custTotalPages: 0,
  kpiLoading: false,
  kpiSuccess: false,
  kpi: {
    customerLtv: "",
    checkOutRate: "",
    revenuePerVisitor: "",
    abandonmentRate: "",
  },
  prodLoading: false,
  prodSuccess: false,
  products: [],
  prodTotalPages: 0,
  webTrafficSuccess: false,
  webTrafficLoading: false,
  webTrafficData: {
    dateLabels: [],
    users: [],
    newUsers: [],
  },
  conversionDataLoading: false,
  conversionDataSuccess: false,
  conversionData: {
    total: "",
    graphData: [],
  },
  heirchyLoading: false,
  heirchySuccess: false,
  productsHierarchy: {
    product: "",
    percentage: 0,
    categories: [],
  },
};

const reducer = (
  state: DashboardState = initialState,
  action: DashboardActionTypes
): DashboardState => {
  switch (action.type) {
    case LOAD_DASHBOARD_SUMMARY:
      return {
        ...state,
        isLoading: true,
        summaryData: initialState.summaryData,
      };
    case LOAD_DASHBOARD_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        summarySuccess: true,
        summaryData: action.payload,
      };
    case LOAD_DASHBOARD_SUMMARY_ERROR:
      return {
        ...state,
        isLoading: false,
        summarySuccess: false,
      };

    case LOAD_CUST_PURCHASE_DETAILS:
      return {
        ...state,
        custPurchaseDetailsLoading: true,
        custPurchaseDetailsSuccess: false,
        custPurchaseDetails: initialState.custPurchaseDetails,
        custTotalPages: initialState.custTotalPages,
      };
    case LOAD_CUST_PURCHASE_DETAILS_SUCCESS:
      return {
        ...state,
        custPurchaseDetailsLoading: false,
        custPurchaseDetailsSuccess: true,
        custPurchaseDetails: action.payload.custDetails,
        custTotalPages: action.payload.totalPages,
      };
    case LOAD_CUST_PURCHASE_DETAILS_ERROR:
      return {
        ...state,
        custPurchaseDetailsLoading: false,
        custPurchaseDetailsSuccess: false,
      };

    case LOAD_TOP_PERFORMANCE_PRODUCTS:
      return {
        ...state,
        prodLoading: true,
        products: initialState.products,
        prodSuccess: false,
        prodTotalPages: initialState.prodTotalPages,
      };
    case LOAD_TOP_PERFORMANCE_PRODUCTS_SUCCESS:
      return {
        ...state,
        prodLoading: false,
        prodSuccess: true,
        products: action.payload.products,
        prodTotalPages: action.payload.totalPages,
      };
    case LOAD_TOP_PERFORMANCE_PRODUCTS_ERROR:
      return {
        ...state,
        prodLoading: false,
        prodSuccess: false,
      };

    case LOAD_DASHBOARD_KPI:
      return {
        ...state,
        kpiLoading: true,
        kpi: initialState.kpi,
      };
    case LOAD_DASHBOARD_KPI_SUCCESS:
      return {
        ...state,
        kpiLoading: false,
        kpiSuccess: true,
        kpi: action.payload,
      };
    case LOAD_DASHBOARD_KPI_ERROR:
      return {
        ...state,
        kpiLoading: false,
        kpiSuccess: false,
      };

    case LOAD_WEB_TRAFFIC_DATA:
      return {
        ...state,
        webTrafficLoading: true,
        webTrafficData: initialState.webTrafficData,
      };
    case LOAD_WEB_TRAFFIC_DATA_SUCCESS:
      return {
        ...state,
        webTrafficLoading: false,
        webTrafficSuccess: true,
        webTrafficData: action.payload,
      };
    case LOAD_WEB_TRAFFIC_DATA_ERROR:
      return {
        ...state,
        webTrafficLoading: false,
        webTrafficSuccess: false,
      };
    case LOAD_CONVERSION_RATE:
      return {
        ...state,
        conversionDataLoading: true,
        conversionData: initialState.conversionData,
      };
    case LOAD_CONVERSION_RATE_SUCCESS:
      return {
        ...state,
        conversionDataLoading: false,
        conversionDataSuccess: true,
        conversionData: action.payload,
      };
    case LOAD_CONVERSION_RATE_ERROR:
      return {
        ...state,
        conversionDataLoading: false,
        conversionDataSuccess: false,
      };
    case LOAD_PRODUCTS_HIERARCHY:
      return {
        ...state,
        heirchyLoading: true,
        productsHierarchy: initialState.productsHierarchy,
      };
    case LOAD_PRODUCTS_HIERARCHY_SUCCESS:
      return {
        ...state,
        heirchyLoading: false,
        heirchySuccess: true,
        productsHierarchy: action.payload,
      };
    case LOAD_PRODUCTS_HIERARCHY_ERROR:
      return {
        ...state,
        heirchyLoading: false,
        heirchySuccess: false,
      };
    case DASHBOARD_CLEAN_UP:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
